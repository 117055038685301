import React, {useEffect, useState} from "react";
import {
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  dialogGrid: {
    flexGrow: 1,
    textAlign: 'center',
    paddingTop: '0 !important',
  },
  progress: {
    height: '20px',
  },
}));

export default function EmailSendProgressDialog (props) {

  const classes = useStyles();
  // a progress erteke 0-100
  const [progress, setProgress] = useState(0);
  // az useEffect-ben hasznalt setTimeout
  const [timer, setTimer] = useState();
  // sikeresen elkuldott email-ek darabszama
  const [failedNum, setFailedNum] = useState(0);
  // sikeresen elkuldott email-ek darabszama
  const [succeededNum, setSucceededNum] = useState(0);
  // feldolgozas alatti email-ek darabszama
  const [processingNum, setProcessingNum] = useState(0);
  // egy flag, ami minden axios-hivas utan 2mp-vel allapotot valt, az useEffect ezt figyeli es ujra idoziti az axios-hivast
  const [processed, setProcessed] = useState( false );

  useEffect(
    () => {
      return postponeUpdate();
    }, [processed]
  );

  const postponeUpdate = () => {
      setTimer( setTimeout( () => updateValues( props.idList ), 2000 ) );
      return () => {
        clearTimeout( timer );
      };
  }

  const updateValues = ( idList ) => {
    let sent = 0;
    let failed = 0;
    const request = {
      url: '/api/changemonemails/progresssendmulti/' + idList.join( ',' ),
      timeout: 1000,
      method: 'POST',
    };
    axios( request )
      .then( resp => {
        if ( resp !== null && resp.data !== null ){
          failed = resp.data.failed;
          sent = resp.data.sent;
          setFailedNum( failed );
          setSucceededNum( sent );
          setProcessingNum( resp.data.progress );
          setProgress( ( sent + failed ) / idList.length * 100 );
        }
      })
      .catch( () => { setFailedNum( 'ismeretlen' ); setProcessingNum( 'ismeretlen' ); setSucceededNum( 'ismeretlen' ); setProgress( 0 ) } );
      // miutan elertuk a folyamat veget (+1 ciklus), bezarjuk az ablakot
      if ( ( succeededNum + failedNum ) === idList.length ){
        props.closeDialog();
      }
      else {
        // cserelgetjuk a processed erteket, aminek a valtozasat az useEffect figyeli
        setProcessed( !processed );
      }
  };

  return (
   <>
    <DialogTitle id="email-view-dialog-title">
        {props.idList.length}db email küldése...
        {' Sikeres / sikertelen / folyamatban: ' + succeededNum + ' / ' + failedNum + ' / ' + processingNum }
    </DialogTitle>
    <DialogContent className={classes.dialogGrid}>
        <LinearProgress className={classes.progress} variant="determinate" value={progress} />
    </DialogContent>
   </>
  )
}