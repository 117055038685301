import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles( ( theme ) => ({
  docMetaTable: {
    marginBottom: '1em'
  },
}) );

const UserFriendlyDocMeta = ( { meta } ) => {
  const classes = useStyles();

  return (
      <TableContainer component={Paper} className={classes.docMetaTable}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell variant="head">FaCím1:</TableCell>
              <TableCell colSpan={2}>{meta.title1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">FaCím2:</TableCell>
              <TableCell colSpan={2}>{meta.title2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Típus:</b><p>{meta.docType?.name}</p>
              </TableCell>
              <TableCell>
                <b>Kibocsátó(k):</b>
                <ol>
                  {meta.issuers?.map( is => (
                      <li key={"is" + is.id}>{is.name}</li>
                  ) )}
                </ol>
              </TableCell>
              <TableCell>
                <b>Jogterület(ek):</b>
                <ol>
                  {meta.lawFields?.map( is => (
                      <li key={"lf" + is.id}>{is.name}</li>
                  ) )}
                </ol>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>KibIdő:</b> {meta.emd?.text}</TableCell>
              <TableCell><b>SD:</b> {meta.sd?.text}</TableCell>
              <TableCell><b>ED:</b> {meta.ed?.text}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default UserFriendlyDocMeta;
