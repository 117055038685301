import React, {useEffect, useState} from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import ShortDate from "../../../components/ShortDate";
import IconButton from "@material-ui/core/IconButton";

import axios from "axios";
import moment from "moment";
import MUIDataTable from "mui-datatables";

import Loader from "../../../components/Loader";
import {muiTableLabels, shortText} from "../../../utils";

import CampaignEditor from "./CampaignEditor";


const useStyles = makeStyles((theme) => ({
    table: {
        margin: "1em 0"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dialogGrid: {
        flexGrow: 1,
    },
    sampleText: {
        padding: 5
    },
    sampleBgText: {
        backgroundColor: '#eeeae2'
    }
}));

const TABLE_COL_NAME = {name: 'name', label: 'Kampány név', options: {sort: true, filter: false}};
const TABLE_COL_ACTIVE = {
    name: 'active',
    label: 'Aktív?',
    options: {
        sort: true,
        filter: true,
        customBodyRender: value => value ? <Icon>check</Icon> : null,
    }
};
const TABLE_COL_START_DATE = {name: 'startDate', label: 'Kezdete', options: {sort: true, filter: false}};
const TABLE_COL_END_DATE = {name: 'endDate', label: 'Vége', options: {sort: true, filter: false}};
const TABLE_COL_STAT = {name: 'stat', label: 'Dokumentumok', options: {sort: false, filter: false}};
const TABLE_COL_CREATE_DATE = {name: 'createDate', label: 'Létrehozás dátuma', options: {sort: true, filter: false}};
const TABLE_COL_CREATOR = {name: 'creator', label: 'Létrehozó', options: {sort: true, filter: true}};
const TABLE_COL_MODIFY_DATE = {name: 'modifyDate', label: 'Módosítás dátum', options: {sort: true, filter: false}};
const TABLE_COL_MODIFIER = {name: 'modifier', label: 'Módosító', options: {sort: true, filter: true}};

const documentOptions = {
    pagination: true,
    selectableRows: 'none',
    print: false,
    downloadOptions: {filename: 'campaigns.csv', separator: ','},
    viewColumns: false,
    textLabels: muiTableLabels,
};

function createCampaign() {
    return {
        id: null,
        name: '',
        active: true,
        startDate: moment().format('YYYY.MM.DD'),
        endDate: '',
        text: '',
        foregroundColor: 'black',
        backgroundColor: 'white',
        linkType: 1,
        link: '',
        docs: [],
        conflicts: [],
    };
}


export default function Campaign() {
    const classes = useStyles();
    //const {enqueueSnackbar} = useSnackbar();
    const [backdrop, setBackdrop] = React.useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [camps, setCamps] = useState();
    const [campaign, setCampaign] = useState(createCampaign());

    const loadCampaigns = () => {
        setBackdrop(true);
        axios("/api/campaign").then(resp => {
            let camps = resp.data;
            camps.forEach(c => {
                c.startDate = ShortDate.toDateStr(c.startDate);
                c.endDate = ShortDate.toDateStr(c.endDate, '');
                c.stat = c.docs.map(d => '' + d + (c.conflicts.indexOf(d) >= 0 ? '*' : '')).join(', ');
            });
            setCamps(camps);
            setBackdrop(false);
        });
    };

    const getCampById = campId => {
        const index = camps.findIndex(c => c.id === campId);
        return index >= 0 ? camps[index] : null;
    };

    const newCampaign = () => {
        setCampaign(createCampaign());
        setOpenDialog(true);
    };

    const editCampaign = campId => {
        const camp = getCampById(campId);
        if (camp) {
            setCampaign(camp);
            setOpenDialog(true);
        }
    };

    const closeDialog = () => {
        setOpenDialog(false);
        loadCampaigns();
    };

    useEffect(() => {
        loadCampaigns();
    }, []);

    const documentColumns = [
        {
            name: '#',
            options: {
                filter: false,
                sort: false,
                customHeadRender: (meta) => (
                    <th key={meta.index} className="MuiTableCell-root MuiTableCell-head">
                        <IconButton onClick={loadCampaigns} title="Frissítés" style={{float: 'right'}}>
                            <Icon fontSize="small">refresh</Icon>
                        </IconButton>
                    </th>
                ),
                customBodyRender: (value, meta) => (meta.rowIndex + 1) + '.',
                setCellProps: () => ({
                    align: 'right',
                }),
            }
        },
        {
            name: 'id',
            empty: true,
            options: {
                sort: false,
                filter: false,
                customHeadRender: (meta) => (
                    <th key={meta.index} className="MuiTableCell-root MuiTableCell-head">
                        <IconButton onClick={newCampaign} title="Új kampány">
                            <Icon fontSize="small">add</Icon>
                        </IconButton>
                    </th>
                ),
                customBodyRender: (value, meta) => (
                    <IconButton onClick={() => editCampaign(meta.rowData[1])} title="Szerkesztés">
                        <Icon fontSize="small">edit</Icon>
                    </IconButton>
                ),
            }
        },
        TABLE_COL_NAME,
        TABLE_COL_ACTIVE,
        TABLE_COL_START_DATE,
        TABLE_COL_END_DATE,
        {
            name: 'text',
            label: 'Szöveg (HTML)',
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, meta) => {
                    const campId = meta.rowData[1];
                    const camp = getCampById(campId);
                    return (
                        <div className={classes.sampleText} style={{
                            color: camp.foregroundColor,
                            backgroundColor: camp.backgroundColor
                        }} title={value}>
                            {shortText(value, 30)}
                        </div>
                    );
                },
            }
        },
        TABLE_COL_STAT,
        TABLE_COL_CREATE_DATE,
        TABLE_COL_CREATOR,
        TABLE_COL_MODIFY_DATE,
        TABLE_COL_MODIFIER,
    ];

    return (
        <div>
            <Typography variant="h4">Kampány kezelő</Typography>

            {camps ? null : <Loader/>}

            <Paper elevation={3} className={classes.table}>
                <MUIDataTable
                    data={camps}
                    columns={documentColumns}
                    options={documentOptions}
                />
            </Paper>

            <Dialog
                open={openDialog}
                onClose={closeDialog}
                fullWidth
                maxWidth="xl"
                scroll="paper"
                disableBackdropClick={true}
                aria-labelledby="ce-campaign-dlg-title"
                aria-describedby="ce-campaign-dlg-content"
            >
                <CampaignEditor campaign={campaign} closeDialog={closeDialog}/>
            </Dialog>

            <Backdrop className={classes.backdrop} open={backdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
}
