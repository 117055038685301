import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles( ( theme ) => ({
  rowIndexHeader: {
    width: "50px"
  },
  rowIndex: {
    textAlign: "right"
  },
}) );

const Text = ( { rows } ) => {
  const classes = useStyles();

  const empty = !rows.length ?
      <TableRow><TableCell colSpan={2}>Nincs betöltve dokumentum.</TableCell></TableRow> : null;

  return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.rowIndexHeader}>Ssz</TableCell>
              <TableCell>Szöveg</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map( row => (
                <TableRow key={"ri" + row.rowIndex}>
                  <TableCell className={classes.rowIndex}>
                    {row.rowIndex}
                  </TableCell>
                  <TableCell>{row.text}</TableCell>
                </TableRow>
            ) )}
            {empty}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default Text;