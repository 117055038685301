import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Backdrop,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {useForm} from "react-hook-form";
import {useSnackbar} from 'notistack';

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	dialogGrid: {
		flexGrow: 1,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

// a feluletnek az megjelenesi allapotai lehetsegesek:
// - kiindulo allapot: a kapott szabalybeli nev, birosag es datum mezok megjelenitese inaktivan, valamint a kapcsolodo dokumentumok listaja
export default function InvalidRuleDeleteDialog({rule, closeDialog, closeRefreshDialog, saveUrl, ruleRelatedDocs}) {
	const classes = useStyles();

	// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// a szerver-muveletek idejere a feluletet inaktivra allitjuk
	const [backdrop, setBackdrop] = React.useState(false);
	// a beallitott ertekek kozul a szovegeseket ezzel a modszerrel olvassuk ki (kulon inputRef-ek helyett)
	const {register, getValues} = useForm();

	const submit = () => {
		rule={ id:rule.id, ...getValues() };
		setBackdrop(true);
		const request = {
			url: saveUrl.replace( '%id', rule.id ),
			method: 'DELETE',
			data: rule
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				enqueueSnackbar( 'Jóváhagyásra váró szöveg: "' + rule.synonym + '" törlése sikeres', {variant: "success"} );
				setBackdrop( false );
				closeRefreshDialog();
			})
			.catch(handleError);
	};

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const editDoc = (docId) => {
		window.open( '/bh/editor/' + docId , '_blank' );
	}

	return (
	<div>
		<form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
			<DialogTitle>
				Jóváhagyásra váró szöveg törlése
			</DialogTitle>

			<DialogContent className={classes.dialogGrid}>
				<Grid container spacing={3} justify="center" alignItems="center">
					<Grid item xs={6}>
						<TextField
							name="synonym"
							variant="outlined"
							size="small"
							label="Bírónév"
							fullWidth={true}
							disabled={true}
							value={rule.synonym}
							inputRef={register}
							InputProps={{
								className: classes.input
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							name="court"
							variant="outlined"
							id="combo-box-demo"
							size="small"
							disabled={true}
							fullWidth={true}
							label="Bíróság"
							value={rule.courtName}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							name="created"
							variant="outlined"
							size="small"
							disabled={true}
							fullWidth={true}
							label="Létrehozás dátuma"
							value={rule.createDate}
							InputProps={{
								className: classes.input
							}}
						/>
					</Grid>
				</Grid>
				<br/>
				<Typography variant="body1">A(z) '{rule.synonym}' szöveg az alábbi dokumentum(ok)ban került felismerésre:</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell>Dok. meta-adatai</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>FaCím1</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ruleRelatedDocs?.map((doc, index) => (
							<TableRow key={doc.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell>
									<IconButton aria-label="create" title="Dok. meta-adatai" onClick={() => editDoc(doc.id)} >
										<Icon fontSize="small">create</Icon>
									</IconButton>
								</TableCell>
								<TableCell align="right">{doc.id}</TableCell>
								<TableCell>{doc.title1}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog} color="primary" autoFocus={true}>
					Mégsem
				</Button>
				<Button
					variant="contained"
					color="primary"
					type="button"
					className={classes.redButton}
					startIcon={<Icon>saveIcon</Icon>}
					onClick={submit}
				>
					Jóváhagyásra váró szöveg törlése
				</Button>
			</DialogActions>
		</form>

		<Backdrop className={classes.backdrop} open={backdrop}>
			<CircularProgress color="inherit"/>
		</Backdrop>
	</div>
	);
};