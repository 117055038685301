import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CssBaseline from "@material-ui/core/CssBaseline";
import Sidebar from "./Sidebar";
import {userContext} from "../context";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as api from "../api";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${Sidebar.drawerWidth}px)`,
        marginLeft: Sidebar.drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -Sidebar.drawerWidth
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

export default function Container({children}) {
    const classes = useStyles();
    const auth = useContext(userContext);
    const [sidebar, setSidebar] = useState(false);
    const [profileMenu, setProfileMenu] = useState(null);

    const profile = auth.profile;

    const logout = () => {
        setProfileMenu(null);
        setSidebar(false);
        api.logout(auth);
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: sidebar,
                })}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={clsx(classes.menuButton, {[classes.hide]: (sidebar || !auth.isAuthenticated)})}
                        color="inherit"
                        aria-label="menu" onClick={() => setSidebar(!sidebar)}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        ORAC Szerkesztői Rendszer
                    </Typography>
                    <Button
                        color="inherit"
                        className={!auth.isAuthenticated ? classes.hide : null}
                        startIcon={<AccountCircleIcon/>}
                        onClick={e => setProfileMenu(e.currentTarget)}>{profile ? profile.fullname : 'USER'}</Button>
                    <Menu
                        id="profile-menu"
                        anchorEl={profileMenu}
                        keepMounted
                        open={Boolean(profileMenu)}
                        onClose={() => setProfileMenu(null)}
                    >
                        <MenuItem onClick={logout}>Kijelentkezés</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <Sidebar open={sidebar} setOpen={setSidebar}/>

            <main className={clsx(classes.content, {[classes.contentShift]: sidebar})}>
                <div className={classes.drawerHeader}/>
                {children}
            </main>

        </div>
    );
}
