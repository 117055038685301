import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	Backdrop,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {useForm} from "react-hook-form";
import {useSnackbar} from 'notistack';
import {preparedComments} from "../../utils";

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	dialogGrid: {
		flexGrow: 1,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

// a feluletnek az megjelenesi allapotai lehetsegesek:
// - kiindulo allapot: a kapott szabalybeli nev (inaktiv), es komment mezok megjelenitese, valamint a nevhez mint globalis bironevhez kapcsolodo dokumentumok listaja
// - hibalista allapot: a kiindulo allapotban mentes gombra kovetkezhet be, ha a mentes soran utkozo szabalyokat talalt az ellenorzes
export default function InvalidRuleToJudgeRuleDialog({rule, courts, closeDialog, closeRefreshDialog, saveUrl, ruleRelatedDocs}) {
	const classes = useStyles();

	// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// a szerver-muveletek idejere a feluletet inaktivra allitjuk
	const [backdrop, setBackdrop] = React.useState(false);
	// a beallitott ertekek kozul a szovegeseket ezzel a modszerrel olvassuk ki (kulon inputRef-ek helyett)
	const {register, getValues} = useForm();
	// a kivalasztott birosag azonositoja
	const [newCourtId, setNewCourtId] = useState(rule.courtId ? courts.find(element => element.key === rule.courtId) : null);
	// a mentes soran utkozo szabalyokat megjelenito komponenst megjelenitesere hasznalt allapotok
	const [openSaveFailedDialog, setOpenSaveFailedDialog] = useState(false);
	// a mentes soran utkozo szabalyokat megjelenito komponens tartalma
	const [judgeRuleRelatedRules, setJudgeRuleRelatedRules] = useState();
	// a megjegyzes Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott/megadott szoveget
	const [newComment, setNewComment] = useState();

	const submit = () => {
		const courtId = newCourtId ? newCourtId.key : null;
		rule={ id:rule.id, courtId: courtId, comment: newComment, ...getValues() };
		setBackdrop(true);
		const request = {
			url: saveUrl.replace( '%id', rule.id ),
			method: 'POST',
			data: rule
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				if ( resp.data.relatedRules && resp.data.relatedRules.length ) {
					setJudgeRuleRelatedRules( resp.data.relatedRules );
					setOpenSaveFailedDialog( true );
				} else {
					enqueueSnackbar( 'Új bírónév "' + rule.synonym + '" jóváhagyása sikeres', {variant: "success"} );
					setBackdrop( false );
					closeRefreshDialog();
				}
			})
			.catch(handleError);
	};

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const handleSaveFailedDialogClose = () => {
		setOpenSaveFailedDialog(false);
		enqueueSnackbar('Hiba: bírónév jóváhagyása sikertelen', {variant: "error"});
	}

	const editDoc = (docId) => {
		window.open( '/bh/editor/' + docId , '_blank' );
	}

	return (
	<div>
		<form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
			<DialogTitle>
				Bírónév jóváhagyása
			</DialogTitle>

			<DialogContent className={classes.dialogGrid}>
				<Grid container spacing={3} justify="center" alignItems="center">
					<Grid item xs={6}>
						<TextField
							name="synonym"
							variant="outlined"
							size="small"
							label="Bírónév"
							fullWidth={true}
							disabled={true}
							value={rule.synonym}
							inputRef={register}
							InputProps={{
								className: classes.input
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						{courts ? <Autocomplete
							id="combo-box-demo"
							options={courts}
							size="small"
							getOptionLabel={(option) => option.value}
							onChange={(event, value) => setNewCourtId(value)}
							defaultValue={ rule.courtId ? courts.find(element => element.key === rule.courtId) : null }
							renderInput={(params) => <TextField {...params} label="Bíróság" variant="outlined"/> }
							/>
						: null }
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							id="combo-box-demo3"
							options={preparedComments}
							size="small"
							freeSolo={true}
							autoSelect={true}
							onChange={(event, value) => setNewComment(value)}
							renderInput={(params) => <TextField {...params} label="Megjegyzés" variant="outlined"/> }
						/>
					</Grid>
				</Grid>
				<br/>
				<Typography variant="body1">A(z) '{rule.synonym}' bírónév az alábbi dokumentum(ok)hoz kapcsolódik:</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell>Szerkesztés</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>FaCím1</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ruleRelatedDocs?.map((doc, index) => (
							<TableRow key={doc.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell>
									<IconButton aria-label="create" title="Szerkesztés" onClick={() => editDoc(doc.id)} >
										<Icon fontSize="small">create</Icon>
									</IconButton>
								</TableCell>
								<TableCell align="right">{doc.id}</TableCell>
								<TableCell>{doc.title1}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog} color="primary" autoFocus={true}>
					Mégsem
				</Button>
				<Button
					variant="contained"
					color="primary"
					type="button"
					className={classes.redButton}
					startIcon={<Icon>saveIcon</Icon>}
					onClick={submit}
				>
					Bírónév jóváhagyása
				</Button>
			</DialogActions>
		</form>
		
		<Dialog open={openSaveFailedDialog} onClose={() => setOpenSaveFailedDialog(false)} fullWidth maxWidth="lg">
			<DialogTitle id="customized-dialog-title" onClose={() => setOpenSaveFailedDialog(false)}>
				Szabály ütközés
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">Az új bírónév: '{rule.synonym}' az alábbi szabályokkal ütközik, 
					ezért nem hagyható jóvá!</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>Bíróság neve</TableCell>
							<TableCell>Szabály</TableCell>
							<TableCell>Létrehozás</TableCell>
							<TableCell>Módosítás</TableCell>
							<TableCell>Utolsó módosító</TableCell>
							<TableCell>Megjegyzés</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{judgeRuleRelatedRules?.map((rule, index) => (
							<TableRow key={rule.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{rule.id}</TableCell>
								<TableCell>{rule.courtName}</TableCell>
								<TableCell>{rule.ruleText}</TableCell>
								<TableCell>{rule.createDate}</TableCell>
								<TableCell>{rule.modifiedDate}</TableCell>
								<TableCell>{rule.username}</TableCell>
								<TableCell>{rule.comment}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus className={classes.button} variant="contained">
					OK
				</Button>
			</DialogActions>
		</Dialog>

		<Backdrop className={classes.backdrop} open={backdrop}>
			<CircularProgress color="inherit"/>
		</Backdrop>
	</div>
	);
};