import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import {Add as AddIcon, Delete as DeleteIcon, Refresh as RefreshIcon, Save as SaveIcon} from '@material-ui/icons';
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {v1 as uuidv1} from 'uuid';
import InlineEditor from "../../../components/InlineEditor";

const useStyles = makeStyles( ( theme ) => ({
  panel: {
    marginBottom: '1em'
  },
  table: {
    "& td": {
      padding: "6px"
    }
  },
  buttonCol: {
    width: "40px",
    padding: "6px"
  },
  typeSelect: {
    width: "100%",
    "& em": {
      color: "gray"
    }
  }
}) );

const MetaEditor = ( { meta, types, saveMeta, refreshMeta } ) => {
  const classes = useStyles();

  const [data, setData] = meta;
  const [metaType, setMetaType] = useState( '12' );

  const addEntry = () => {
    if( metaType ) {
      const type = parseInt( metaType );
      for( let t of data ) {
        if( t.id === type ) {
          if( t.values === undefined ) {
            t.values = [];
          }
          t.values.push( {
            id: uuidv1(),
            typeId: t.id,
            value: ''
          } );
          setData( [...data] );
        }
      }
    }
  };

  const removeEntry = metaValue => {
    setData( data.filter( type => {
      if( type.values ) {
        type.values = type.values.filter( value => value.id !== metaValue.id );
        if( type.values.length === 0 ) {
          delete type.values;
        }
      }
      return true;
    } ) );
  };

  const setMetaValue = ( value, newValue ) => {
    value.value = newValue;
    setData( [...data] );
  }

  return (
      <TableContainer component={Paper} className={classes.panel}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="right">
                <ButtonGroup variant="contained">
                  <Button
                      onClick={saveMeta}
                      color="primary"
                      startIcon={<SaveIcon/>}>Mentés</Button>
                  <Button
                      onClick={refreshMeta}
                      startIcon={<RefreshIcon/>}>Frissítés</Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.buttonCol}>
                <IconButton variant="contained" size="small" onClick={() => addEntry()}>
                  <AddIcon/>
                </IconButton>
              </TableCell>
              <TableCell>
                <Select
                    className={classes.typeSelect}
                    displayEmpty
                    value={metaType}
                    onChange={e => setMetaType( e.target.value )}
                >
                  <MenuItem value="">
                    <em>Válassz metaadat típust</em>
                  </MenuItem>
                  {types.map( type => <MenuItem key={"t" + type.id} value={type.id}>{type.name}</MenuItem> )}
                </Select>
              </TableCell>
            </TableRow>
          </TableHead>

          {data?.filter( type => type.values ).map( type => (
                  <TableBody key={type.id}>
                    <TableRow>
                      <TableCell colSpan={2} variant="head">{type.name}</TableCell>
                    </TableRow>
                    {type.values.map( v => {
                      return (
                          <TableRow key={"md" + v.id}>
                            <TableCell className={classes.deleteCell}>
                              <IconButton size="small" onClick={() => removeEntry( v )}>
                                <DeleteIcon/>
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <InlineEditor value={v.value} setValue={value => setMetaValue( v, value )}/>
                            </TableCell>
                          </TableRow>
                      );
                    } )}
                  </TableBody>
              )
          )}

        </Table>
      </TableContainer>
  );
};

export default MetaEditor;
