import React from "react";
import {Router} from "@reach/router";
import Page from "./components/Page";
import * as guards from "./guards";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import BHEditor from "./pages/bh/editor/BHEditor";
import PrecList from "./pages/bh/PrecList";
import BHLinkConflict from "./pages/bh/BHLinkConflict";
import JudgeBlacklist from "./pages/bhgy/JudgeBlacklist";
import InvalidRules from "./pages/bhgy/InvalidRules";
import JudgeSynonyms from "./pages/bhgy/JudgeSynonyms";
import ValidJudges from "./pages/bhgy/ValidJudges";
import NotEnoughJudges from "./pages/bhgy/NotEnoughJudges";
import NoneJudges from "./pages/bhgy/NoneJudges";
import DocTypes from "./pages/meta/DocTypes";
import Issuers from "./pages/meta/Issuers";
import Covid19 from "./pages/meta/Covid19";
import Campaign from "./pages/other/campaign/Campaign";
import ChangeMonitoring from "./pages/changemonitoring/ChangeMonitoring";
import ChangeMonitoringEmailsByDate from "./pages/changemonitoring/ChangeMonitoringEmailsByDate";

import axios from "axios";

axios.interceptors.response.use(
    response => response,
    error => {
      if( error.response.status === 401 ) {
        window.localStorage.clear();
        window.location = '/login';
      } else {
        return Promise.reject( error );
      }
    } );

export default () => (
    <Router>
      <Page path="/login" guard={guards.unauthenticated} component={Login}/>
      <Page path="/home" guard={guards.authenticated} component={Home} default/>
      <Page path="/bh/editor" guard={guards.authenticated} component={BHEditor}/>
      <Page path="/bh/editor/:id" guard={guards.authenticated} component={BHEditor}/>
      <Page path="/bh/prec" guard={guards.authenticated} component={PrecList}/>
      <Page path="/bhgy/judgeblacklist" guard={guards.authenticated} component={JudgeBlacklist}/>
      <Page path="/bhgy/validjudges" guard={guards.authenticated} component={ValidJudges}/>
      <Page path="/bhgy/judgesynms" guard={guards.authenticated} component={JudgeSynonyms}/>
      <Page path="/bhgy/invalidjudges" guard={guards.authenticated} component={InvalidRules}/>
      <Page path="/bhgy/notenoughjudges" guard={guards.authenticated} component={NotEnoughJudges}/>
      <Page path="/bhgy/nonejudges" guard={guards.authenticated} component={NoneJudges}/>
      <Page path="/bh/bh-bad-links" guard={guards.authenticated} component={BHLinkConflict}/>
      <Page path="/meta/doctypes" guard={guards.authenticated} component={DocTypes}/>
      <Page path="/meta/issuers" guard={guards.authenticated} component={Issuers}/>
      <Page path="/meta/covid19" guard={guards.authenticated} component={Covid19}/>
      <Page path="/other/changemonitoring" guard={guards.authenticated} component={ChangeMonitoring}/>
      <Page path="/other/changemonitoringemails/:id" guard={guards.authenticated} component={ChangeMonitoringEmailsByDate}/>
      <Page path="/other/campaign" guard={guards.authenticated} component={Campaign}/>

      <Page
          path="/profile"
          guard={guards.authenticated}
          component={Profile}
      />

    </Router>
);
