import React, {useState,useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {useSnackbar} from 'notistack';

import {v1 as uuidv1} from 'uuid';
import axios from 'axios';

import UserFriendlyDocMeta from "./UserFriendlyDocMeta";
import Text from "./Text";
import MetaEditor from "./MetaEditor";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        marginBottom: '1em'
    },
    title: {
        padding: "0 1em"
    },
    docMetaTable: {
        marginBottom: '1em'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function BHEditor(props) {
    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [toolbar, setToolbar] = useState(1);
    const [docId, setDocId] = useState( ( props && props.id ) ? props.id : '555555' );
    const [rows, setRows] = useState(null);
    const [docMeta, setDocMeta] = useState(null);
    const [meta, setMeta] = useState([]);
    const [metaType, setMetaType] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    const beginTrans = () => {
        setBackdrop(true);
    };

    const endTrans = (msg) => {
        setBackdrop(false);

        enqueueSnackbar(msg.message, {variant: msg.type})
    };

    const createMetaMap = (types, values) => {
        const map = {};
        const result = [];

        types.forEach(t => {
            const g = map[t.id] = {id: t.id, name: t.name};
            result.push(g);
        });

        values.forEach(value => {
            if (value.typeId === undefined || value.value === undefined) {
                console.log("Unknown object:", value);
                return;
            }

            const t = map[value.typeId];
            if (t) {
                if (t.values === undefined) {
                    t.values = [];
                }
                value.id = uuidv1();
                t.values.push(value);
            } else {
                console.log("Unknown type:", value);
            }
        });
        debugger
        return result;
    };

    const openDocument = e => {
        if (e) {
            e.preventDefault();
        }

        const id = /\d{1,6}/.test(docId || '') ? parseInt(docId) : 0;
        if (id === 0) {
            return;
        }

        beginTrans();
        axios.all([
            axios('/api/doc/' + id + "/uf-meta"),
            axios('/api/meta/types'),
            axios('/api/meta/' + id)
        ])
            .then(([docResponse, metaTypeResponse, metaResponse]) => {
                setDocMeta(docResponse.data);
                setMeta(createMetaMap(metaTypeResponse.data, metaResponse.data));
                setMetaType(metaTypeResponse.data);
                setRows(null);
                endTrans({message: "Dokumentum betöltve", type: 'success'});
            })
            .catch(error => {
                endTrans({message: "Hiba történt", type: 'error'});
                console.error(error);
            });
    };

    useEffect( () => {
        if ( props && props.id ) 
        {
          openDocument();
        }
    }, [] );

    const loadText = () => {
        beginTrans();
        axios('/api/doc/' + docMeta.id)
            .then(resp => {
                setRows(resp.data);
                endTrans({message: "Dokumentum szöveg betöltve", type: 'success'});
            })
            .catch(error => {
                endTrans({message: "Hiba történt", type: 'error'});
                console.error(error);
            });
    };

    const saveMeta = () => {
        const data = [];
        meta.forEach(m => {
            if (m.values) {
                m.values.forEach(v => {
                    if (v.value && v.value.length) {
                        data.push({typeId: m.id, value: v.value});
                    }
                });
            }
        });
        beginTrans();
        const request = {
            url: '/api/meta/' + docMeta.id,
            method: 'POST',
            data
        };
        axios.all([
            axios('/api/meta/types'),
            axios(request)
        ])
            .then(([metaTypeResponse, metaResponse]) => {
                setMeta(createMetaMap(metaTypeResponse.data, metaResponse.data));
                setMetaType(metaTypeResponse.data);
                endTrans({message: "Metaadatok elmentve", type: 'success'});
            })
            .catch(error => {
                endTrans({message: "Hiba történt", type: 'error'});
                console.error(error);
            });
    };

    const refreshMeta = () => {
        beginTrans();
        axios.all([
            axios('/api/meta/types'),
            axios('/api/meta/' + docMeta.id)
        ])
            .then(([metaTypeResponse, metaResponse]) => {
                setMeta(createMetaMap(metaTypeResponse.data, metaResponse.data));
                setMetaType(metaTypeResponse.data);
                endTrans({message: "Metaadatok frissítve", type: 'success'});
            })
            .catch(error => {
                endTrans({message: "Hiba történt", type: 'error'});
                console.error(error);
            });
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={9}>
                    <Paper
                        component="form"
                        className={classes.toolbar}
                        elevation={toolbar}
                        onMouseEnter={() => setToolbar(4)}
                        onMouseLeave={() => setToolbar(1)}
                        onSubmit={e => openDocument(e)}>
                        <Typography className={classes.title} variant="h6" noWrap>
                            BH Szerkesztő
                        </Typography>
                        <Divider className={classes.divider} orientation="vertical"/>
                        <InputBase
                            value={docId}
                            className={classes.input}
                            placeholder="Dok. ID"
                            onChange={e => setDocId(e.target.value)}
                            autoFocus={true}
                            inputProps={{
                                maxLength: 6
                            }}
                        />
                        <Button type="submit" className={classes.iconButton}>
                            Megnyitás
                        </Button>
                    </Paper>

                    {docMeta ? <UserFriendlyDocMeta meta={docMeta}/> : null}

                    {rows
                        ? <Text rows={rows}/>
                        : docMeta?.id
                            ? <Button variant="contained" color="secondary" onClick={() => loadText()}>Dokumentum szövegének
                                betöltése...</Button>
                            : null}

                </Grid>
                <Grid item xs={12} sm={3}>
                    {docMeta ? <MetaEditor meta={[meta, setMeta]} types={metaType} saveMeta={saveMeta}
                                           refreshMeta={refreshMeta}/> : null}
                </Grid>
            </Grid>

            <Backdrop className={classes.backdrop} open={backdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    );
}
