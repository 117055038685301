import React, {useEffect, useState} from "react";

import {makeStyles, createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Icon from "@material-ui/core/Icon";
import LaunchIcon from "@material-ui/icons/Launch";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

import MUIDataTable from "mui-datatables";

import axios from "axios";
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {useSnackbar} from "notistack";

import Loader from "../../../components/Loader";
import {customRowIndexColumn, DATE_FORMAT, muiTableLabels} from "../../../utils";
import ShortDate from "../../../components/ShortDate";

import AddDocDialog from "./AddDocDialog";


const useStyles = makeStyles((theme) => ({
    docTable: {
        paddingTop: '1em',
    },
    sample: {
        width: '100%',
        padding: 10,
        textAlign: 'center',
        verticalAlign: 'center',
    },
    sampleBackground: {
        backgroundColor: '#eeeae2',
    }
}));

const theme = () => createMuiTheme({
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                '&:nth-child(2)': {width: '40px !important', textAlign: 'center',},
                '&:nth-child(4)': {width: '40px !important', textAlign: 'right',},
                '&:nth-child(6)': {width: '80px !important', textAlign: 'right',},
            }
        }
    }
});

const COLORS = ['black', 'white', 'red', 'lime', 'blue', 'yellow', 'aqua', 'fuchsia', 'silver', 'gray', 'maroon', 'olive', 'green', 'purple', 'teal', 'navy'];

const COLOR_TOOLTIP = 'Használható színek nevei: ' + COLORS.join(', ')

const fixedDocumentColumns = [
    {name: 'id', label: 'Dok ID', options: {sort: true, filter: false}},
    {name: 'title1', label: 'FaCím1', options: {sort: false, filter: false}},
    {name: 'title2', label: 'FaCím1', options: {sort: false, filter: false}},
    {
        name: 'conflict',
        label: 'Ütközik',
        options: {sort: true, filter: true, customBodyRender: value => value ? <SentimentVeryDissatisfiedIcon/> : null}
    }
];

const documentOptions = {
    pagination: true,
    selectableRows: 'none',
    print: false,
    downloadOptions: {filename: 'docs.csv', separator: ','},
    viewColumns: false,
    textLabels: muiTableLabels,
};


export default function CampaignEditor({campaign, closeDialog}) {
    const classes = useStyles();

    const {enqueueSnackbar} = useSnackbar();

    const {register, handleSubmit, errors, reset, getValues, triggerValidation, control, watch} = useForm({
        defaultValues: campaign
    });

    const {fields: docList} = useFieldArray({control, name: "docs"});
    useFieldArray({control, name: "conflicts"});

    const [loading, setLoading] = useState(false);

    const [documents, setDocuments] = useState(null);

    const [openAddDocDialog, setOpenAddDocDialog] = useState(false);

    const handleError = (error) => {
        enqueueSnackbar('Hiba történt...', {variant: "error"});
        console.log(error);
        setLoading(false);
    }

    const setForm = camp => {
        camp.startDate = ShortDate.toDateStr(camp.startDate);
        camp.endDate = ShortDate.toDateStr(camp.endDate, '');
        reset(camp);
        setLoading(false);

        loadDocumentTitles(camp.docs, camp.conflicts);
    };

    const onSubmit = data => {
        setLoading(true);

        data.startDate = ShortDate.parse(data.startDate);
        data.endDate = ShortDate.parse(data.endDate);

        data = {
            ...data,
            docs: (documents || []).map(doc => doc.id),
        };


        const request = {
            url: '/api/campaign',
            method: 'POST',
            data,
        };
        axios(request)
            .then(resp => {
                setForm(resp.data);
                enqueueSnackbar(
                    'Sikeres mentés',
                    {
                        variant: "success",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                    });
            })
            .catch(handleError);
    };

    const showAddDocDialog = () => setOpenAddDocDialog(true);

    const closeAddDocDialog = () => setOpenAddDocDialog(false);

    const addDocuments = (docs) => {
        const test = [...docs];
        docList.forEach(doc => test.push(doc.value));

        setDocuments(null);

        const request = {
            url: '/api/campaign/docs',
            method: 'POST',
            data: {
                id: getValues().id,
                docs: test.join(' '),
                test: false,
            },
        };
        axios(request)
            .then(resp => {
                const list = resp.data;
                const docs = [];
                const conflicts = [];
                list.forEach(doc => {
                    docs.push(doc.id);
                    if (doc.conflict) {
                        conflicts.push(doc.id);
                    }
                });
                reset({...getValues(), docs, conflicts});
                setDocuments(list);
            });
    };

    const removeDocument = id => {
        setDocuments(documents.filter(doc => doc.id !== id));
    };

    const loadDocumentTitles = (docs, conflicts) => {
        setDocuments(null);

        const request = {
            url: '/api/doc/titles',
            method: 'POST',
            data: docs
        };
        axios(request)
            .then(resp => {
                const docs = resp.data;
                docs.forEach(doc => doc.conflict = conflicts.indexOf(doc.id) >= 0 ? 'Igen' : '');
                setDocuments(docs);
            });
    };

    useEffect(() => {
        if (getValues().id) {
            setLoading(true);
            const request = {
                url: '/api/campaign/' + getValues().id,
                method: 'GET',
            };
            axios(request)
                .then(resp => setForm(resp.data));
        }

    }, []);


    const linkTypeValidator = value => {
        let linkType = getValues().linkType;

        let result = true;

        switch (linkType) {
            case 1:
                result = /^(\d{1,6}('\d{1,5})?|'\d{1,5})$/.test(value);
                break
            case 2:
                result = /^https?:\/\/\S+$/.test(value);
                break
            default:
                result = true;
                break;
        }
        return result;
    };

    const colorValidator = value => {
        return /^#[0-9a-f]{6}$/i.test(value) || COLORS.indexOf((value || '').toLowerCase()) >= 0;
    };

    const documentColumns = [
        {
            name: 'id',
            options: {
                filter: false,
                sort: false,
                customHeadRender: meta => (
                    <th key={meta.index} className="MuiTableCell-root MuiTableCell-head">
                        <IconButton title="Dokumentumok hozzáadása" style={{float: 'right'}} onClick={showAddDocDialog}>
                            <Icon style={{color: '#008000'}}>add_circle</Icon>
                        </IconButton>
                    </th>
                ),
                customBodyRender: value => (
                    <IconButton title="Törlés" onClick={() => removeDocument(value)}>
                        <Icon fontSize="small">delete</Icon>
                    </IconButton>
                ),
            }
        },
        customRowIndexColumn(),
        ...fixedDocumentColumns
    ];

    return (
        <>
            <DialogTitle id="ce-campaign-dlg-title" onClose={closeDialog}>
                {getValues().id ? 'Kampány szerkesztése #' + getValues().id : 'Új kampány létrehozása'}
            </DialogTitle>

            <DialogContent id="ce-campaign-dlg-content" dividers={true}>
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                    <input name="id" type="hidden" ref={register()}/>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label="Kampány név"
                                inputRef={register({required: true})}
                                onChange={() => triggerValidation('name')}
                                required
                                error={!!errors.name}
                                variant="outlined"
                                fullWidth
                                autoFocus/>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="active"
                                        as={Switch}
                                        control={control}
                                        type="checkbox"
                                    />}
                                label="Aktív"/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="startDate"
                                label="Kezdő nap"
                                helperText="éééé.hh.nn"
                                inputRef={register({required: true, pattern: DATE_FORMAT})}
                                onChange={() => triggerValidation('startDate')}
                                required
                                error={!!errors.startDate}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="endDate"
                                label="Utolsó nap"
                                helperText="éééé.hh.nn"
                                inputRef={register({required: false, pattern: DATE_FORMAT})}
                                onChange={() => triggerValidation('endDate')}
                                error={!!errors.endDate}
                                variant="outlined"
                                fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="ce-foreground-color">Betűszín</InputLabel>
                                <Controller
                                    name="foregroundColor"
                                    control={control}
                                    as={
                                        <Select
                                            id="ce-foreground-color"
                                            label="Betűszín"
                                        >
                                            <MenuItem value="black">Fekete</MenuItem>
                                            <MenuItem value="white">Fehér</MenuItem>
                                        </Select>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="backgroundColor"
                                label="Háttérszín"
                                helperText="HTML színkód (#RRGGBB) vagy angol név"
                                title={COLOR_TOOLTIP}
                                inputRef={register({validate: {format: colorValidator}})}
                                onChange={() => triggerValidation('backgroundColor')}
                                required
                                error={!!errors.backgroundColor}
                                variant="outlined"
                                fullWidth/>

                        </Grid>
                        <Grid item xs={2} className={classes.sampleBackground}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                className={classes.sample}
                                style={{
                                    color: watch('foregroundColor') || 'black',
                                    backgroundColor: watch('backgroundColor') || 'white'
                                }}
                            >
                                Tesztszöveg
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="text"
                                label="Szöveg (HTML)"
                                inputRef={register({required: true})}
                                onChange={() => triggerValidation('text')}
                                required
                                error={!!errors.text}
                                variant="outlined"
                                fullWidth/>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="ce-link-type">Link típus</InputLabel>
                                <Controller
                                    name="linkType"
                                    control={control}
                                    onChange={([selected]) => {
                                        triggerValidation('link');
                                        return selected;
                                    }}
                                    as={
                                        <Select
                                            id="ce-link-type"
                                            label="Link típus"
                                        >
                                            <MenuItem value={1}>JK link</MenuItem>
                                            <MenuItem value={2}>URL</MenuItem>
                                        </Select>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                name="link"
                                label="Link"
                                inputRef={register({validate: {linkPattern: linkTypeValidator}})}
                                onChange={() => triggerValidation('link')}
                                required
                                error={!!errors.link}
                                variant="outlined"
                                fullWidth/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.docTable}>
                        {getValues().id ?
                            (documents
                                    ? <MuiThemeProvider theme={theme()}>
                                        <MUIDataTable
                                            data={documents}
                                            columns={documentColumns}
                                            options={documentOptions}
                                        />
                                    </MuiThemeProvider>
                                    : <Loader/>
                            )
                            : (
                                <Paper elevation={2}>
                                    <Alert severity="info">A kampanyt először el kell menteni, hogy dokumentumot lehessen
                                        hozzáadni.</Alert>
                                </Paper>
                            )}

                    </Grid>

                    <Grid item xs={12}>
                        <p>
                            <Link href="https://html-color-codes.info/" variant="body1" target="_blank">
                                <LaunchIcon/> HTML színkód generátor
                            </Link>
                        </p>
                    </Grid>

                    <input type="submit" hidden/>

                </form>

                <Dialog
                    open={openAddDocDialog}
                    onClose={closeAddDocDialog}
                    fullWidth
                    maxWidth="lg"
                    scroll="paper"
                    disableBackdropClick={true}
                >
                    <AddDocDialog
                        id={getValues().id}
                        closeDialog={closeAddDocDialog}
                        addDocuments={addDocuments}/>
                </Dialog>

            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Icon>save</Icon>}
                    onClick={handleSubmit(onSubmit)}
                    disabled={loading}
                >
                    Mentés
                </Button>
                <Button
                    variant="contained"
                    startIcon={<Icon>cancel</Icon>}
                    onClick={closeDialog}
                    disabled={loading}
                >
                    Bezárás
                </Button>
            </DialogActions>
        </>
    );
};
