import React, {createRef, useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import ShortDate from "../../components/ShortDate";
import Loader from "../../components/Loader";
import axios from "axios";
import {useSnackbar} from 'notistack';
import {customRowIndexColumn, muiTableLabels} from "../../utils";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        padding: "1em",
        margin: "1em 0"
    },
    table: {
        marginBottom: "1em"
    },
    button: {
        marginTop: "5px"
    },
    buttonCol: {
        width: "40px",
        padding: "6px"
    },
    indexCol: {
        width: "40px",
        padding: "6px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const fixDocumentColumns = [
    customRowIndexColumn(),
    {name: 'docId', label: 'ID', options: {sort: true, filter: false}},
    {name: 'title1', label: 'FaCím1', options: {sort: true, filter: false}},
    {name: 'title2', label: 'FaCím2', options: {sort: true, filter: false}},
    {name: 'emitDate', label: 'KibIdő', options: {sort: true, sortDirection: 'desc'}},
    {name: 'startDate', label: 'SD', options: {sort: true}},
    {name: 'endDate', label: 'ED', options: {sort: true}},
    {name: 'docTypeName', label: 'Dok. típus', options: {sort: true}},
    {name: 'createDate', label: 'Dátum', options: {sort: true}},
];

const documentOptions = {
    pagination: false,
    selectableRows: 'none',
    print: false,
    downloadOptions: {filename: 'covid19-dokumentumok.csv', separator: ','},
    viewColumns: false,
    textLabels: muiTableLabels
};

const Covid19 = () => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [backdrop, setBackdrop] = React.useState(false);

    const [documents, setDocuments] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [testDocuments, setTestDocuments] = useState();
    const newDocuments = createRef();

    const setup = (docs) => {
        docs.forEach(doc => {
            doc.emitDate = ShortDate.toDateStr(doc.emitDate);
            doc.startDate = ShortDate.toDateStr(doc.startDate);
            doc.endDate = ShortDate.toDateStr(doc.endDate);
        });
        return docs;
    };

    useEffect(() => {

        axios("/api/covid19").then(resp => {
            setDocuments(setup(resp.data));
        });

    }, []);

    const handleError = (error) => {
        setBackdrop(false);
        enqueueSnackbar('Hiba történt...', {variant: "error"});
        console.log(error);
    }

    const testDoucment = () => {
        const docs = newDocuments.current.value;
        if (!docs || !docs.trim().length) {
            enqueueSnackbar('Nem adtál meg dokumentum azonosítót', {variant: "warning"});
            return;
        }

        setBackdrop(true);

        const request = {
            url: '/api/covid19/test',
            method: 'POST',
            data: docs
        };
        axios(request)
            .then(resp => {
                setBackdrop(false);
                if (resp.data.length) {
                    setTestDocuments(resp.data);
                    setOpenDialog(true);
                } else {
                    enqueueSnackbar('A megadott azonosítók már szerepeltek az adatbázisban', {variant: "warning"});
                }
            })
            .catch(handleError);
    };

    const saveDoucment = () => {
        setBackdrop(true);
        setOpenDialog(false);

        const docs = [];
        if (testDocuments) {
            testDocuments.map(doc => docs.push(doc.docId));
        }

        const request = {
            url: '/api/covid19',
            method: 'POST',
            data: docs.join(',')
        };
        axios(request)
            .then(resp => {
                setBackdrop(false);
                if (resp.data.length) {
                    setDocuments(setup(resp.data));
                    setTestDocuments(null);
                    setOpenDialog(false);
                } else {
                    enqueueSnackbar('Sikeres mentés', {variant: "success"});
                }
            })
            .catch(handleError);
    };

    const enter = e => {
        if (e.keyCode === 13) {
            testDoucment();
        }
    };

    const deleteDoc = (docId) => {
        setBackdrop(true);

        const request = {
            url: '/api/covid19/' + docId,
            method: 'DELETE'
        };
        axios(request)
            .then(resp => {
                setDocuments(setup(resp.data));
                setBackdrop(false);
                enqueueSnackbar('Dokumentum sikeresen törölve: ' + docId, {variant: "success"});
            })
            .catch(handleError);
    };

    const documentColumns = [
        {
            name: "docId",
            label: "Törlés",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton aria-label="delete" onClick={() => deleteDoc(value)} title="Törlés">
                            <Icon fontSize="small">delete</Icon>
                        </IconButton>
                    );
                }
            }
        },
        ...fixDocumentColumns
    ];

    const loader = documents ? null : <Loader/>;

    return (
        <div>
            <Typography variant="h4">Veszélyhelyzeti rendeletalkotással érintett dokumentumok</Typography>

            <Paper elevation={3} className={classes.toolbar}>
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label="Dokumentumazonosítók..."
                    helperText="Írd ide a hozzáadandó dokumentumok azonosítóit"
                    inputRef={newDocuments}
                    onKeyUp={enter}
                />
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<Icon>send</Icon>}
                    onClick={testDoucment}
                >
                    Küldés
                </Button>
            </Paper>

            {loader}

            <Paper elevation={3} className={classes.table}>
                <MUIDataTable
                    data={documents}
                    columns={documentColumns}
                    options={documentOptions}
                />
            </Paper>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="lg">
                <DialogTitle id="customized-dialog-title" onClose={() => setOpenDialog(false)}>
                    Új dokumentumok
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">A megadott azonosítókból a következők nem lettek még rögzíve:</Typography>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right" className={classes.indexCol}>#</TableCell>
                                <TableCell align="right">ID</TableCell>
                                <TableCell>FaCím1</TableCell>
                                <TableCell>FaCím2</TableCell>
                                <TableCell>KibIdő</TableCell>
                                <TableCell>SD</TableCell>
                                <TableCell>ED</TableCell>
                                <TableCell>Dok. típus</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {testDocuments?.map((doc, index) => (
                                <TableRow key={'c19id' + doc.docId}>
                                    <TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
                                    <TableCell align="right">{doc.docId}</TableCell>
                                    <TableCell>{doc.title1}</TableCell>
                                    <TableCell>{doc.title2}</TableCell>
                                    <TableCell><ShortDate date={doc.emitDate}/></TableCell>
                                    <TableCell><ShortDate date={doc.startDate}/></TableCell>
                                    <TableCell><ShortDate date={doc.endDate}/></TableCell>
                                    <TableCell>{doc.docTypeName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<Icon>save</Icon>}
                        onClick={saveDoucment}
                        autoFocus
                    >
                        Hozzáadás a listához
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={backdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
};

export default Covid19;