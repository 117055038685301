import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Backdrop,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {useSnackbar} from 'notistack';

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

// a feluletnek az megjelenesi allapotai lehetsegesek:
// - kiindulo allapot: a kapott szabalyok megjelenitese tablazatban, valamint a hozzajuk kapcsolodo dokumentumok listaja
export default function InvalidRuleMultiDeleteDialog({rules, closeDialog, closeRefreshDialog, saveUrl, rulesRelatedDocs}) {
	const classes = useStyles();

	// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// a szerver-muveletek idejere a feluletet inaktivra allitjuk
	const [backdrop, setBackdrop] = React.useState(false);

	const getRulesText = () => {
		return '"' + rules.map( rule => rule.synonym ).join( '", "' ) + '"';
	};

	const submit = () => {
		setBackdrop(true);
		const request = {
			url: saveUrl,
			method: 'DELETE',
			data: rules
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				enqueueSnackbar( 'Jóváhagyásra váró szövegek: ' + getRulesText() + ' törlése sikeres', {variant: "success"} );
				setBackdrop( false );
				closeRefreshDialog();
			})
			.catch(handleError);
	};

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const editDoc = (docId) => {
		window.open( '/bh/editor/' + docId , '_blank' );
	}

	return (
	<div>
		<DialogTitle>
			Jóváhagyásra váró szöveg(ek) törlése
		</DialogTitle>

		<DialogContent className={classes.dialogGrid}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell align="right" className={classes.indexCol}>#</TableCell>
						<TableCell align="right">ID</TableCell>
						<TableCell>Bíróság neve</TableCell>
						<TableCell>Szabály</TableCell>
						<TableCell>Létrehozás</TableCell>
						<TableCell>Módosítás</TableCell>
						<TableCell>Utolsó módosító</TableCell>
						<TableCell>Megjegyzés</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rules?.map((rule, index) => (
						<TableRow key={rule.id}>
							<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
							<TableCell align="right">{rule.id}</TableCell>
							<TableCell>{rule.courtName}</TableCell>
							<TableCell>{rule.ruleText}</TableCell>
							<TableCell>{rule.createDate}</TableCell>
							<TableCell>{rule.modifiedDate}</TableCell>
							<TableCell>{rule.username}</TableCell>
							<TableCell>{rule.comment}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<br/>
			<Typography variant="body1">A(z) '{getRulesText()}' szöveg(ek) az alábbi dokumentum(ok)ban került(ek) felismerésre:</Typography>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell align="right" className={classes.indexCol}>#</TableCell>
						<TableCell>Dok. meta-adatai</TableCell>
						<TableCell align="right">ID</TableCell>
						<TableCell>FaCím1</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rulesRelatedDocs?.map((doc, index) => (
						<TableRow key={doc.id}>
							<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
							<TableCell>
								<IconButton aria-label="create" title="Dok. meta-adatai" onClick={() => editDoc(doc.id)} >
									<Icon fontSize="small">create</Icon>
								</IconButton>
							</TableCell>
							<TableCell align="right">{doc.id}</TableCell>
							<TableCell>{doc.title1}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</DialogContent>
		<DialogActions>
			<Button onClick={closeDialog} color="primary" autoFocus={true}>
				Mégsem
			</Button>
			<Button
				variant="contained"
				color="primary"
				type="button"
				className={classes.redButton}
				startIcon={<Icon>deleteIcon</Icon>}
				onClick={submit}
			>
				Jóváhagyásra váró szöveg(ek) törlése
			</Button>
		</DialogActions>

		<Backdrop className={classes.backdrop} open={backdrop}>
			<CircularProgress color="inherit"/>
		</Backdrop>
	</div>
	);
};