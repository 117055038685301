import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import { 
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Icon,
  Paper,
  Typography } from "@material-ui/core";
import {customRowIndexColumn} from "../../utils";
import {useSnackbar} from 'notistack';
import axios from "axios";
import Loader from "../../components/Loader";
import MultiSelectTableToolbar from "../../components/MultiSelectTableToolbar";
import MUIDataTable from "mui-datatables";
import EmailViewDialog from "./EmailViewDialog";
import EmailSendProgressDialog from "./EmailSendProgressDialog";

const useStyles = makeStyles( ( theme ) => ({
  redButton: {
    color: '#ffffff',
    backgroundColor: '#ff0000',
    '&:hover': {
      backgroundColor: '#aa0000'
    }
  },
  toolbar: {
    padding: "1em",
    margin: "1em 0"
  },
  panel: {
    marginBottom: '1em'
  },
  table: {
    "& th": {
      textAlign: "center",
      padding: "6px"
    },
    "& td": {
      padding: "6px"
    }
  },
  buttonCol: {
    width: "80px",
    padding: "6px"
  },
  typeSelect: {
    width: "100%",
    "& em": {
      color: "gray"
    }
  },
  typography: {
    padding: "10px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}) );

export default function ChangeMonitoringEmailsByDate(props) {
  const classes = useStyles();
  // felugro szovegek idejeben es kesleltetett muveletekben hasznalt ido
  const LONG_SNACKBAR_MILLIS = 5000;
  // a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
  const {enqueueSnackbar} = useSnackbar();
  // email-ek listaja, a fooldalon tablazatban jelennek meg
  const [emails, setEmails] = useState();
  // kivalasztott email
  const [actEmail, setActEmail] = useState([]);
  // a megjelenito ablak megjeleniteset szabalyzo flag
  const [openViewDialog, setViewDialog] = useState(false);
  // progress jelzes a lassu muveletekhez
  const [backdrop, setBackdrop] = useState(false);
  // tablazat kijelolt soraihoz tartozo szabalyokat tarolo valtozo
  const [tableRowsSelected, setTableRowsSelected] = useState([]);
  // tobb email kuldesekor folyamatjelzo dialogus megjelenitesi flag-je
  const [openProgressDialog, setProgressDialog] = useState(false);
  // tobb email kuldesekor az kuldendo id-lista
  const [progressIdList, setProgressIdList] = useState();

  const showViewDialog = () => setViewDialog(true);
  const closeViewDialog = () => setViewDialog(false);
  const showProgressDialog = () => setProgressDialog(true);
  const closeProgressDialog = () => { setProgressDialog(false); setTimeout( () => { loadFiles(); setBackdrop( false ); }, LONG_SNACKBAR_MILLIS ); }

  const sendDialog = (email) => {
    if (!window.confirm("Biztosan küldhető az email a " + email.emailAddress + " címre?")) {
      return;
    }
    setBackdrop(true);
    const request = {
      url: '/api/changemonemails/send/' + email.id,
      timeout: 120000,
      method: 'POST',
    };
    axios(request)
      .then(resp => {
        if ( resp.data.failed )
        {
          enqueueSnackbar( "Email küldési hiba: " + resp.data.failed, { variant: 'error', autoHideDuration: LONG_SNACKBAR_MILLIS } );
          setBackdrop( false );
        }
        else
        {
          enqueueSnackbar("Email küldés sikeres: " + resp.data.email.emailAddress, { variant: 'success', autoHideDuration: LONG_SNACKBAR_MILLIS } );
          setTimeout( () => { loadFiles(); setBackdrop( false ); }, LONG_SNACKBAR_MILLIS );
        }
      })
      .catch( e => handleError(e) );
  };

  const refreshEmailsState = (emailList) => {
    if (!window.confirm("Biztosan kerüljön frissítésre " + emailList.length + "db email állapota?")) {
      return;
    }
    setBackdrop(true);
    let ids = emailList.map( i => i.id );
    const request = {
      url: '/api/changemonemails/refreshmulti/' + ids.join( ','),
      timeout: 12000,
      method: 'POST',
    };
    axios(request)
      .then(resp => {
        if ( resp.data.failed )
        {
          enqueueSnackbar( "Email állapot frissítési hiba: " + resp.data.failed, { variant: 'error', autoHideDuration: LONG_SNACKBAR_MILLIS } );
          setBackdrop( false );
        }
        else
        {
          enqueueSnackbar("Email állapot frisítés sikeres: " + resp.data.count + "db státusz változott", { variant: 'success', autoHideDuration: LONG_SNACKBAR_MILLIS } );
          setTimeout( () => { loadFiles(); setBackdrop( false ); }, 1000 );
        }
      })
      .catch( e => handleError(e) );
  };

  const sendEmails = (emailList) => {
    if ( !window.confirm( "Biztosan küldhető a " + emailList.length + "db email?" ) ) {
      return;
    }
    setBackdrop( true );
    setProgressIdList( emailList.map( i => i.id ) );
    showProgressDialog( true );
    let ids = emailList.map( i => i.id );
    const request = {
      url: '/api/changemonemails/sendmulti/' + ids.join( ','),
      timeout: emailList.length * 30000,
      method: 'POST',
    };
    axios( request )
      .then( resp => {
        if ( resp && resp.data.failed )
        {
          enqueueSnackbar( "Email küldési hiba: " + resp.data.failed, { variant: 'error', autoHideDuration: LONG_SNACKBAR_MILLIS } );
          setBackdrop( false );
        }
        else if ( resp && resp.data )
        {
          const hasFailed = resp.data.emails.length !== emailList.length;
          enqueueSnackbar('Emailek küldése ' + (hasFailed ? 'részben ' : '') + 'sikeres: ' + resp.data.emails.length + '/' +  emailList.length + "db",
            { variant: hasFailed ? 'warning' : 'success', autoHideDuration: LONG_SNACKBAR_MILLIS } );
        }
        else
        {
          enqueueSnackbar('Az emailek küldése időtúllépés miatt nem megvárható!', { variant: 'error', autoHideDuration: LONG_SNACKBAR_MILLIS } );
        }
      })
      .catch( e => handleError(e) );

  };

  const sendSelectedEmails = () => {
    const mails = tableRowsSelected.map( i => emails[ i ] );
    sendEmails( mails );
  }

  const sendAllEmails = () => {
    const mails = emails.filter( element => element.state === 0 );
    sendEmails( mails );
  }

  const refreshAllEmailState = () => {
    const mails = emails.filter( element => element.state !== 0 );
    refreshEmailsState( mails );
  }

  const refreshSelectedEmailsState = () => {
    const mails = tableRowsSelected.map( i => emails[ i ] );
    refreshEmailsState( mails );
  }

  const handleError = (error) => {
    setBackdrop(false);
    enqueueSnackbar('Hiba történt: ' + error, {variant: "error"});
    console.log(error);
  }

  const documentColumns = [
    customRowIndexColumn(),
    {name: 'id', label: 'Előnézet', options: {sort: true, filter: false, searchable: false,
      customBodyRender: (value, tableMeta, updateValue) => {
            return ( <Button variant="contained" color="secondary" onClick={() => { setActEmail( emails.filter( email => email.id === tableMeta.rowData[ 1 ] )[ 0 ] ); showViewDialog(); }}>Előnézet</Button> );
      }
    }},
    {name: 'send', label: 'Küldés', options: {filter: false, searchable: false,
      customBodyRender: (value, tableMeta, updateValue) => {
            return ( <>
                      {
                        ( tableMeta.rowData[ 10 ] != null || tableMeta.rowData[ 11 ] !== 0 )
                          ? <span>-</span>
                          : <Button className={classes.redButton} variant="contained" color="secondary" onClick={() => { sendDialog( emails.filter( email => email.id === tableMeta.rowData[ 1 ] )[ 0 ] ); }}>Küldés</Button>
                      }
                </> );
      }
    }},
    {name: 'userId', label: 'Azonosító', options: {sort: true, filter: true}},
    {name: 'userFullname', label: 'Teljes név', options: {sort: true, filter: true, filterType: 'textField'}},
    {name: 'emailAddress', label: 'Email', options: {sort: true, filter: true, filterType: 'textField'}},
    {name: 'fromAddress', label: 'Feladó', options: {sort: true, filter: true, filterType: 'textField'}},
    {name: 'createDate', label: 'Létrehozás ideje', options: {sort: true, filter: true, searchable: false, filterType: 'textField'}},
    {name: 'subject', label: 'Tárgy', options: {sort: true, filter: true, searchable: false, filterType: 'textField'}},
    {name: 'sendDate', label: 'Kiküldés ideje', options: {sort: true, filter: true, searchable: false, filterType: 'textField'}},
    {name: 'mailerCampaign', label: 'Kampány', options: {sort: true, filter: false, searchable: false, filterType: 'textField'}},
    {name: 'mailerIdentifier', label: 'Levél Id', options: {sort: true, filter: true, searchable: false, filterType: 'textField',
      customBodyRender: (value, tableMeta, updateValue) => {
        return ( <p> {( value != null ) ? value : 'Még nincs' } </p> );
      }
    }},
    {name: 'state', label: 'Állapot', options: {sort: true, filter: true, searchable: false, filterType: 'textField',
      customBodyRender: (value, tableMeta, updateValue) => {
        let rowName = 'Ismeretlen';
        switch ( value ){
          case 0:
            rowName = 'Nincs elküldve';
            break;
          case 5:
            rowName = 'Küldés folyamatban';
            break;
          case 10:
            rowName = 'Mailjet-re küldve';
            break;
          case 20:
            rowName = 'Mailjet kézbesítette';
            break;
          case 30:
            rowName = 'Mailjet visszapattant';
            break;
          case 40:
            rowName = 'Elolvasva';
            break;
          case 50:
            rowName = 'Kattintva';
            break;
          default:
            rowName = 'Ismeretlen';
        }
        return ( <p>{rowName}</p>);
      }}},
    {name: 'stateDate', label: 'Állapot ideje', options: {sort: true, filter: true, searchable: false, filterType: 'textField'}}
  ];

  const options = {
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPageOptions: [10, 50, 100, 1000],
    rowsSelected: tableRowsSelected,
    customToolbarSelect: () => {
      return (
      <>
        <MultiSelectTableToolbar handleClick={sendSelectedEmails} iconName='restore' className='restore' tooltip='Kijelölt email-ek küldése'/>
        <MultiSelectTableToolbar handleClick={refreshSelectedEmailsState} iconName='refresh' className='refresh' tooltip='Kijelölt email-ek állapot-frissítése'/>
      </>
      );
    },
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setTableRowsSelected( rowsSelected );
    }
  }

  const loadFiles = async () => {
    const result = await axios("/api/changemonemails/" + props.id);
    const data = result.data.entries;
    setEmails( data );
  };

  useEffect( () => {
      loadFiles();
  }, [props, props.id] );

  return (
  <>
    <div>
      <Typography variant="h4">{props.id} változásfigyelés email-lista</Typography>

      {emails ? null : <Loader/>}

      <Paper elevation={3} className={classes.table}>
          <Typography className={classes.typography}>Az itt felsorolt email-ek lettek létrehozva a {props.id} dátumú változásfigyelési listához
          </Typography>
          <Grid container spacing={2} justify="center" alignItems="center">
              { ( emails && emails.find( element => element.state === 0 ) )
                ? <Grid item xl={5}>
                    <Button variant="contained" color="primary" className={classes.redButton} startIcon={<Icon>send</Icon>} onClick={sendAllEmails}>A még elküldetlen levelek küldése a listából</Button>
                  </Grid>
                : null
              }
              { ( emails && emails.find( element => element.state !== 0 ) )
                    ? <Grid item xl={5}>
                        <Button variant="contained" color="primary" startIcon={<Icon>refresh</Icon>} onClick={refreshAllEmailState}>Email-ek állapotának frissítése</Button>
                      </Grid>
                    : null
              }
          </Grid>
          <br/>
          <MUIDataTable
            data={emails || []}
            columns={documentColumns}
            options={options}
            />
      </Paper>

      <Dialog
          open={openViewDialog}
          onClose={closeViewDialog}
          fullWidth
          maxWidth="xl"
          scroll="paper"
          disableBackdropClick={true}
      >
          <EmailViewDialog
              email={actEmail}
              closeDialog={closeViewDialog}
          />
      </Dialog>

      <Dialog
          open={openProgressDialog}
          onClose={closeProgressDialog}
          fullWidth
          maxWidth="xl"
          scroll="paper"
          disableBackdropClick={true}
      >
        <EmailSendProgressDialog
            closeDialog={closeProgressDialog}
            idList={progressIdList}
        />
      </Dialog>

    </div>
    <Backdrop className={classes.backdrop} open={backdrop}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  </>
  );
};
