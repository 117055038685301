import React, {useEffect, useState} from "react";

import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";


import LaunchIcon from '@material-ui/icons/Launch';

import {makeStyles, createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";

import {useForm} from "react-hook-form";
import {useSnackbar} from "notistack";
import MUIDataTable from "mui-datatables";
import axios from "axios";

import Loader from "../../components/Loader";
import {customRowIndexColumn, muiTableLabels} from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    saveButton: {
        width: '100%',
        height: '100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const documentColumns = [
    customRowIndexColumn(),
    {name: 'docId', label: 'ID', options: {sort: true, filter: false}},
    {name: 'title1', label: 'FaCím1', options: {sort: true, filter: false}},
    {name: 'docType', label: 'Dok. típus', options: {sort: true, filter: true}},
    {name: 'year', label: 'Évszám', options: {sort: true, filter: true}},
    {name: 'editor', label: 'Szerkesztő', options: {sort: true, filter: true}},
    {name: 'createDate', label: 'Dátum', options: {sort: true, filter: false}},
    {
        name: 'comment',
        label: 'Megjegyzés',
        options: {
            sort: true,
            filter: false,
            customBodyRender: value => (
                <span>{value?.split('\n').map((line, index) => <React.Fragment
                    key={index}>{line}<br/></React.Fragment>)}</span>
            ),
        },
    },
];

const baseDocumentOptions = {
    pagination: true,
    rowsPerPage: 20,
    rowsPerPageOptions: [10, 20, 50, 100],
    selectableRows: 'none',
    print: false,
    downloadOptions: {filename: 'bad_bh_links.csv', separator: ','},
    viewColumns: false,
    textLabels: muiTableLabels,
};

const theme = () => createMuiTheme({
    overrides: {
        MUIDataTableHeadCell: {
            root: {
                borderBottom: '1px solid rgb(224, 224, 224)',
            }
        },
        MUIDataTableBodyCell: {
            root: {
                padding: '3px 5px',
                whiteSpace: 'nowrap',
                '&:nth-child(1)': {width: '40px !important'},
                '&:nth-child(2)': {width: '40px !important'},
                '&:nth-child(3)': {width: '150px !important'},
                '&:nth-child(4)': {width: '200px !important'},
                '&:nth-child(5)': {width: '60px !important'},
                '&:nth-child(6)': {width: '150px !important'},
                '&:nth-child(7)': {width: '150px !important'},
                '&:nth-child(8)': {width: '150px !important'},
                '&:nth-child(10)': {width: '40px !important'},
                '&:nth-child(12)': {width: '140px !important'},
                '&:nth-child(14)': {width: '130px !important'},
                '&:nth-child(16)': {whiteSpace: 'normal'},
            }
        }
    }
})

export default function BHLinkConflict() {
    const classes = useStyles();

    const {enqueueSnackbar} = useSnackbar();
    const [backdrop, setBackdrop] = React.useState(false);

    const {register, handleSubmit, getValues, setValue, errors} = useForm();

    const [list, setList] = useState([]);

    const onSubmit = data => {
        if (!data.docType) {
            enqueueSnackbar('A megadott BH nem létezik, ezért nem menthető', {variant: "error"});
            return;
        }

        setBackdrop(true);

        const request = {
            url: "/api/bh-bad-links/" + data.docId,
            method: 'POST',
            data: {
                comment: data.comment
            }
        };
        axios(request)
            .then(resp => {
                setList(resp.data);
                setBackdrop(false);
                enqueueSnackbar('A megjegyzés elmentve a ' + data.docId + ' ID-hoz', {variant: "success"});
            });
    };

    const loadDocument = () => {
        const docId = getValues().docId;
        if (!(docId && /^[358]\d{5}$/.test(docId))) {
            setValue('title1', 'Nem BH ID');
            setValue('comment', '');
            setValue('docType', '');
            return;
        }

        axios("/api/bh-bad-links/" + docId)
            .then(resp => {
                const item = resp.data;
                setValue('title1', item.title1 || 'ID nem található');
                setValue('comment', item.comment);
                setValue('docType', item.docType);
            });
    };

    const onRowClick = (data, rowMeta) => {
        const item = list[rowMeta.dataIndex];

        setValue('docId', item.docId);
        setValue('title1', item.title1);
        setValue('docType', item.docType);
        setValue('comment', item.comment);
    };

    useEffect(() => {
        setBackdrop(true);
        axios("/api/bh-bad-links")
            .then(resp => {
                setList(resp.data);
                setBackdrop(false);
                enqueueSnackbar('A lista sikeresen betöltődött', {variant: "success"});
            });
    }, [enqueueSnackbar]);

    const documentOptions = {
        ...baseDocumentOptions,
        onRowClick
    };

    return (
        <div className={classes.root}>
            <Typography variant="h5" gutterBottom className={classes.title}>
                Hibás BH hivatkozások
            </Typography>

            {list
                ? <>
                    <Paper elevation={3} className={classes.paper}>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={3}>
                                <Grid item xs={1}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        startIcon={<Icon>save</Icon>}
                                        className={classes.saveButton}
                                    >
                                        Mentés
                                    </Button>
                                </Grid>
                                <Grid item xs={1}>
                                    <input name="docType" type="hidden" ref={register()}/>
                                    <TextField
                                        name="docId"
                                        helperText="Dok. ID"
                                        required
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        inputRef={register({validate: true})}
                                        error={!!errors.docId}
                                        onChange={loadDocument}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        name="title1"
                                        helperText="BH Szám"
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                        inputRef={register()}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        name="comment"
                                        helperText="Megjegyzés"
                                        required
                                        variant="outlined"
                                        autoFocus
                                        multiline
                                        fullWidth
                                        inputRef={register({validate: true})}
                                        error={!!errors.comment}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </>
                : <Loader/>}

            <p className="body2">
                <Link href="http://badbh.orac.lan:8080/" target="_blank">
                    <LaunchIcon fontSize="small"/> Hibás BH hivatkozások tartalomjegyzék
                </Link>
                &nbsp;
                (csak az ORAC belső hálózatából érhető el)
            </p>

            <Paper elevation={3} className={classes.table}>
                <MuiThemeProvider theme={theme()}>
                    <MUIDataTable
                        title={"BH-k bevezetője és szövege között található hivatkozás konfliktusok"}
                        data={list}
                        columns={documentColumns}
                        options={documentOptions}
                        class
                    />
                </MuiThemeProvider>
            </Paper>
            <Backdrop className={classes.backdrop} open={backdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );

}