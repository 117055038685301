import React, {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import axios from "axios";
import {v1 as uuidv1} from 'uuid';
import IconButton from "@material-ui/core/IconButton";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles( ( theme ) => ({
  root: {
    width: 400
  }
}) );

const SExpansionPanel = withStyles( {
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
} )( ExpansionPanel );

const SExpansionPanelSummary = withStyles( {
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
} )( ExpansionPanelSummary );

const SExpansionPanelDetails = withStyles( ( theme ) => ({
  root: {
    padding: theme.spacing( 2 ),
  },
}) )( ExpansionPanelDetails );


export default function PrecList() {
  const classes = useStyles();
  const [files, setFiles] = useState( [] );
  const [expanded, setExpanded] = React.useState();

  const handleChange = ( panel ) => ( event, newExpanded ) => {
    setExpanded( newExpanded ? panel : false );
  };

  const download = ( file ) => {
    const link = document.createElement( 'a' );
    link.setAttribute( 'href', "/api/preclist/download/" + file.filename );
    link.setAttribute( "download", file.filename );
    link.click();
  };

  useEffect( () => {
        const loadFiles = async () => {
          const result = await axios( "/api/preclist" );
          const data = result.data;
          let last = { date: "" };
          const groups = [];
          data.forEach( file => {
            const date = file.year + "." + file.month;
            if( date !== last.date ) {
              last = { date, files: [] };
              groups.push( last );
            }
            last.files.push( file );
            file.id = uuidv1()
          } );
          if( groups.length ) {
            setExpanded( groups[ 0 ].date )
          }
          setFiles( groups );
        };
        loadFiles();
      }
      , [] );

  if( !files ) {
    return <Loader/>
  }

  return (
      <div className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Precedens képes határozatok
        </Typography>
        {files.map( group => (
            <SExpansionPanel key={group.date} expanded={expanded === group.date} onChange={handleChange( group.date )}>
              <SExpansionPanelSummary>{group.date}</SExpansionPanelSummary>
              <SExpansionPanelDetails>
                <Table size="small">
                  <TableBody>
                    {group.files.map( file => (
                        <TableRow key={file.id}>
                          <TableCell>
                            <IconButton aria-label="download" size="small" onClick={() => download( file )} title="Letöltés">
                              <GetAppOutlinedIcon/>
                            </IconButton>
                          </TableCell>
                          <TableCell>{file.filename}</TableCell>
                        </TableRow>
                    ) )}
                  </TableBody>
                </Table>
              </SExpansionPanelDetails>
            </SExpansionPanel>
        ) )}

      </div>
  );
}
