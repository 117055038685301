import React, {useState} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import {
	Backdrop,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {useForm} from "react-hook-form";
import {useSnackbar} from 'notistack';
import {preparedComments} from "../../utils";

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	dialogGrid: {
		flexGrow: 1,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

// TODO:
// - az elso dok-listaban csak az aktualis birosaghoz tartozo doksikat listazza
// - megerosites ablak es mukodesenek implementalasa
// - piros gombok a modosito muveleteknel
// - teszteles
export default function InvalidRuleToBlacklistRuleDialog({rule, courts, closeDialog, closeRefreshDialog, saveUrl, ruleRelatedDocs}) {
	const classes = useStyles();

	// a birosag Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott birosag id-jet (a megadott rule.courtId-rol indul)
	const [courtId, setCourtId] = useState( rule.courtId );
	// a megjegyzes Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott/megadott szoveget
	const [newComment, setNewComment] = useState();
	// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// a szerver-muveletek idejere a feluletet inaktivra allitjuk
	const [backdrop, setBackdrop] = React.useState(false);
	// a beallitott ertekek kozul a szovegeseket ezzel a modszerrel olvassuk ki (kulon inputRef-ek helyett)
	const {register, getValues} = useForm();
	// a mentes soran utkozo szabalyokat megjelenito komponens megjelenitesere hasznalt allapotok
	const [openSaveFailedDialog, setOpenSaveFailedDialog] = useState(false);
	// a mentes soran utkozo szabalyokat megjelenito komponens tartalma
	const [blacklistRuleRelatedRules, setBlacklistRuleRelatedRules] = useState();
	// a mentes soran megerositheto szabalyokat es dokumentumokat megjelenito komponenst megjelenitesere hasznalt allapotok
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	// a mentes soran megerositheto szabalyokat megjelenito komponens tartalma
	const [blacklistRuleConfirmRules, setBlacklistRuleConfirmRules] = useState();
	// a mentes soran megerositheto szabalyok dokumentumait megjelenito komponens tartalma
	const [blacklistRuleConfirmDocs, setBlacklistRuleConfirmDocs] = useState();
	
	const submit = () => {
		rule={ courtId: courtId, id:rule.id, comment: newComment, ...getValues() };
		setBackdrop(true);
		const request = {
			url: saveUrl.replace( '%id', rule.id ),
			method: 'POST',
			data: rule
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				if ( resp.data.relatedRules && resp.data.relatedRules.length ) {
					// ha van relateddocs, akkor megerosites szukseges
					if ( resp.data.relatedDocs ) {
						setBlacklistRuleConfirmRules( resp.data.relatedRules );
						setBlacklistRuleConfirmDocs( resp.data.relatedDocs );
						setOpenConfirmDialog( true );
					} else {
						setBlacklistRuleRelatedRules( resp.data.relatedRules );
						setOpenSaveFailedDialog( true );
					}
				} else {
					enqueueSnackbar( 'Új bírónév "' + rule.synonym + '" felvétele feketelistára sikeres', {variant: "success"} );
					setBackdrop( false );
					closeRefreshDialog();
				}
			})
			.catch(handleError);
	};

	const confirmBlacklist = () => {
		rule={ id:rule.id, comment:newComment, ...getValues(), forced: true };
		setBackdrop(true);
		const request = {
			url: saveUrl.replace( '%id', rule.id ),
			method: 'POST',
			data: rule,
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				enqueueSnackbar( 'Új bírónév: "' + rule.synonym + '" felvétele feketelistára sikeres', {variant: "success"} );
				closeRefreshDialog();
				}
			)
			.catch(handleError);
	};

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const handleSaveFailedDialogClose = () => {
		setOpenSaveFailedDialog(false);
		enqueueSnackbar('Hiba: bírónév feketelistára felvétele sikertelen', {variant: "error"});
	}

	const closeConfirmDialog = () => {
		setOpenConfirmDialog(false);
	}

	const editDoc = (docId) => {
		window.open( '/bh/editor/' + docId , '_blank' );
	}

	return (
	<div>
		<form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
			<DialogTitle>
				Bírónév felvétele feketelistára
			</DialogTitle>

			<DialogContent className={classes.dialogGrid}>
				<Grid container spacing={3} justify="center" alignItems="center">
					<Grid item xs={6}>
						<TextField
							name="synonym"
							variant="outlined"
							size="small"
							label="Bírónév"
							fullWidth={true}
							disabled={true}
							value={rule.synonym}
							inputRef={register}
							InputProps={{
								className: classes.input
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							id="combo-box-demo"
							size="small"
							options={ courts }
							getOptionLabel={(option) => option.value}
							defaultValue={ courts.find(element => element.key === rule.courtId) }
							onChange={(event, value) => setCourtId(value?value.key:null)}
							renderInput={(params) => <TextField {...params} label="Bíróság" variant="outlined"/> }
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							id="combo-box-demo"
							options={preparedComments}
							size="small"
							freeSolo={true}
							autoSelect={true}
							onChange={(event, value) => setNewComment(value)}
							renderInput={(params) => <TextField {...params} label="Megjegyzés" variant="outlined"/> }
							/>
					</Grid>
				</Grid>
				<br/>
				<Typography variant="body1">A(z) '{rule.synonym}' bírónév az alábbi dokumentum(ok)hoz kapcsolódik:</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell>Szerkesztés</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>FaCím1</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ruleRelatedDocs?.map((doc, index) => (
							<TableRow key={doc.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell>
									<IconButton aria-label="create" title="Szerkesztés" onClick={() => editDoc(doc.id)} >
										<Icon fontSize="small">create</Icon>
									</IconButton>
								</TableCell>
								<TableCell align="right">{doc.id}</TableCell>
								<TableCell>{doc.title1}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog} color="primary" autoFocus={true}>
					Mégsem
				</Button>
				<Button
					variant="contained"
					color="primary"
					type="button"
					className={classes.redButton}
					startIcon={<Icon>saveIcon</Icon>}
					onClick={submit}
				>
					Bírónév felvétele feketelistára
				</Button>
			</DialogActions>
		</form>
		
		<Dialog open={openSaveFailedDialog} onClose={() => setOpenSaveFailedDialog(false)} fullWidth maxWidth="lg">
			<DialogTitle id="customized-dialog-title" onClose={() => setOpenSaveFailedDialog(false)}>
				Szabály ütközés
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">A bírónév: '{rule.synonym}' az alábbi szabályokkal ütközik, 
					ezért nem vehető fel feketelistára!</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>Bíróság neve</TableCell>
							<TableCell>Szabály</TableCell>
							<TableCell>Létrehozás</TableCell>
							<TableCell>Módosítás</TableCell>
							<TableCell>Utolsó módosító</TableCell>
							<TableCell>Megjegyzés</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{blacklistRuleRelatedRules?.map((rule, index) => (
							<TableRow key={rule.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{rule.id}</TableCell>
								<TableCell>{rule.courtName}</TableCell>
								<TableCell>{rule.ruleText}</TableCell>
								<TableCell>{rule.createDate}</TableCell>
								<TableCell>{rule.modifiedDate}</TableCell>
								<TableCell>{rule.username}</TableCell>
								<TableCell>{rule.comment}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus className={classes.button} variant="contained">
					OK
				</Button>
			</DialogActions>
		</Dialog>

		<Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)} fullWidth maxWidth="lg">
			<DialogTitle id="customized-dialog-title" onClose={() => setOpenConfirmDialog(false)}>
				Megerősítés
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">Mindegyik lenti dokumentumra vonatkozóan megtörténjen a módosítás?</Typography>
				<br/><br/>
				<Typography variant="body1">A feketelistára felvételre kerülő '{rule.synonym}' név az alábbi Jóváhagyásra váró nevekre is kihat, 
				amivel a bírónév a lenti dokumentumokból is kihagyásra kerül:</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>Bíróság neve</TableCell>
							<TableCell>Szabály</TableCell>
							<TableCell>Létrehozás</TableCell>
							<TableCell>Módosítás</TableCell>
							<TableCell>Utolsó módosító</TableCell>
							<TableCell>Megjegyzés</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{blacklistRuleConfirmRules?.map((rule, index) => (
							<TableRow key={rule.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{rule.id}</TableCell>
								<TableCell>{rule.courtName}</TableCell>
								<TableCell>{rule.ruleText}</TableCell>
								<TableCell>{rule.createDate}</TableCell>
								<TableCell>{rule.modifiedDate}</TableCell>
								<TableCell>{rule.username}</TableCell>
								<TableCell>{rule.comment}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<br/>
				<Typography variant="body1">A fenti szabályok az alábbi dokumentumokhoz kapcsolódóan módosítják a bírónevet:</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>FaCím1</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{blacklistRuleConfirmDocs?.map((doc, index) => (
							<TableRow key={doc.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{doc.id}</TableCell>
								<TableCell>{doc.title1}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeConfirmDialog} color="primary" autoFocus={true}>
					Mégsem
				</Button>
				<Button onClick={confirmBlacklist} color="primary" className={classes.redButton} variant="contained">
					Név feketelistára rakásának megerősítése
				</Button>
			</DialogActions>
		</Dialog>

		<Backdrop className={classes.backdrop} open={backdrop}>
			<CircularProgress color="inherit"/>
		</Backdrop>
	</div>
	);
};