import React, {createRef, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from "@material-ui/core/styles";
import { Backdrop,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import {useSnackbar} from 'notistack';
import Loader from "../../components/Loader";
import axios from "axios";
import {customRowIndexColumn, preparedComments} from "../../utils";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	toolbar: {
		padding: "1em",
		margin: "1em 0"
	},
	panel: {
		marginBottom: '1em'
	},
	table: {
		"& td": {
		padding: "4px"
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}) );

// a feluletnek az megjelenesi allapotai lehetsegesek:
// - kiindulo allapot: a feketelista szabalyok megjelenitese tablazatban
// - szabaly torles megerosites felulet (torles gomb): a kapott szabaly, torolheto birosaggal, valamint a hozza kapcsolodo dokumentumok listajanak megjelenitese tablazatban
// - szabaly torles sikertelen: a torlendo szabaly torlese masik szaballyal utkozik (pl. ugyanaz a szoveg, eltero birosag)
// - szabaly letrehozas sikertelen: a letrehozando szabaly masik szaballyal utkozik (pl. van csereszabaly ugyanazzal a szoveggel)
export default function JudgeBlacklist() {
	const classes = useStyles();
	const newName = createRef();
	const {enqueueSnackbar} = useSnackbar();
	const [documents, setDocuments] = useState();
	const [courts, setCourts] = useState();
	const [newCourtId, setNewCourtId] = useState();
	// a megjegyzes Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott szoveget
	const [newComment, setNewComment] = useState();
	const [backdrop, setBackdrop] = React.useState(false);
	const [openDeleteRelatedDialog, setOpenDeleteRelatedDialog] = useState(false);
	const [openInsertFailedDialog, setOpenInsertFailedDialog] = useState(false);
	const [deleteRelatedDocuments, setDeleteRelatedDocuments] = useState();
	const [deleteRelatedDialogText, setDeleteRelatedDialogText] = useState("");
	const [insertRelatedDocuments, setInsertRelatedDocuments] = useState();
	const [chosenRuleId, setChosenRuleId] = useState();
	const [openDeleteFailedDialog, setOpenDeleteFailedDialog] = useState(false);
	const [deleteConflictRules, setDeleteConflictRules] = useState([]);

	const saveNewBlacklisted = () => {
		const text = newName.current.value;
		const courtId = newCourtId ? newCourtId.key : undefined;
		if (!text || !text.trim().length) {
			enqueueSnackbar('Nem adtál meg szöveget', {variant: "warning"});
			return;
		}
		setBackdrop(true);

		const request = {
			url: '/api/judgeblacklist',
			method: 'POST',
			data: {
				name: text,
				comment: newComment,
				courtid: courtId
			}
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				if ( resp.data.entries && resp.data.entries.length ) {
					enqueueSnackbar( 'Új feketelista elem: "' + text + '" sikeresen mentve', {variant: "success"} );
					setDocuments( resp.data.entries );
				} else {
					setInsertRelatedDocuments( resp.data.related );
					setOpenInsertFailedDialog(true);
				}
			})
			.catch(handleError);
	};

	const enter = e => {
		if ( e.keyCode === 13 ) {
			saveNewBlacklisted();
		}
	};

	useEffect( () => {
		axios( "/api/judgeblacklist" ).then( resp => {
			setDocuments( resp.data.list );
			setCourts( resp.data.courts );
		} );

	}, [ ] );

	const fixDocumentColumns = [
			customRowIndexColumn(),
			{name: 'id', label: 'ID', options: {sort: true, filter: false}},
			{name: 'name', label: 'Feketelistás szöveg', options: {sort: true, filter: false}},
			{name: 'courtName', label: 'Bíróság neve', options: {sort: true}},
			{name: 'comment', label: 'Megjegyzés', options: {sort: true}},
			{name: 'createDate', label: 'Létrehozás dátuma', options: {sort: true}},
			{name: 'modifyDate', label: 'Módosítás dátuma', options: {sort: true}},
			{name: 'username', label: 'Utolsó módosító', options: {sort: true}},
	];

	const documentColumns = [
		{
			name: "id",
			label: "Törlés",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<IconButton aria-label="delete" title="Törlés" onClick={() => getRelated(value)} >
							<Icon fontSize="small">delete</Icon>
						</IconButton>
					);
				}
			}
		},
		...fixDocumentColumns
	];

	const getRelated = (id) => {
		setBackdrop(true);
		setChosenRuleId( id );
		const request = {
			url: '/api/judgeblacklist/' + id,
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				// ha a szerver valaszban van a feketelista-szabaly torleshez kapcsolodo dokumentum, akkor megjelenitjuk a lista-ablakot (csak forced=false esetben erkezhet)
				if ( !resp.data.related || resp.data.related.length === 0 )
				{
					setDeleteRelatedDialogText( "A feketelistás név dokumentum meta-adatként nem került mentésre, így ennek"
								+ " törlése dokumentum-metaadatban változást nem okoz."
								+ " A szabály törlésével csak később migrált dokumentumokban válik láthatóvá a név." );
					setDeleteRelatedDocuments( null );
				}
				else
				{
					setDeleteRelatedDialogText( "A törlendő feketelistás bírónév az alábbi dokumentumokban helyes névként fog megjelenni:" );
					setDeleteRelatedDocuments( resp.data.related );
				}
				setOpenDeleteRelatedDialog(true);
			})
			.catch(handleError);
	};

	const deleteName = (id, isForced) => {
		setBackdrop(true);
		setChosenRuleId( id );
		const request = {
			url: '/api/judgeblacklist/' + id,
			method: 'DELETE',
			data: { forced: isForced }
		};
		axios(request)
			.then(resp => {
				if ( resp.data.conflict ){
					// ha a szerver valaszban van a utkozo feketelista-szabaly, akkor hibauzenetben megjelenitjuk
					setDeleteConflictRules( resp.data.conflict );
					setOpenDeleteFailedDialog(true);
				}
				else if ( typeof( resp.data.related ) != "undefined" ){
					// ha a szerver valaszban van a feketelista-szabaly torleshez kapcsolodo dokumentum, akkor megjelenitjuk a lista-ablakot (csak forced=false esetben erkezhet)
					setDeleteRelatedDocuments( resp.data.related );
					setOpenDeleteRelatedDialog(true);
				}
				else {
					// ha a szerver valaszban nincs a feketelista-szabaly torleshez kapcsolodo dokumentum
					handleDeleteRelatedDialogClose();
					setDocuments( resp.data.entries );
					enqueueSnackbar('Feketelistás név sikeresen törölve: ' + id, {variant: "success"});
				}
			})
			.catch(handleError);
	};

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const handleDeleteRelatedDialogClose = () => {
		setOpenDeleteRelatedDialog(false);
		setBackdrop(false);
	}

	const handleDeleteFailedDialogClose = () => {
		setOpenDeleteFailedDialog(false);
		setBackdrop(false);
	}

	const handleInsertFailedDialogClose = () => {
		setOpenInsertFailedDialog(false);
		enqueueSnackbar('Hiba: mentés sikertelen', {variant: "error"});
	}

	// lehet a tablazatban szurni, viszont nincs sor-kijeloles
	const tableOptions = {
		filter: true,
		selectableRows: 'none',
		filterType: 'dropdown',
		rowsPerPage: 10,
        rowsPerPageOptions: [10, 50, 100, 1000],
	};

	return (
		<div>
			<Typography variant="h4">Bírónév feketelista</Typography>
			<Paper elevation={3} className={classes.toolbar}>
				<Grid container spacing={5} justify="center" alignItems="center">
					<Grid item xs={8}>
						<TextField
							variant="outlined"
							size="small"
							fullWidth={true}
							label="Feketelistába mentendő szöveg/bírónév..."
							inputRef={newName}
							onKeyUp={enter}
							InputProps={{
								className: classes.input
							}}
						/>
					</Grid>
					<Grid item xs={2}>
							{courts ? <Autocomplete
								id="combo-box-demo"
								options={courts}
								size="small"
								getOptionLabel={(option) => option.value}
								onChange={(event, value) => setNewCourtId(value)}
								renderInput={(params) => <TextField {...params} label="Bíróság" variant="outlined"/> }
								/>
							: null }
					</Grid>
					<Grid item xs={2}>
						<Autocomplete
							id="combo-box-demo"
							options={preparedComments}
							size="small"
							freeSolo={true}
							autoSelect={true}
							onChange={(event, value) => setNewComment(value)}
							renderInput={(params) => <TextField {...params} label="Megjegyzés" variant="outlined"/> }
							/>
					</Grid>
				</Grid>
				<Grid container spacing={5}>
					<Grid item xs={5}>
						<Button
							variant="contained"
							color="primary"
							className={classes.redButton}
							startIcon={<Icon>send</Icon>}
							onClick={saveNewBlacklisted}
						>
							Mentés
						</Button>
					</Grid>
				</Grid>
			</Paper>

			{documents ? null : <Loader/>}

			<Dialog open={openDeleteRelatedDialog} onClose={handleDeleteRelatedDialogClose} fullWidth maxWidth="lg">
				<DialogTitle id="customized-dialog-title" onClose={handleDeleteRelatedDialogClose}>
					Dokumentum metaadatokat érintő törléshez szükséges megerősítés
				</DialogTitle>
				<DialogContent>
					<Typography variant="body1">{deleteRelatedDialogText}</Typography>
					{deleteRelatedDocuments?
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell align="right" className={classes.indexCol}>#</TableCell>
									<TableCell align="right">ID</TableCell>
									<TableCell>FaCím1</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{deleteRelatedDocuments.map((doc, index) => (
									<TableRow key={'id' + doc.id}>
										<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
										<TableCell align="right">{doc.id}</TableCell>
										<TableCell>{doc.title1}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					:""}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteRelatedDialogClose} color="primary" autoFocus={true}>
						Mégsem
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.redButton}
						startIcon={<Icon>delete</Icon>}
						onClick={() => deleteName(chosenRuleId, true)}
					>
						Feketelistás név törlése
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={openInsertFailedDialog} onClose={() => setOpenInsertFailedDialog(false)} fullWidth maxWidth="lg">
				<DialogTitle id="customized-dialog-title" onClose={() => setOpenInsertFailedDialog(false)}>
					Szabály ütközés
				</DialogTitle>
				<DialogContent>
					<Typography variant="body1">Az új feketelistás bírónév az alábbi szabályokkal ütközik:</Typography>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell align="right" className={classes.indexCol}>#</TableCell>
								<TableCell align="right">ID</TableCell>
								<TableCell>Bíróság neve</TableCell>
								<TableCell>Szabály</TableCell>
								<TableCell>Létrehozás</TableCell>
								<TableCell>Módosítás</TableCell>
								<TableCell>Megjegyzés</TableCell>
								<TableCell>Utolsó módosító</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{insertRelatedDocuments?.map((rule, index) => (
								<TableRow key={'id' + rule.id}>
									<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
									<TableCell align="right">{rule.id}</TableCell>
									<TableCell>{rule.courtName}</TableCell>
									<TableCell>{rule.ruleText}</TableCell>
									<TableCell>{rule.createDate}</TableCell>
									<TableCell>{rule.modifiedDate}</TableCell>
									<TableCell>{rule.comment}</TableCell>
									<TableCell>{rule.username}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleInsertFailedDialogClose} color="primary" autoFocus className={classes.button} variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={openDeleteFailedDialog} onClose={() => setOpenDeleteFailedDialog(false)} fullWidth maxWidth="lg">
				<DialogTitle id="customized-dialog-title" onClose={() => setOpenDeleteFailedDialog(false)}>
					Szabály ütközés
				</DialogTitle>
				<DialogContent>
					<Typography variant="body1">A törlendő feketelistás bírónév az alábbi szabályok miatt a dokumentumban továbbra is rejtett maradna, ezek a szabályok csak együtt törölhetőek (jelen felületen egyelőre nem):</Typography>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell align="right" className={classes.indexCol}>#</TableCell>
								<TableCell align="right">ID</TableCell>
								<TableCell>Bíróság neve</TableCell>
								<TableCell>Szabály</TableCell>
								<TableCell>Létrehozás</TableCell>
								<TableCell>Módosítás</TableCell>
								<TableCell>Utolsó módosító</TableCell>
								<TableCell>Megjegyzés</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{deleteConflictRules?.map((rule, index) => (
								<TableRow key={'id' + rule.id}>
									<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
									<TableCell align="right">{rule.id}</TableCell>
									<TableCell>{rule.courtName}</TableCell>
									<TableCell>{rule.ruleText}</TableCell>
									<TableCell>{rule.createDate}</TableCell>
									<TableCell>{rule.modifiedDate}</TableCell>
									<TableCell>{rule.username}</TableCell>
									<TableCell>{rule.comment}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteFailedDialogClose} color="primary" autoFocus className={classes.button} variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<Paper elevation={3} className={classes.table}>
						<MUIDataTable
							data={documents}
							columns={documentColumns}
							options={tableOptions}
							/>
			</Paper>

			<Backdrop className={classes.backdrop} open={backdrop}>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</div>
	);
};
