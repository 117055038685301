import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "@reach/router";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";


const mainMenu = [
  {
    title: "Általános",
    key: "general",
    items: [
      { name: "Főoldal", key: "home", path: "/" }
    ]
  },
  {
    title: "BH",
    key: "bh",
    items: [
      { name: "BH Editor", key: "bh-editor", path: "/bh/editor" },
      { name: "Prec. képes határozatok", key: "bh-prec", path: "/bh/prec" },
      { name: "Hibás BH hivatkozások", key: "bh-bad-links", path: "/bh/bh-bad-links" },
    ]
  },
  {
    title: "BHGY",
    key: "bhgy",
    items: [
      { name: "Bírónév feketelista", key: "bhgy-judgeblacklist", path: "/bhgy/judgeblacklist" },
      { name: "Valid bírónevek", key: "bhgy-validjudges", path: "/bhgy/validjudges" },
      { name: "Jóváhagyásra váró bírónevek", key: "bhgy-invalidjudges", path: "/bhgy/invalidjudges" },
      { name: "Bírónév szinonimák", key: "bhgy-judgesynms", path: "/bhgy/judgesynms" },
      { name: "Bírónév-hiányos dokumentumok", key: "bhgy-notenoughjudges", path: "/bhgy/notenoughjudges" },
      { name: "Bírónév nélküli dokumentumok", key: "bhgy-nonejudges", path: "/bhgy/nonejudges" },
    ]
  },
  {
    title: "Metaadatok",
    key: "meta",
    items: [
      { name: "Dokumentum típus", key: "meta-doctypes", path: "/meta/doctypes" },
      { name: "Kibocsátók", key: "meta-issuers", path: "/meta/issuers" },
      { name: "Covid 19 dokumentumok", key: "meta-covid19", path: "/meta/covid19" },
    ]
  },
  {
    title: "Egyéb",
    key: "other",
    items: [
      { name: "Kampányok", key: "other-campaign", path: "/other/campaign" },
      { name: "Változásfigyelés", key: "changemonitoring", path: "/other/changemonitoring" },
    ]
  }
];

const drawerWidth = 240;

const useStyles = makeStyles( ( theme ) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create( ['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    } ),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create( ['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    } ),
  },
  menuButton: {
    marginRight: theme.spacing( 2 ),
  },
  hide: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing( 0, 1 ),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing( 3 ),
    transition: theme.transitions.create( 'margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    } ),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create( 'margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    } ),
    marginLeft: 0,
  },
  menu: {
    width: "100%"
  },
  divider: {
    height: "2px"
  }
}) );

const Sidebar = ( { open, setOpen } ) => {
  const classes = useStyles();

  return (
      <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          onClose={() => setOpen( false )}

          classes={{ paper: classes.drawerPaper }}>

        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpen( false )}>
            <ChevronLeftIcon/>
          </IconButton>

        </div>

        <Divider className={classes.divider}/>

        {mainMenu.map( menu => (
            <List
                key={menu.key}
                className={classes.menu}
                subheader={
                  <ListSubheader component="div">
                    {menu.title}
                  </ListSubheader>
                }>
              {menu.items.map( item => (
                  <ListItem
                      key={item.key}
                      component={Link}
                      to={item.path} button>
                    <ListItemText primary={item.name}/>
                  </ListItem>
              ) )}
              <Divider/>
            </List>
        ) )}

      </Drawer>

  );
};

Sidebar.drawerWidth = drawerWidth;

export default Sidebar;