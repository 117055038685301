import React, {createContext, useState} from "react";
import {useLocalStorage} from "./hooks";

export const userContext = createContext( null );

export const UserProvider = ( { children } ) => {
  const [isAuthenticated, setAuthenticated] = useState( false );
  const [profileStorage, setProfileStorage] = useLocalStorage( "profile" );
  const [profile, setProfile] = useState( null );
  const [isLoading, setLoading] = useState( true );

  const createSession = data => {
    setProfile( data );
    setProfileStorage( data );
    setAuthenticated( true );
    setLoading( false );
  };

  const removeSession = () => {
    setProfile( null );
    setProfileStorage( null );
    setAuthenticated( false );
    console.log( "Remove session" );
  };

  const fetchUser = () => {
    if( !isAuthenticated && profileStorage && profileStorage.username && profileStorage.fullname ) {
      setProfile( profileStorage );
      setAuthenticated( true );
    }
    setLoading( false );
  }

  return (
      <userContext.Provider
          value={{
            profile,
            isAuthenticated,
            isLoading,
            createSession,
            removeSession,
            fetchUser
          }}
      >
        {children}
      </userContext.Provider>
  );
};
