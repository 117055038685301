import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import RestoreIcon from '@material-ui/icons/Restore';
import axios from "axios";
import { useSnackbar } from 'notistack';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  redButton: {
    color: '#ffffff',
    backgroundColor: '#ff0000',
    '&:hover': {
      backgroundColor: '#aa0000'
    }
  },
  dialogGrid: {
    flexGrow: 1,
    paddingTop: '0 !important',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  docTitleCol: {
      width: '75%',
  },
  changeMonitoring: {
      backgroundColor: '#fff3cd',
  }
}));

const ViewedButton = withStyles((theme) => ({
    root: {
        color: red[500],
        '&:hover': {
            backgroundColor: red[50],
            color: red[700],
        },
    },
}))(IconButton);

export default function DateEditDialog({ dir, docs, closeDialog, onDocChange }) {
  const classes = useStyles();

  // a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
  const { enqueueSnackbar } = useSnackbar();
  // a szerver-muveletek idejere a feluletet inaktivra allitjuk
  const [backdrop, setBackdrop] = React.useState(false);
  // html feltoltesenel a feltoltendo dokumentum azonositojat tartalmazza
  const [docId, setDocId] = React.useState(null);
  // html feltoltesehez a fajlfeltolto input elem
  const fileUploader = React.useRef(null);

  const handleError = (error) => {
    setBackdrop(false);
    enqueueSnackbar('Hiba történt...', { variant: "error" });
    console.log(error);
  }

  const activate = (id, activate) => {
    setBackdrop(true);
    axios.post('/api/changemon/activate/' + dir.diffDir + '/' + id + '?activate=' + activate)
      .then(() => {
        setBackdrop(false);
        enqueueSnackbar("Sikeresen " + (activate ? "aktiválva" : "inaktiválva"), { variant: 'success' });
      })
      .catch(handleError);
  };

  const downloadHtml = (doc) => {
    axios({
      url: '/api/changemon/html/' + dir.diffDir + '/' + doc.id,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', dir.diffDir + '-[' + doc.id + ']-' + doc.title1 + '.html');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  const uploadHtml = (id) => {
    setDocId(id);
    fileUploader.current.click();
  }

  const onFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (!file) {
      enqueueSnackbar("Válassz ki egy fájlt", { variant: 'error' });
      return;
    }

    let data = new FormData();
    data.append('file', file);
    data.append('name', file.name);

    setBackdrop(true);
    axios({
      url: '/api/changemon/html/' + dir.diffDir + '/' + docId,
      method: 'POST',
      data: data,
    })
      .then(() => {
        setBackdrop(false);
        enqueueSnackbar("Sikeres feltöltés", { variant: 'success' });
      })
      .catch(handleError);
  }

  const restoreHtml = (id) => {
    setBackdrop(true);
    axios({
      url: '/api/changemon/html/restore/' + dir.diffDir + '/' + id,
      method: 'POST',
    })
      .then(() => {
        setBackdrop(false);
        enqueueSnackbar("Sikeres visszaállítás", { variant: 'success' });
      })
      .catch(handleError);
  }

  return (
    <>
      <DialogTitle id="date-edit-dialog-title">
        {dir.diffDir}
      </DialogTitle>
      <DialogContent className={classes.dialogGrid}>
        <Table id="date-edit-dialog-content" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="right">#</TableCell>
              <TableCell>Átnézve</TableCell>
              <TableCell>Engedélyezve</TableCell>
              <TableCell>Dok. ID</TableCell>
              <TableCell>Dokumentum cím</TableCell>
              <TableCell>Közlönyök</TableCell>
              <TableCell>Megnyitás</TableCell>
              <TableCell>Dok. összes változása</TableCell>
              <TableCell>Html letöltése</TableCell>
              <TableCell>Html feltöltése</TableCell>
              <TableCell>Html visszaállítása</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((doc, index) => (
              <TableRow key={doc.id} className={doc.changeMonitoring ? classes.changeMonitoring : null}>
                <TableCell align="right">
                  {index + 1}.
                </TableCell>
                <TableCell align="center">
                  { doc.viewed
                      ? <IconButton title="Átnézve" variant="contained" onClick={() => onDocChange(doc)}>
                          <CheckIcon fontSize="small" />
                        </IconButton>
                      : <ViewedButton title="Átnézve" variant="outlined" onClick={() => onDocChange(doc)}>
                          <CheckBoxOutlineBlankIcon fontSize="small" />
                        </ViewedButton>
                  }
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    defaultChecked={doc.activated}
                    disabled={dir.approvedDate != null}
                    onChange={e => activate(doc.id, e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
                <TableCell>
                  {doc.id}
                </TableCell>
                <TableCell className={classes.docTitleCol}>
                  {doc.typicalAbbrevation &&
                    doc.typicalAbbrevation + " - "
                  }
                  {doc.title1} {doc.title2}
                </TableCell>
                <TableCell>
                  {doc.gazettes.map((gazette) => (
                    <div key={gazette}>{gazette}</div>
                  ))}
                </TableCell>
                <TableCell align="center">
                  <IconButton title="Megnyitás..." onClick={() =>
                    window.open("https://web.jogkodex.hu/diff?date=" + dir.diffDir + "&id=" + doc.id, "_blank")
                  }>
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton title="Dokumentum összes változásának megnyitása..." onClick={() =>
                    window.open("https://web.jogkodex.hu/diff?id=" + doc.id, "_blank")
                  }>
                    <OpenInBrowserIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton title="Változás html letöltése..." onClick={() =>
                    {downloadHtml(doc);}
                  }>
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton title="Változás html feltöltése..." disabled={dir.approvedDate != null} onClick={() =>
                    {uploadHtml(doc.id);}
                  }>
                    <PublishIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton title="Eredeti html visszaállítása..." disabled={dir.approvedDate != null} onClick={() =>
                    {restoreHtml(doc.id);}
                  }>
                    <RestoreIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary" autoFocus={true}>
          Bezárás
        </Button>
      </DialogActions>
      <input ref={fileUploader} onChange={onFileChange} type="file" style={{ display: "none" }}/>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};