import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles( ( theme ) => ({
  text: {
    cursor: "pointer"
  },
  editor: {
    fontSize: "10pt"
  }
}) );

const InlineEditor = ( { value, setValue } ) => {
  const classes = useStyles();

  const [edit, setEdit] = useState( false );
  const [data, setData] = useState( value );

  const save = () => {
    setValue( data );
    setEdit( false );
  };

  const onChange = e => setData( e.target.value );

  const onKeyUp = ( e ) => {
    if( e.keyCode === 13 ) {
      save();
      setEdit( false );
    }
  };

  return (
      <div>
        {edit
            ? <TextField
                variant="outlined"
                fullWidth={true}
                size="small"
                value={data}
                onChange={onChange}
                onKeyUp={onKeyUp}
                placeholder="Üres"
                autoFocus={true}
                onBlur={save}
                InputProps={{
                  classes: {
                    input: classes.editor,
                  },
                }}
            />
            : <span
                className={classes.text}
                onClick={() => setEdit( true )}>
                      {value ? value : 'Üres'}
              </span>}
      </div>
  );
};

export default InlineEditor;