import React, {createRef, useEffect, useState} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from "@material-ui/core/styles";
import { 
	Backdrop,
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	Dialog,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	Typography } from "@material-ui/core";
import {useSnackbar} from 'notistack';
import Loader from "../../components/Loader";
import axios from "axios";
import {customRowIndexColumn, preparedComments} from "../../utils";
import MUIDataTable from "mui-datatables";
import JudgeSynonymEditDialog from "./JudgeSynonymEditDialog";
import JudgeSynonymDeleteDialog from "./JudgeSynonymDeleteDialog";

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	toolbar: {
		padding: "1em",
		margin: "1em 0"
	},
	panel: {
		marginBottom: '1em'
	},
	table: {
		"& td": {
		padding: "6px"
		}
	},
	buttonCol: {
		width: "80px",
		padding: "6px"
	},
	typeSelect: {
		width: "100%",
		"& em": {
		  color: "gray"
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}) );

function createRule(courts) {
	return {
		id: null,
		name: "elírás",
		synonym: "elírás", 
		judge: "elírás", 
		courts: courts,
		courtId: 23,
		comment: "Megjegyzés",
		createDate: "2021.04.08 17:06:35", 
		modifyDate: "2021.04.08 17:06:35",
		username: "user"
	};
}

const fixDocumentColumns = [
			customRowIndexColumn(),
	{name: 'id', label: 'ID', options: {sort: true, filter: false}},
	{name: 'synonym', label: 'Cserélendő szöveg', options: {sort: true}},
	{name: 'judgeName', label: 'Helyes bírónév', options: {sort: true}},
	{name: 'courtName', label: 'Bíróság neve', options: {sort: true }},
	{name: 'createDate', label: 'Létrehozás dátuma', options: {sort: true}},
	{name: 'modifyDate', label: 'Módosítás dátuma', options: {sort: true}},
	{name: 'username', label: 'Utolsó módosító', options: {sort: true}},
	{name: 'comment', label: 'Megjegyzés', options: {sort: true}},
	];

export default function  JudgeSynonyms() {
	const classes = useStyles();

		// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// varolistas szabalyok, a fooldalon tablazatban jelennek meg
	const [documents, setDocuments] = useState();
	// progress jelzes a lassu muveletekhez
	const [backdrop, setBackdrop] = useState(false);
	// birosagok listaja, az egyes dialogusoknak parameterkent atadando birosagok kiindulo adatszerkezete
	const [courts, setCourts] = useState([]);
	// helyes bironevek, az egyes dialogusoknak parameterkent atadando adatszerkezete
	const [judges, setJudges] = useState([]);
	// a muvelethez tartozo szabaly, torles dialogusnak parameterkent atadando adatszerkezet
	const [rule, setRule] = useState(createRule());
	// az uj rekord mentesekor megadott cserelendo szoveg
	const newOrigText = createRef();
	// az uj rekord mentesekor megadott birosag
	const [newCourtId, setNewCourtId] = useState();
	// a megjegyzes Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott/megadott szoveget
	const [newComment, setNewComment] = useState();
	// uj rekord mentesekor az utkozo szabalyok
	const [insertRelatedRules, setInsertRelatedRules] = useState([]);
	// uj rekord mentesekor utkozo szabalyokat megjelenito dialogus
	const [openInsertFailedDialog, setOpenInsertFailedDialog] = useState(false);
	// szabaly(ok) muveleteiben szerverrol erkezo kapcsolodo dokumentumok, dialogusok parameterkent atadando adatszerkezet
	const [ruleRelatedDocs, setRuleRelatedDocs] = useState([]);

	// szabalybol csere-szabalyt keszito dialogus lathatosaga
	const [openEditReplaceRuleDialog, setEditReplaceRuleDialog] = useState(false);
	// egy szabaly torleset vegzo dialogus lathatosaga
	const [openDeleteRuleDialog, setDeleteRuleDialog] = useState(false);

	// a bironev Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott nevet (a megadott rule.synonym-rol indul)
	const [judge, setJudge] = useState( '' );

	const showEditReplaceRuleDialog = () => setEditReplaceRuleDialog(true);
	const closeEditReplaceRuleDialog = () => {
		setEditReplaceRuleDialog(false);
	};
	const closeEditReplaceRuleDialogRefresh = () => {
		setEditReplaceRuleDialog(false);
		refresh(true);
	};

	const handleInsertFailedDialogClose = () => {
		setOpenInsertFailedDialog(false);
		enqueueSnackbar('Hiba: mentés sikertelen', {variant: "error"});
	}

	const showDeleteRuleDialog = () => setDeleteRuleDialog(true);
	const closeDeleteRuleDialog = () => {
		setDeleteRuleDialog(false);
	};
	const closeDeleteRuleDialogRefresh = () => {
		setDeleteRuleDialog(false);
		refresh(true);
	};

	const refresh = (needBackdrop) => {
		if ( needBackdrop ){
			setBackdrop(true);
		}
		axios( "/api/judgesynms" ).then( resp => {
			if ( needBackdrop ){
				setBackdrop(false);
			}
			setCourts( resp.data.courts );
			setJudges( resp.data.judges );
			setDocuments( resp.data.list );
		} );
	}

	useEffect( () => {
		refresh(false);
	}, [ ] );

	const saveNewReplaceRule = () => {
		const origText = newOrigText.current.value;
		const courtId = newCourtId ? newCourtId.key : undefined;
		if (!origText || !origText.trim().length) {
			enqueueSnackbar('A cserélendő szöveget megadása kötelező!', {variant: "warning"});
			return;
		}
		if ( !judge || !judge.trim().length) {
			enqueueSnackbar('A helyes szöveg megadása kötelező!', {variant: "warning"});
			return;
		}
		if ( origText === judge ) {
			enqueueSnackbar('A cserélendő szöveg nem egyezhet meg a helyes névvel!', {variant: "warning"});
			return;
		}
		setBackdrop(true);

		const request = {
			url: '/api/judgesynms',
			method: 'POST',
			data: {
				synonym: origText,
				judge: judge,
				courtId: courtId,
				comment: newComment
			}
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				if ( resp.data.relatedRules && resp.data.relatedRules.length > 0 ) {
					setInsertRelatedRules( resp.data.relatedRules );
					setOpenInsertFailedDialog(true);
				} else {
					enqueueSnackbar( 'Új csereszabály: "' + origText + '"->"' + judge + '" sikeresen mentve', {variant: "success"} );
					refresh(true);
				}
			})
			.catch(handleError);

	};

	const editName = (id) => {
		setBackdrop(true);
		const request = {
			url: '/api/judgesynms/' + id,
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				// a szerver valaszban vannak a kapcsolodo dokumentumok, megjelenitjuk a szerkeszto-ablakban
				setRule( resp.data.rule );
				setRuleRelatedDocs( resp.data.related );
				showEditReplaceRuleDialog();
			})
			.catch(handleError);
	};

	const deleteName = (id) => {
		setBackdrop(true);
		const request = {
			url: '/api/judgesynms/' + id,
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				setRule( resp.data.rule );
				setRuleRelatedDocs( resp.data.related );
				showDeleteRuleDialog();
			})
			.catch(handleError);
	}

	const documentColumns = [
		{
			name: "id",
			label: "Törlés",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<IconButton aria-label="delete" title="Törlés" onClick={() => deleteName(value)}>
							<Icon fontSize="small">delete</Icon>
						</IconButton>
					);
				}
			}
		},
		{
			name: "id",
			label: "Szerkesztés",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<IconButton aria-label="create" title="Szerkesztés" onClick={() => editName(value)} >
							<Icon fontSize="small">create</Icon>
						</IconButton>
					);
				}
			}
		},
		...fixDocumentColumns
	];

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const options = {
		filter: true,
		selectableRows: 'none',
		filterType: 'dropdown',
		responsive: 'vertical',
        rowsPerPageOptions: [10, 50, 100, 1000],
	};

	return (
		<div>
			<Typography variant="h4">Bírónév szinonimák (elírások, asszonynevek)</Typography>
				<Paper elevation={3} className={classes.toolbar}>
				<Grid container spacing={5} justify="center" alignItems="center">
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							size="small"
							fullWidth={true}
							inputRef={newOrigText}
							label="Cserélendő szöveg..."
							InputProps={{
								className: classes.input
							}}
						/>
					</Grid>
					<Grid item xs={4}>
							{judges ? <Autocomplete
								id="judgecombo"
								size="small"
								options={judges}
								freeSolo={true}
								autoSelect={true}
								onChange={(event, value) => setJudge(value)}
								renderInput={(params) => <TextField {...params} label="Helyes Bírónév" variant="outlined"/> } 
								/>
							: null }
					</Grid>
					<Grid item xs={2}>
							{courts ? <Autocomplete
								id="courtcombo"
								options={courts}
								size="small"
								onChange={(event, value) => setNewCourtId(value)}
								getOptionLabel={(option) => option.value}
								renderInput={(params) => <TextField {...params} label="Bíróság" variant="outlined"/> }
								/>
							: null }
					</Grid>
					<Grid item xs={2}>
						<Autocomplete
							id="combo-box-demo3"
							options={preparedComments}
							size="small"
							freeSolo={true}
							autoSelect={true}
							onChange={(event, value) => setNewComment(value)}
							renderInput={(params) => <TextField {...params} label="Megjegyzés" variant="outlined"/> }
							/>
					</Grid>
				</Grid>
				<Grid container spacing={5}>
					<Grid item xs={5}>
						<Button
							variant="contained"
							color="primary"
							className={classes.redButton}
							startIcon={<Icon>send</Icon>}
							onClick={saveNewReplaceRule}
						>
							Mentés
						</Button>
					</Grid>
				</Grid>
			</Paper>

			{documents ? null : <Loader/>}

			<Dialog
				open={openEditReplaceRuleDialog}
				onClose={closeEditReplaceRuleDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<JudgeSynonymEditDialog
					rule={rule}
					courts={rule.courtId?[courts.find(element => element.key === rule.courtId)]:[]}
					judges={judges}
					ruleRelatedDocs={ruleRelatedDocs}
					closeDialog={closeEditReplaceRuleDialog}
					closeRefreshDialog={closeEditReplaceRuleDialogRefresh}
					saveUrl='/api/judgesynms/%id'
					header='Szinonima módosítása'
					preText='Az itt módosított csere-szabálynak csak a lenti a dokumentum-metaadatokra van hatása, amikhez nem tartozik külön 
					valid bírónév. Ezeken felül csak később bekerülő anyagoknál kerül felhasználásra.'
					saveButtonText='Csere-szabály módosítása'/>
			</Dialog>

			<Dialog
				open={openDeleteRuleDialog}
				onClose={closeDeleteRuleDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<JudgeSynonymDeleteDialog
					rule={rule}
					ruleRelatedDocs={ruleRelatedDocs}
					closeDialog={closeDeleteRuleDialog}
					closeRefreshDialog={closeDeleteRuleDialogRefresh}
					saveUrl='/api/judgesynms/%id'/>
			</Dialog>

			<Dialog open={openInsertFailedDialog} onClose={() => setOpenInsertFailedDialog(false)} fullWidth maxWidth="lg">
				<DialogTitle id="customized-dialog-title" onClose={() => setOpenInsertFailedDialog(false)}>
					Szabály ütközés
				</DialogTitle>
				<DialogContent>
					<Typography variant="body1">Az új csereszabály az alábbi meglévő szabályokkal ütközik:</Typography>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell align="right" className={classes.indexCol}>#</TableCell>
								<TableCell align="right">ID</TableCell>
								<TableCell>Bíróság neve</TableCell>
								<TableCell>Szabály</TableCell>
								<TableCell>Létrehozás</TableCell>
								<TableCell>Módosítás</TableCell>
								<TableCell>Utolsó módosító</TableCell>
								<TableCell>Megjegyzés</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{insertRelatedRules?.map((rule, index) => (
								<TableRow key={'id' + rule.id}>
									<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
									<TableCell align="right">{rule.id}</TableCell>
									<TableCell>{rule.courtName}</TableCell>
									<TableCell>{rule.ruleText}</TableCell>
									<TableCell>{rule.createDate}</TableCell>
									<TableCell>{rule.modifiedDate}</TableCell>
									<TableCell>{rule.username}</TableCell>
									<TableCell>{rule.comment}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleInsertFailedDialogClose} color="primary" autoFocus className={classes.button} variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<Paper elevation={3} className={classes.table}>
					<MUIDataTable
						data={documents || []}
						columns={documentColumns}
						options={options}
						/>
			</Paper>

			<Backdrop className={classes.backdrop} open={backdrop}>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</div>
	);
};
