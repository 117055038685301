import React from 'react';
import {UserProvider} from "./context";
import Routes from './routes';

import {ThemeProvider} from '@material-ui/core/styles'
import theme from "./theme";
import Container from "./components/Container";
import {SnackbarProvider} from 'notistack';


//https://www.obytes.com/blog/setting-up-authentication-flow-in-react-applications

function App() {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} autoHideDuration={1000}>
                <UserProvider>
                    <Container>
                        <Routes/>
                    </Container>
                </UserProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
