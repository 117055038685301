export const relogin = async (userContext) => {
    console.log("User context: " + userContext.user)
    return fetch('/api/auth/profile')
        .then(handleResponse(userContext))
        .then(data => {
            console.log(data);
            userContext.createSession({
                username: data.username,
                fullname: data.fullname
            });

            return data;
        });
};

export const login = async (username, password, userContext) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username, password})
    };

    return fetch('/api/auth', requestOptions)
        .then(handleResponse(userContext))
        .then(data => {
            if (data.status === 'success') {
                userContext.createSession({
                    username: data.username,
                    fullname: data.fullname,
                    authorities: data.authorities
                });
            }

            return data;
        });
};

export const logout = (userContext) => {
    setTimeout(()=>userContext.removeSession(), 300);
};

export const handleResponse = (userContext) => {
    return (response) => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    logout(userContext);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }
};
