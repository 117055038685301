import React, {useEffect, useState} from "react";
import {
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function CustomIframe ({
  children,
  ...props
  }) {

  const classes = useStyles();
    // a szerver-muveletek idejere a feluletet inaktivra allitjuk
  const [backdrop, setBackdrop] = useState(false);

  // ha a props.src mezo valtozik, akkor elinditjuk a progress-jelzest
  useEffect(() => {
      setBackdrop( true );
    }, [props.src])

  // ha az iframe-n lefut az onLoad, akkor leallitjuk a progress-jelzest
  const iframeLoaded = () => {
    setBackdrop(false);
  }

  return (
   <>
    <iframe title="emailContent" onLoad={iframeLoaded} {...props}>
      createPortal(children)
    </iframe>
    <Backdrop className={classes.backdrop} open={backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
   </>
  )
}