import React from "react";

export default function Table() {
  return (
      <div>

        Profile
      </div>
  );
}
