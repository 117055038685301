import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Backdrop,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {useSnackbar} from 'notistack';

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	dialogGrid: {
		flexGrow: 1,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

// a feluletnek az megjelenesi allapotai lehetsegesek:
// - kiindulo allapot: a kapott szabalybeli nev, birosag es datum mezok megjelenitese inaktivan, valamint a kapcsolodo dokumentumok listaja
export default function NotEnoughJudgeRemigrateDialog({ids, closeDialog, closeRefreshDialog, saveOneUrl, saveMultiUrl, docs}) {
	const classes = useStyles();

	// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// a szerver-muveletek idejere a feluletet inaktivra allitjuk
	const [backdrop, setBackdrop] = React.useState(false);

	const submit = () => {
		setBackdrop(true);
		const single = ( ids.length === 1 );
		const url = single ? saveOneUrl.replace( '%id', ids[ 0 ] ) : saveMultiUrl.replace( '%ids', ids )
		const data = single ? null : ids;

		const request = {
			url: url,
			method: 'POST',
			data: data
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				if ( resp.data.updated === ids.length )
				{
					const text = 'Dokumentum' + ( single ? "" : "ok (id=" ) + ids;
					enqueueSnackbar( text + ') újramigrálásra jelölése sikeres', {variant: "success"} );
				}
				else if ( resp.data.updated === 0 )
				{
					const textPrefix = 'Dokumentum' + ( single ? "" : "ok (id=" ) + ids;
					enqueueSnackbar( textPrefix + ') újramigrálásra jelölése sikertelen', {variant: "error"} );
				}
				else if ( resp.data.updated < ids.length )
				{
					const textPrefix = 'Dokumentum' + ( single ? "" : "ok (id=" ) + ids;
					const textPostfix = 'Sikertelen:' + ( ids.length - resp.data.updated ) + " db";
					enqueueSnackbar( textPrefix + ') újramigrálásra jelölése részben sikeres. ' + textPostfix, {variant: "warning"} );
				}
				else
				{
					handleError();
				}
				setBackdrop( false );
				closeRefreshDialog();
			})
			.catch(handleError);
	};

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	return (
	<div>
		<form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
			<DialogTitle>
				Dokumentum megjelölése újramigrálásra
			</DialogTitle>

			<DialogContent className={classes.dialogGrid}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>FaCím1</TableCell>
							<TableCell>Bírók</TableCell>
							<TableCell>Jelenleg újramigrálandó?</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{docs?.map((doc, index) => (
							<TableRow key={doc.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{doc.id}</TableCell>
								<TableCell>{doc.title}</TableCell>
								<TableCell>{doc.judgeNames == null ? '-' : doc.judgeNames == null}</TableCell>
								<TableCell>{doc.remigrateFlag == null
									? 'még migrálatlan' 
									:doc.remigrateFlag === false ? 'nem' : 'igen'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog} color="primary" autoFocus={true}>
					Mégsem
				</Button>
				<Button
					variant="contained"
					color="primary"
					type="button"
					className={classes.redButton}
					startIcon={<Icon>saveIcon</Icon>}
					onClick={submit}
				>
					Újramigrálásra jelölés
				</Button>
			</DialogActions>
		</form>

		<Backdrop className={classes.backdrop} open={backdrop}>
			<CircularProgress color="inherit"/>
		</Backdrop>
	</div>
	);
};