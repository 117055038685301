import React, {useEffect, useState} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from "@material-ui/core/styles";
import { 
	Backdrop,
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	Dialog,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	Typography } from "@material-ui/core";
import {useSnackbar} from 'notistack';
import Loader from "../../components/Loader";
import axios from "axios";
import {customRowIndexColumn, preparedComments} from "../../utils";
import MUIDataTable from "mui-datatables";
import ValidJudgeEditDialog from "./ValidJudgeEditDialog";
import ValidJudgeDeleteDialog from "./ValidJudgeDeleteDialog";

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	toolbar: {
		padding: "1em",
		margin: "1em 0"
	},
	panel: {
		marginBottom: '1em'
	},
	table: {
		"& td": {
		padding: "6px"
		}
	},
	buttonCol: {
		width: "80px",
		padding: "6px"
	},
	typeSelect: {
		width: "100%",
		"& em": {
		  color: "gray"
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	typography: {
		padding: "10px"
	},
}) );

const fixDocumentColumns = [
			customRowIndexColumn(),
	{name: 'id', label: 'ID', options: {sort: true, filter: false}},
	{name: 'source', label: 'Forrás', options: {sort: true, filter: false}},
	{name: 'judgeName', label: 'Helyes bírónév', options: {sort: true}},
	{name: 'courtName', label: 'Bíróság neve', options: {sort: true }},
	{name: 'synonym', label: 'Cserélt szöveg', options: {sort: true}},
	{name: 'createDate', label: 'Létrehozás dátuma', options: {sort: true}},
	{name: 'modifyDate', label: 'Módosítás dátuma', options: {sort: true}},
	{name: 'username', label: 'Utolsó módosító', options: {sort: true}},
	{name: 'comment', label: 'Megjegyzés', options: {sort: true}},
	];

export default function  ValidJudges() {
	const classes = useStyles();

		// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// varolistas szabalyok, a fooldalon tablazatban jelennek meg
	const [documents, setDocuments] = useState();
	// progress jelzes a lassu muveletekhez
	const [backdrop, setBackdrop] = useState(false);
	// a birosagok listaja
	const [courts, setCourts] = useState([]);
	// a kivalasztott birosag azonositoja
	const [newCourtId, setNewCourtId] = useState();
	// helyes bironevek, az egyes dialogusoknak parameterkent atadando adatszerkezete
	const [judges, setJudges] = useState([]);
	// a muvelethez tartozo szabaly, dialogusoknak parameterkent atadando adatszerkezet
	const [rule, setRule] = useState({});
	// uj rekord mentesekor az utkozo szabalyok
	const [insertRelatedRules, setInsertRelatedRules] = useState([]);
	// uj rekord mentesekor utkozo szabalyokat megjelenito dialogus
	const [openInsertFailedDialog, setOpenInsertFailedDialog] = useState(false);

	// bironev szerkeszto dialogus lathatosaga
	const [openEditReplaceRuleDialog, setEditReplaceRuleDialog] = useState(false);
	// egy szabaly torleset vegzo dialogus lathatosaga
	const [openDeleteRuleDialog, setDeleteRuleDialog] = useState(false);
	// szabaly(ok) muveleteiben szerverrol erkezo kapcsolodo dokumentumok, dialogusok parameterkent atadando adatszerkezet
	const [ruleRelatedDocs, setRuleRelatedDocs] = useState([]);

	// a bironev Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott nevet (a megadott rule.synonym-rol indul)
	const [judge, setJudge] = useState( '' );
	// a megjegyzes Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott/megadott szoveget
	const [newComment, setNewComment] = useState();

	const showEditReplaceRuleDialog = () => setEditReplaceRuleDialog(true);
	const closeEditReplaceRuleDialog = () => {
		setEditReplaceRuleDialog(false);
	};
	const closeEditReplaceRuleDialogRefresh = () => {
		setEditReplaceRuleDialog(false);
		refresh(true);
	};

	const handleInsertFailedDialogClose = () => {
		setOpenInsertFailedDialog(false);
		enqueueSnackbar('Hiba: mentés sikertelen', {variant: "error"});
	}

	const showDeleteRuleDialog = () => setDeleteRuleDialog(true);
	const closeDeleteRuleDialog = () => {
		setDeleteRuleDialog(false);
	};
	const closeDeleteRuleDialogRefresh = () => {
		setDeleteRuleDialog(false);
		refresh(true);
	};

	const refresh = (needBackdrop) => {
		if ( needBackdrop ){
			setBackdrop(true);
		}
		axios( "/api/validjudges" ).then( resp => {
			if ( needBackdrop ){
				setBackdrop(false);
			}
			setJudges( resp.data.judges );
			setCourts( resp.data.courts );
			setDocuments( resp.data.list );
		} );
	}

	useEffect( () => {
		refresh(false);
	}, [ ] );

	const saveNewJudge = () => {
		if ( !judge || !judge.trim().length ) {
			enqueueSnackbar('A név megadása kötelező!', {variant: "warning"});
			return;
		}
		if ( judge.trim().indexOf( ' ' ) < 0 ) {
			enqueueSnackbar('Nem megengedett az egyszavas bírónév!', {variant: "warning"});
			return;
		}
		setBackdrop(true);
		const courtId = newCourtId ? newCourtId.key : undefined;

		const request = {
			url: '/api/validjudges',
			method: 'POST',
			data: {
				judge: judge,
				courtId: courtId,
				comment: newComment,
			}
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				if ( resp.data.relatedRules && resp.data.relatedRules.length > 0 ) {
					setInsertRelatedRules( resp.data.relatedRules );
					setOpenInsertFailedDialog(true);
				} else {
					enqueueSnackbar( 'Új bírónév: "' + judge + '" sikeresen mentve', {variant: "success"} );
					refresh(true);
				}
			})
			.catch(handleError);
	};

	const editName = (id) => {
		setBackdrop(true);
		const request = {
			url: '/api/validjudges/' + id,
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				// a szerver valaszban vannak a kapcsolodo dokumentumok, megjelenitjuk a szerkeszto-ablakban
				setRule( resp.data.rule );
				setRuleRelatedDocs( resp.data.related );
				showEditReplaceRuleDialog();
			})
			.catch(handleError);
	};

	const deleteName = (id) => {
		setBackdrop(true);
		const request = {
			url: '/api/validjudges/' + id,
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				setRule( resp.data.rule );
				setRuleRelatedDocs( resp.data.related );
				showDeleteRuleDialog();
			})
			.catch(handleError);
	}

	const documentColumns = [
		{
			name: "id",
			label: "Törlés",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return ( tableMeta.rowData[ 4 ] === "Névlista" )
						?	<IconButton aria-label="delete" title="Törlés" onClick={() => deleteName(value)}>
								<Icon fontSize="small">delete</Icon>
							</IconButton>
						: '';
				}
			}
		},
		{
			name: "id",
			label: "Szerkesztés",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return ( tableMeta.rowData[ 4 ] === "Névlista" )
						?	<IconButton aria-label="create" title="Szerkesztés" onClick={() => editName(value)} >
								<Icon fontSize="small">create</Icon>
							</IconButton>
						: '';
				}
			}
		},
		...fixDocumentColumns
	];

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const options = {
		filter: true,
		selectableRows: 'none',
		filterType: 'dropdown',
		responsive: 'vertical',
        rowsPerPageOptions: [10, 50, 100, 1000],
	};

	return (
		<div>
			<Typography variant="h4">Valid bírónevek</Typography>
				<Paper elevation={3} className={classes.toolbar}>
				<Grid container spacing={5} justify="center" alignItems="center">
					<Grid item xs={6}>
							{judges ? <Autocomplete
								id="judgecombo"
								size="small"
								options={judges}
								freeSolo={true}
								autoSelect={true}
								onChange={(event, value) => setJudge(value)}
								renderInput={(params) => <TextField {...params} label="Helyes Bírónév" variant="outlined"/> } 
								/>
							: null }
					</Grid>
					<Grid item xs={2}>
							{courts ? <Autocomplete
								id="combo-box-demo"
								options={courts}
								size="small"
								getOptionLabel={(option) => option.value}
								onChange={(event, value) => setNewCourtId(value)}
								renderInput={(params) => <TextField {...params} label="Bíróság" variant="outlined"/> }
								/>
							: null }
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							id="combo-box-demo3"
							options={preparedComments}
							size="small"
							defaultValue={rule.comment}
							freeSolo={true}
							autoSelect={true}
							onChange={(event, value) => setNewComment(value)}
							renderInput={(params) => <TextField {...params} label="Megjegyzés" variant="outlined"/> }
							/>
					</Grid>
				</Grid>
				<Grid container spacing={5}>
					<Grid item xs={5}>
						<Button
							variant="contained"
							color="primary"
							className={classes.redButton}
							startIcon={<Icon>send</Icon>}
							onClick={saveNewJudge}
						>
							Új bírónév mentése
						</Button>
					</Grid>
				</Grid>
			</Paper>

			{documents ? null : <Loader/>}

			<Dialog
				open={openEditReplaceRuleDialog}
				onClose={closeEditReplaceRuleDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<ValidJudgeEditDialog 
					rule={rule}
					judges={judges}
					courts={rule.courtId ? [courts.find(element => element.key === rule.courtId)] : []}
					ruleRelatedDocs={ruleRelatedDocs}
					closeDialog={closeEditReplaceRuleDialog}
					closeRefreshDialog={closeEditReplaceRuleDialogRefresh}
					saveUrl='/api/validjudges/%id'
				/>
			</Dialog>

			<Dialog
				open={openDeleteRuleDialog}
				onClose={closeDeleteRuleDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<ValidJudgeDeleteDialog
					rule={rule}
					ruleRelatedDocs={ruleRelatedDocs}
					closeDialog={closeDeleteRuleDialog}
					closeRefreshDialog={closeDeleteRuleDialogRefresh}
					saveUrl='/api/validjudges/%id'/>
			</Dialog>

			<Dialog open={openInsertFailedDialog} onClose={() => setOpenInsertFailedDialog(false)} fullWidth maxWidth="lg">
				<DialogTitle id="customized-dialog-title" onClose={() => setOpenInsertFailedDialog(false)}>
					Szabály ütközés
				</DialogTitle>
				<DialogContent>
					<Typography variant="body1">Az új bírónév az alábbi meglévő szabályokkal ütközik:</Typography>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell align="right" className={classes.indexCol}>#</TableCell>
								<TableCell align="right">ID</TableCell>
								<TableCell>Bíróság neve</TableCell>
								<TableCell>Szabály</TableCell>
								<TableCell>Létrehozás</TableCell>
								<TableCell>Módosítás</TableCell>
								<TableCell>Utolsó módosító</TableCell>
								<TableCell>Megjegyzés</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{insertRelatedRules?.map((rule, index) => (
								<TableRow key={'id' + rule.id}>
									<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
									<TableCell align="right">{rule.id}</TableCell>
									<TableCell>{rule.courtName}</TableCell>
									<TableCell>{rule.ruleText}</TableCell>
									<TableCell>{rule.createDate}</TableCell>
									<TableCell>{rule.modifiedDate}</TableCell>
									<TableCell>{rule.username}</TableCell>
									<TableCell>{rule.comment}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleInsertFailedDialogClose} color="primary" autoFocus className={classes.button} variant="contained">
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<Paper elevation={3} className={classes.table}>
					<Typography className={classes.typography}>Az itt felsorolt nevek vannak használatban a bírónév felismerőben. A nevek 
					egy részét csereszabályok határozzák meg, mely esetben az adott név lehet csak egy bíróságon engedélyezett 
					('Forrás' oszlop: 'Csereszabály'). A csak kis-nagybetűben különböző nevekre jelenleg azért van külön szükség, 
					mert minden olyan formában szükséges megtartani, ahogyan a dokumentumokban szükséges felismerni 
					(pl."Valakiné dr. Nem Réka", "Valakiné Dr. Nem Réka").
					A dokumentumok meta-adataiban a csak kis-nagybetűben eltérő szövegek már nincsenek megkülönböztetve, így ha a
					bírónév felismerő talál egy "Valakiné Dr. Nem Réka"-t (abban az esetben, ha az a lenti listában már szerepel),
					és ez a név már szerepel "Valakiné dr. Nem Réka" formában valamely dokumentumhoz tartozó bírónévként,
					akkor ez utóbbi kerül mentésre a dokumentum meta-adataiba. Ez okozza, hogy a kétféleképp ('Dr' és 'dr'-el) is írt 
					bírónevek egyikéhez, látszólag nem tartozik dokumentum.
					Ennek alternatívája, hogy olyan csereszabályként kell felvenni a listán kívüli változatot, ami azt a listabelire cseréli.
					</Typography>
					<MUIDataTable
						data={documents || []}
						columns={documentColumns}
						options={options}
						/>
			</Paper>

			<Backdrop className={classes.backdrop} open={backdrop}>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</div>
	);
};
