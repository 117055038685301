import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import { 
	Backdrop,
	CircularProgress,
	Icon,
	IconButton,
	Dialog,
	Paper,
	Typography } from "@material-ui/core";
import {customRowIndexColumn} from "../../utils";
import {useSnackbar} from 'notistack';
import axios from "axios";
import Loader from "../../components/Loader";
import MUIDataTable from "mui-datatables";
import MultiSelectTableToolbar from "../../components/MultiSelectTableToolbar";
import NotEnoughJudgeRemigrateDialog from "./NotEnoughJudgeRemigrateDialog";

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	toolbar: {
		padding: "1em",
		margin: "1em 0"
	},
	panel: {
		marginBottom: '1em'
	},
	table: {
		"& td": {
		padding: "6px"
		}
	},
	buttonCol: {
		width: "80px",
		padding: "6px"
	},
	typeSelect: {
		width: "100%",
		"& em": {
		  color: "gray"
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	typography: {
		padding: "10px"
	},
}) );

const fixDocumentColumns = [
			customRowIndexColumn(),
	{name: 'id', label: 'ID', options: {sort: true, filter: false}},
	{name: 'title', label: 'Title 1', options: {sort: true, filter: true}},
	{name: 'judgeCount', label: 'Valid bírók száma',
			options: {
			filter: false,
			sort: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return '0';
			}
		}

	},
	{name: 'remigrateFlag', label: 'Jelenleg újramigrálandó?',
		options: {
			filter: false,
			sort: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return ( value == null ) ? 'még migrálatlan' : ( ( value === true ) ? 'igen' : 'nem' );
			}
		}
	},
	];

export default function  NoneJudges(props) {
	const classes = useStyles();
		// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// varolistas szabalyok, a fooldalon tablazatban jelennek meg
	const [documents, setDocuments] = useState();
	// progress jelzes a lassu muveletekhez
	const [backdrop, setBackdrop] = useState(false);

	// tablazat kijelolt soraihoz tartozo szabalyokat tarolo valtozo, azert csak gomb-nyomaskor allitjuk be a selectedRules-be hogy ne lassitson
	const [tableRowsSelected, setTableRowsSelected] = useState([]);
	// az ujramigralas-megerosito ablak megjeleniteset szabalyzo flag
	const [remigrateDialog, setRemigrateDialog] = useState(false);
	// kivalasztott sorbeli dokumentum-azonosito
	const [docIds, setDocIds] = useState([]);
	// kivalasztott sorbeli dokumentum-adatai
	const [docs, setDocs] = useState([]);

	const showRemigrateDialog = () => setRemigrateDialog(true);
	const closeRemigrateDialog = () => {
		setRemigrateDialog(false);
	};
	const closeRemigrateDialogRefresh = () => {
		setRemigrateDialog(false);
		refresh(true);
	};

	const refresh = (needBackdrop) => {
		if ( needBackdrop ){
			setBackdrop(true);
		}
		axios( "/api/nonejudges" ).then( resp => {
			if ( needBackdrop ){
				setBackdrop(false);
			}
			setDocuments( resp.data.list );
		} );
	}

	useEffect( () => {
		refresh(false);
	}, [ ] );

	const reMigrateDoc = (docId) => {
		setBackdrop(true);
		const request = {
			url: '/api/nonejudges/' + docId + '/remigrate',
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				// a szerver valaszban vannak a dokumentumok, megjelenitjuk oket a jovahagyas ablakban
				setDocIds( [ docId ] );
				setDocs( resp.data.related );
				showRemigrateDialog();
			})
			.catch(handleError);
	}

	const editDoc = (docId) => {
		//window.location.href = '/bh/editor/' + docId;
		window.open( '/bh/editor/' + docId , '_blank' );
	}

	const documentColumns = [
		{
			name: "id",
			label: "Újramigrálásra jelölés",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return ( tableMeta.rowData[ 6 ] === false )
					?	<IconButton aria-label="autorenew" title="Újramigrálásra jelölés" onClick={() => reMigrateDoc(value)} >
							<Icon fontSize="small">restore</Icon>
						</IconButton>
					: '';
				}
			}
		},
		{
			name: "id",
			label: "Szerkesztés",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return <IconButton aria-label="create" title="Szerkesztés" onClick={() => editDoc(value)} >
								<Icon fontSize="small">create</Icon>
							</IconButton>
				}
			}
		},
		...fixDocumentColumns
	];

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const markDocuments = () => {
		setBackdrop(true);
		let ids = tableRowsSelected.map( i => documents[ i ].id );
		const request = {
			url: '/api/nonejudges/' + ids.join(',') + '/remigrate',
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				// a szerver valaszban vannak a dokumentumok, megjelenitjuk oket a jovahagyas ablakban
				setDocIds( ids );
				setDocs( resp.data.related );
				showRemigrateDialog();
			})
			.catch(handleError);
	}

	const options = {
		filter: true,
		selectableRows: 'multiple',
		filterType: 'dropdown',
		responsive: 'vertical',
		rowsPerPageOptions: [10, 50, 100, 1000],
		rowsSelected: tableRowsSelected,
		customToolbarSelect: () => {
			return (
				<MultiSelectTableToolbar handleClick={markDocuments} iconName='restore' className='restore' tooltip='Kijelöltek újramigrálásra állítása'/>
			);
		},
		onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
			setTableRowsSelected( rowsSelected );
		}
	};

	return (
		<div>
			<Typography variant="h4">Bírónév nélküli dokumentumok</Typography>

			{documents ? null : <Loader/>}

			<Paper elevation={3} className={classes.table}>
					<Typography className={classes.typography}>Az itt felsorolt dokumentumoknak jelenleg nincs bírónév metaadata
					</Typography>
					<MUIDataTable
						data={documents || []}
						columns={documentColumns}
						options={options}
						/>
			</Paper>

			<Dialog
				open={remigrateDialog}
				onClose={closeRemigrateDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<NotEnoughJudgeRemigrateDialog
					ids={docIds}
					closeDialog={closeRemigrateDialog}
					closeRefreshDialog={closeRemigrateDialogRefresh}
					docs={docs}
					saveOneUrl='/api/nonejudges/%id/remigrate'
					saveMultiUrl='/api/nonejudges/remigratelist'/>
			</Dialog>

			<Backdrop className={classes.backdrop} open={backdrop}>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</div>
	);
};
