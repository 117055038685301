import React from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
	iconButton: {
	},
};

// DataTable options-ban CustomToolbarSelect-kent megadhato komponens, aminek letrehozaskor a handleClick property-ben kell megadni a hivando metodust
class MultiSelectTableToolbar extends React.Component {
	render() {
		const { classes } = this.props;
		const className = this.props.className ? this.props.className : classes.deleteIcon;
		const iconName = this.props.iconName ? this.props.iconName : 'delete';
		const tooltip = this.props.tooltip ? this.props.tooltip : 'Kijelöltek törlése';
		return (
			<React.Fragment>
				<Tooltip title={tooltip}>
					<IconButton className={classes.iconButton} onClick={this.props.handleClick}>
						<Icon className={className} fontSize="small">{iconName}</Icon>
					</IconButton>
				</Tooltip>
			</React.Fragment>
		);
	}
}

export default withStyles( defaultToolbarStyles, { name: "MultiSelectTableToolbar" } )( MultiSelectTableToolbar );