import React, {useContext, useEffect} from "react";
import {userContext} from "../context";
import Loader from "./Loader";
import Login from "../pages/Login";

export default ( { component: Component, guard, ...props } ) => {
  const user = useContext( userContext );
  const page = <Component {...props} />;

  const isDataLoaded = !!user.profile;

  useEffect( () => {
    if( !isDataLoaded ) {
      user.fetchUser();
    }
  }, [isDataLoaded,user] );

  if( user.isLoading ) {
    return <Loader/>;
  }

  /**
   * When no guard provided
   * or user is not authenticated and guard allows - rendering the page
   */
  if( !guard || (!user.isAuthenticated && guard( false )) ) {
    return page;
  }

  /**
   * When user is not authenticated and guard denies - redirecting a user
   * to the page according to his user data
   */
  if( !user.isAuthenticated && !guard( false ) ) {
    return <Login/>;
  }

  /**
   * Otherwise rendering a component for Authenticated users
   */
  return (
      <Authenticated guard={guard} user={user}>
        {page}
      </Authenticated>
  );
};

const Authenticated = ( { children, guard, user } ) => {
  const isDataLoaded = !!user.profile;

  if( !isDataLoaded ) return <Loader/>;

  /**
   * Once user is loaded, checking his ability to access the page
   * If denied - redirecting him to the page he has access to
   */
  if( !guard( true, user.profile ) ) {
    return <Login/>;
  }

  /**
   * Otherwise user is allowed and we render page content
   */
  return children;
};
