import React, {useContext} from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import MenuIcon from '@material-ui/icons/Menu';
import makeStyles from "@material-ui/core/styles/makeStyles";

import {userContext} from "../context";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "2em"
    },
}));

export default function Home() {
    const classes = useStyles();

    const user = useContext(userContext);

    return (
        <div>
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h4">Üdvözöllek {user.profile.fullname}!</Typography>
                <Typography variant="body1">A fenti toolbar bal oldalán található <IconButton color="primary"><MenuIcon/></IconButton> gombot megnyomva nyithatod ki a menüt.</Typography>
            </Paper>
        </div>

    );
}
