import React from "react";

export const DATE_FORMAT = /^20\d{2}\.(0[1-9]|1[012])\.(0[1-9]|[12]\d|3[01])$/;

export const preparedComments = [ "bíró", "tisztviselő", "ülnök", "titkár", "ügyvédnév is" ];

export const muiTableLabels = {
    body: {
        noMatch: "Nincs megjeleníthető dokumentum",
        toolTip: "Rendezés",
        columnHeaderTooltip: column => `Rendezés ${column.label} szerint`
    },
    pagination: {
        next: "Következő",
        previous: "Előző",
        rowsPerPage: "Sor / oldal:",
        displayRows: "/",
    },
    toolbar: {
        search: "Keresés",
        downloadCsv: "Letöltés CSV-ben",
        print: "nyomtatás",
        viewColumns: "Nézet beállítások",
        filterTable: "Szűrés",
    },
    filter: {
        all: "Mind",
        title: "SZŰRŐK",
        reset: "VISSZAÁLLÍTÁS",
    },
    viewColumns: {
        title: "Oszlopok megjelenítése",
        titleAria: "Táblázat oszlopok megjelenítése/elrejtése",
    },
    selectedRows: {
        text: "darb sor kiválasztva",
        delete: "Törlés",
        deleteAria: "keleölt elemek törlése",
    },
};

export function customRowIndexColumn() {
    return ({
        name: '#',
        options: {
            filter: false,
            sort: false,
            download: false,
            searchable: false,
            customHeadRender: (meta) => (
                <th key={meta.index} className="MuiTableCell-root MuiTableCell-head" style={{textAlign: 'right'}}>#</th>
            ),
            customBodyRender: (value, meta) => {
                return (
                    (meta.rowIndex + 1) + '.'
                );
            },
            setCellProps: () => ({
                align: 'right'
            }),
        }
    })
}

export function shortText(text, limit) {
    if (!text || text.length < limit) {
        return text;
    }
    let p = text.lastIndexOf(' ', limit);
    return text.substring(0, p < 0 ? limit : p) + '...';
}
