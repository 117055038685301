import React from "react";

const dates = [];
const MAX_DX = 65535;
const DX_SHIFT = -32768;
for (let dx = 0; dx <= MAX_DX; ++dx) {
    const temp = dx - 32;
    let year = Math.trunc(temp / 372);
    let mod = temp - (year * 372);

    if (mod === 0) {
        --year;
        mod = temp - (year * 372);
    }

    let month = Math.trunc(mod / 31);
    let day = mod - (month * 31);

    if (day === 0) {
        --month;
        day = mod - (month * 31);
    }
    year = year + 1912;
    month = month + 1;
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    dates.push(year + '.' + month + '.' + day);
}
dates[0] = '-';
dates[65535] = '\u221e';
dates[65534] = 'Bizonytalan';

export function toDateStr(date, infinite) {
    if (date === 32767 && infinite !== undefined) {
        return infinite;
    }
    return dates[date - DX_SHIFT];
}

export function parse(str) {
    if (str && str.length >= 10) {
        const year = parseInt(str.substring(0,4));
        const month = parseInt(str.substring(5,7));
        const day = parseInt(str.substring(8,10));

        if( year && month && day ) {
            return (((year - 2000) * 372) + ((month - 1) * 31) + day);
        }
    }
    return 32767;
}

const ShortDate = ({date, infinite}) => (<span>{toDateStr(date, infinite)}</span>);

ShortDate.toDateStr = toDateStr;
ShortDate.parse = parse;

export default ShortDate;
