import React, {useState} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import {
	Backdrop,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {useForm} from "react-hook-form";
import {useSnackbar} from 'notistack';
import {preparedComments} from "../../utils";

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	dialogGrid: {
		flexGrow: 1,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

// a feluletnek az megjelenesi allapotai lehetsegesek:
// - kiindulo allapot: a kapott szabalybeli nev (inaktiv), szerkesztheto cserenev (legorduloben a valid nevek), nullazhato birosag mezo, es komment mezok megjelenitese, 
//   valamint a kiindulo birosaghoz tartozo kapcsolodo dokumentumok listaja
// - hibalista allapot: a kiindulo allapotban mentes gombra kovetkezhet be, ha a mentes soran utkozo szabalyokat talalt az ellenorzes
// - megerosites allapot: a kiindulo allapotban mentes gombra kovetkezhet be, ha a mentes soran a nem csak a kiindulo ablakon levo dokumentumok valtoznanak
export default function JudgeSynonymEditDialog({rule, courts, judges, closeDialog, closeRefreshDialog, saveUrl, ruleRelatedDocs, 
		header='Elírás javítása', saveButtonText='Elírás javító szabály mentése',
		preText='Ezen a felületen egy elírt, vagy asszonynevet lehet egyéb névvel helyettesíteni. A csere a lenti dokumentumok meta-adataiban is megtörténik.'}) {
	const classes = useStyles();

	// a birosag Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott birosag id-jet (a megadott rule.courtId-rol indul)
	const [courtId, setCourtId] = useState( rule.courtId );
	// a bironev Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott nevet (a megadott rule.synonym-rol indul)
	const [judge, setJudge] = useState( rule.judge ? rule.judge : rule.synonym );
	// a megjegyzes Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott szoveget
	const [newComment, setNewComment] = useState( rule.comment );
	// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// a szerver-muveletek idejere a feluletet inaktivra allitjuk
	const [backdrop, setBackdrop] = React.useState(false);
	// a beallitott ertekek kozul a szovegeseket ezzel a modszerrel olvassuk ki (kulon inputRef-ek helyett)
	const {register, handleSubmit, getValues} = useForm();
	// a mentes soran utkozo szabalyokat megjelenito komponenst megjelenitesere hasznalt allapotok
	const [openEditFailsDialog, setOpenEditFailsDialog] = useState(false);
	// a mentes soran utkozo szabalyokat megjelenito komponens tartalma
	const [editRuleRelatedRules, setEditRuleRelatedRules] = useState();
	// a mentes soran megerositheto szabalyokat es dokumentumokat megjelenito komponenst megjelenitesere hasznalt allapotok
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	// a mentes soran megerositheto szabalyokat megjelenito komponens tartalma
	const [editRuleConfirmRules, setEditRuleConfirmRules] = useState();
	// a mentes soran megerositheto szabalyok dokumentumait megjelenito komponens tartalma
	const [editRulesConfirmDocs, setEditRulesConfirmDocs] = useState();
	
	courts = courts || [];

	const onSubmit = () => {
		if (!judge || !judge.trim().length) {
			enqueueSnackbar( 'Nem adtál meg helyes bírónevet', {variant: "warning"} );
			return;
		}
		if ( judge === rule.synonym ) {
			enqueueSnackbar( 'A helyes bírónév nem lehet azonos a lecserélendő szöveggel, ahhoz a Jóváhagyás gombra kell kattintani', {variant: "warning"} );
			return;
		}
		rule={ courtId: courtId, judge: judge, id:rule.id, comment:newComment, ...getValues() };
		setBackdrop(true);
		const request = {
			url: saveUrl.replace( "%id", rule.id ),
			method: 'POST',
			data: rule
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				if ( resp.data.relatedRules && resp.data.relatedRules.length ) {
					// ha van relateddocs, akkor megerosites szukseges
					if ( resp.data.relatedDocs ) {
						setEditRuleConfirmRules( resp.data.relatedRules );
						setEditRulesConfirmDocs( resp.data.relatedDocs );
						setOpenConfirmDialog( true );
					} else {
						setEditRuleRelatedRules( resp.data.relatedRules );
						setOpenEditFailsDialog( true );
					}
				} else {
					enqueueSnackbar( 'Új javító-szabály: "' + rule.synonym + '"->"' + judge + '" sikeresen mentve', {variant: "success"} );
					setBackdrop( false );
					closeRefreshDialog();
				}
			})
			.catch(handleError);
	};

	const confirmEdit = () => {
		rule={ courtId: courtId, judge: judge, id:rule.id, ...getValues(), forced: true };
		setBackdrop(true);
		const request = {
			url: saveUrl,
			method: 'POST',
			data: rule,
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				enqueueSnackbar( 'Új javító-szabály: "' + rule.synonym + '"->"' + judge + '" sikeresen mentve', {variant: "success"} );
				closeRefreshDialog();
				}
			)
			.catch(handleError);
	};

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const handleEditFailsDialogClose = () => {
		setOpenEditFailsDialog(false);
		enqueueSnackbar('Hiba: elírás javító szabály mentés sikertelen', {variant: "error"});
	}

	const closeConfirmDialog = () => {
		setOpenConfirmDialog(false);
	}

	const editDoc = (docId) => {
		window.open( '/bh/editor/' + docId , '_blank' );
	}

	return (
	<div>
		<form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
			<DialogTitle>
				{header}
			</DialogTitle>

			<DialogContent className={classes.dialogGrid}>
				{preText}
				<br/><br/>
				<Grid container spacing={3} justify="center" alignItems="center">
					<Grid item xs={3}>
						<TextField
							name="synonym"
							variant="outlined"
							size="small"
							label="Elírás"
							fullWidth={true}
							disabled={true}
							value={rule.synonym}
							inputRef={register}
							InputProps={{
								className: classes.input
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						{/*<TextField
							name="judge"
							variant="outlined"
							size="small"
							fullWidth={true}
							label="helyes bírónév..."
							defaultValue={rule.synonym}
							inputRef={register}
							InputProps={{
								className: classes.input
							}} 
						/> */}
						<Autocomplete
							id="combo-box-demo"
							size="small"
							options={judges}
							freeSolo={true}
							autoSelect={true}
							defaultValue={rule.judge ? rule.judge : rule.synonym}
							onChange={(event, value) => setJudge(value)}
							renderInput={(params) => <TextField {...params} label="Helyes Bírónév" variant="outlined"/> }
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							id="combo-box-demo"
							size="small"
							options={ courts }
							getOptionLabel={(option) => option.value}
							defaultValue={ courts.find(element => element.key === rule.courtId) }
							onChange={(event, value) => setCourtId(value?value.key:null)}
							renderInput={(params) => <TextField {...params} label="Bíróság" variant="outlined"/> }
						/>
					</Grid>
					<Grid item xs={2}>
						<Autocomplete
							id="combo-box-demo3"
							options={preparedComments}
							size="small"
							freeSolo={true}
							autoSelect={true}
							onChange={(event, value) => setNewComment(value)}
							renderInput={(params) => <TextField {...params} label="Megjegyzés" variant="outlined"/> }
							/>
					</Grid>
				</Grid>
				<br/>
				{ ruleRelatedDocs &&
				<div>
					<Typography variant="body1">A(z) '{rule.synonym}' elírás az alábbi dokumentum(ok)hoz kapcsolódik:</Typography>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell align="right" className={classes.indexCol}>#</TableCell>
								<TableCell>Szerkesztés</TableCell>
								<TableCell align="right">ID</TableCell>
								<TableCell>FaCím1</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{ruleRelatedDocs?.map((doc, index) => (
								<TableRow key={doc.id}>
									<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
									<TableCell>
										<IconButton aria-label="create" title="Szerkesztés" onClick={() => editDoc(doc.id)} >
											<Icon fontSize="small">create</Icon>
										</IconButton>
									</TableCell>
									<TableCell align="right">{doc.id}</TableCell>
									<TableCell>{doc.title1}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
				}
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog} color="primary" autoFocus={true}>
					Mégsem
				</Button>
				<Button
					variant="contained"
					color="primary"
					type="submit"
					className={classes.redButton}
					startIcon={<Icon>saveIcon</Icon>}
				>
					{saveButtonText}
				</Button>
			</DialogActions>
		</form>
		
		<Dialog open={openEditFailsDialog} onClose={() => setOpenEditFailsDialog(false)} fullWidth maxWidth="lg">
			<DialogTitle id="customized-dialog-title" onClose={() => setOpenEditFailsDialog(false)}>
				Szabály ütközés
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">Az elírást javító '{rule.synonym}' -> '{judge}' szabály az alábbi szabályokkal ütközik, 
					ezért nem menthető el!</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>Bíróság neve</TableCell>
							<TableCell>Szabály</TableCell>
							<TableCell>Létrehozás</TableCell>
							<TableCell>Módosítás</TableCell>
							<TableCell>Utolsó módosító</TableCell>
							<TableCell>Megjegyzés</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{editRuleRelatedRules?.map((rule, index) => (
							<TableRow key={rule.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{rule.id}</TableCell>
								<TableCell>{rule.courtName}</TableCell>
								<TableCell>{rule.ruleText}</TableCell>
								<TableCell>{rule.createDate}</TableCell>
								<TableCell>{rule.modifiedDate}</TableCell>
								<TableCell>{rule.username}</TableCell>
								<TableCell>{rule.comment}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleEditFailsDialogClose} color="primary" autoFocus className={classes.button} variant="contained">
					OK
				</Button>
			</DialogActions>
		</Dialog>

		<Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)} fullWidth maxWidth="lg">
			<DialogTitle id="customized-dialog-title" onClose={() => setOpenConfirmDialog(false)}>
				Megerősítés
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">Mindegyik lenti dokumentumra vonatkozóan megtörténjen a módosítás?</Typography>
				<br/><br/>
				<Typography variant="body1">Az elírást javító '{rule.synonym}' -> '{judge}' szabály az alábbi Várólistás szabályokkal ütközik, 
				melyek az lenti dokumentumokbeli bíróneveket is módosítják.</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>Bíróság neve</TableCell>
							<TableCell>Szabály</TableCell>
							<TableCell>Létrehozás</TableCell>
							<TableCell>Módosítás</TableCell>
							<TableCell>Utolsó módosító</TableCell>
							<TableCell>Megjegyzés</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{editRuleConfirmRules?.map((rule, index) => (
							<TableRow key={rule.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{rule.id}</TableCell>
								<TableCell>{rule.courtName}</TableCell>
								<TableCell>{rule.ruleText}</TableCell>
								<TableCell>{rule.createDate}</TableCell>
								<TableCell>{rule.modifiedDate}</TableCell>
								<TableCell>{rule.username}</TableCell>
								<TableCell>{rule.comment}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<br/>
				<Typography variant="body1">A fenti szabályok az alábbi dokumentumokhoz kapcsolódóan módosítják a bírónevet:</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>FaCím1</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{editRulesConfirmDocs?.map((doc, index) => (
							<TableRow key={doc.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{doc.id}</TableCell>
								<TableCell>{doc.title1}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeConfirmDialog} color="primary" autoFocus={true}>
					Mégsem
				</Button>
				<Button onClick={confirmEdit} color="primary" className={classes.redButton} variant="contained">
					Csere-szabály bevezetésének megerősítése
				</Button>
			</DialogActions>
		</Dialog>

		<Backdrop className={classes.backdrop} open={backdrop}>
			<CircularProgress color="inherit"/>
		</Backdrop>
	</div>
	);
};