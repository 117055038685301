import React, {useState} from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Alert from "@material-ui/lab/Alert";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

import axios from "axios";
import {useForm} from "react-hook-form";


const useStyles = makeStyles((theme) => ({
    dialogGrid: {
        flexGrow: 1,
    },
    col80: {
        width: 80,
    },
}));

export default function AddDocDialog({id, closeDialog, addDocuments}) {
    const classes = useStyles();

    const {register, handleSubmit, getValues} = useForm({
        defaultValues: {
            docList: ''
        },
    });
    const [list, setList] = useState(null);

    const onSubmit = () => {
        let docs = [];
        list.forEach(doc => {
            if (!doc.conflict && docs.indexOf(doc.id) < 0) {
                docs.push(doc.id);
            }
        });

        addDocuments(docs);

        closeDialog();
    };

    const addButton = () => {
        const docList = (getValues().docList || '').trim();
        if (docList.length === 0) return;

        const request = {
            url: '/api/campaign/docs',
            method: 'POST',
            data: {
                id: id,
                docs: docList,
                test: true,
            },
        };
        axios(request)
            .then(resp => {
                setList(resp.data);
            });
    };

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

            <DialogTitle onClose={closeDialog}>
                Dokumentumok hozzáadása a kampányhoz
            </DialogTitle>

            <DialogContent className={classes.dialogGrid}>

                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Alert severity="info">
                            Adja meg a hozzáadandó dokumentumok listáját bármilyen karakterrel elválasztva.<br/>
                            A Hozzáadás gomb csak a nem ütközö dokumentumokat adja hozzá a Kampányhoz.
                        </Alert>
                    </Grid>

                    <Grid item xs={10}>
                        <TextField
                            name="docList"
                            label="Dokumentum azonosítók"
                            inputRef={register()}
                            variant="outlined"
                            fullWidth
                            autoFocus/>
                    </Grid>

                    <Grid item xs={2}>
                        <Button
                            onClick={addButton}
                            variant="contained"
                            color="default"
                            startIcon={<PlaylistAddIcon/>}
                            fullWidth
                        >
                            Teszt
                        </Button>
                    </Grid>
                    {
                        list ? <Grid item xs={12}>
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right" className={classes.col80}>#</TableCell>
                                            <TableCell align="right" className={classes.col80}>ID</TableCell>
                                            <TableCell>FaCím1</TableCell>
                                            <TableCell>FaCím2</TableCell>
                                            <TableCell>Ütközik</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list.map((doc, index) => (
                                            <TableRow key={doc.id}>
                                                <TableCell align="right">{index + 1}.</TableCell>
                                                <TableCell align="right">{doc.id}</TableCell>
                                                <TableCell>{doc.title1}</TableCell>
                                                <TableCell>{doc.title2}</TableCell>
                                                <TableCell>{doc.conflict ? <SentimentVeryDissatisfiedIcon/> : null}</TableCell>
                                            </TableRow>
                                        ))}
                                        {list.length === 0
                                            ? <TableRow><TableCell colSpan={5}>Nincs megjeleníthető dokumentum</TableCell></TableRow>
                                            : null}
                                    </TableBody>
                                </Table>
                            </Grid>
                            : null
                    }
                </Grid>

            </DialogContent>

            <DialogActions>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<Icon>add</Icon>}
                    disabled={!list || list.length === 0}
                >
                    Hozzáadás és Bezárás
                </Button>
                <Button
                    variant="contained"
                    startIcon={<Icon>cancel</Icon>}
                    onClick={closeDialog}
                >
                    Mégse
                </Button>

            </DialogActions>
        </form>
    );
}
