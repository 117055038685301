import {red} from '@material-ui/core/colors';
import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme( {
  palette: {
    primary: {
      main: '#01579b',
    },
    secondary: {
      main: '#2196f3',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#eee',
    },
  },
} );

export default theme;