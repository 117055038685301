import React, {useState} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import {
	Backdrop,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {useSnackbar} from 'notistack';
import {preparedComments} from "../../utils";

const useStyles = makeStyles( ( theme ) => ({
	redButton: {
		color: '#ffffff',
		backgroundColor: '#ff0000',
		'&:hover': {
			backgroundColor: '#aa0000'
		}
	},
	dialogGrid: {
		flexGrow: 1,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

// a feluletnek az megjelenesi allapotai lehetsegesek:
// - kiindulo allapot: a kapott szabalybeli nev (inaktiv), szerkesztheto bironev (legorduloben a valid nevek), datumok es komment mezok megjelenitese, 
// - megerosites allapot: a menteskor visszakapott azonos bironev es dokumentumainak listazasa
export default function ValidJudgeEditDialog({rule, courts=[], judges, closeDialog, closeRefreshDialog, saveUrl, ruleRelatedDocs, 
		header='Valid bírónév szerkesztése', saveButtonText='Módosítás mentése',
		preText='Ezen a felületen helyes bíróneveket lehet szerkeszteni. A név módosítása a lenti dokumentumok meta-adataiban is megtörténik.'}) {
	const classes = useStyles();
	// a megjegyzes Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott/megadott szoveget
	const [newComment, setNewComment] = useState( rule.comment );
	// a bironev Autocomplete onChange-je ebbe allitja be az aktualisan kivalasztott nevet (a megadott rule.synonym-rol indul)
	const [judge, setJudge] = useState( rule.judge ? rule.judge : rule.synonym );
	// a kivalasztott birosag azonositoja
	const [newCourtId, setNewCourtId] = useState(rule.courtId ? courts.find(element => element.key === rule.courtId) : null);
	// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// a szerver-muveletek idejere a feluletet inaktivra allitjuk
	const [backdrop, setBackdrop] = React.useState(false);
	// a mentest akadalyozo utkozo szabalyokat megjelenito komponenst megjelenitesere hasznalt allapotok
	const [openEditFailsDialog, setOpenEditFailsDialog] = useState(false);
	// a mentest akafalyozo szabalyokat megjelenito komponens tartalma
	const [editRuleRelatedRules, setEditRuleRelatedRules] = useState();
	// a mentes megerosito ablakon szabalyokat es dokumentumokat megjelenito komponenst lathatosagara hasznalt allapotok
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	// a mentes megerosito ablakon a szabalyt megjelenito komponens tartalma
	const [editRuleConfirmRules, setEditRuleConfirmRules] = useState();
	// a mentes megerosito ablakon a szabaly dokumentumait megjelenito komponens tartalma
	const [editRuleConfirmDocs, setEditRuleConfirmDocs] = useState();

	const onSubmit = () => {
		if (!judge || !judge.trim().length) {
			enqueueSnackbar( 'A bírónév nem lehet üres', {variant: "warning"} );
			return;
		}
		const courtId = newCourtId ? newCourtId.key : null;
		if ( judge && rule.judge === judge && rule.comment === newComment && courtId === rule.courtId ) {
			enqueueSnackbar('A bírónév, a bíróság és a megjegyzés is változatlan!', {variant: "warning"});
			return;
		}
		if ( judge.trim().indexOf( ' ' ) < 0 ) {
			enqueueSnackbar('Nem megengedett az egyszavas bírónév!', {variant: "warning"});
			return;
		}
		rule={ id:rule.id, judge: judge, courtId: courtId, comment:newComment };
		setBackdrop(true);
		const request = {
			url: saveUrl.replace( "%id", rule.id ),
			method: 'POST',
			data: rule
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				if ( resp.data.relatedRules && resp.data.relatedRules.length ) {
					// ha van relateddocs (akkor is ha ures), az azt jelzi, hogy megerosites szukseges
					if ( resp.data.relatedDocs ) {
						setEditRuleConfirmRules( resp.data.relatedRules );
						setEditRuleConfirmDocs( resp.data.relatedDocs );
						setOpenConfirmDialog( true );
					} else {
						setEditRuleRelatedRules( resp.data.relatedRules );
						setOpenEditFailsDialog( true );
					}
				} else {
					enqueueSnackbar( 'Bírónév módosítás: "' + rule.judge + "' => '" + judge + '" sikeresen végrehajtva', {variant: "success"} );
					setBackdrop( false );
					closeRefreshDialog();
				}
			})
			.catch(handleError);
	};

	const confirmEdit = () => {
		rule={ id:rule.id, judge: judge, comment:newComment, forced:true };
		setBackdrop(true);
		const request = {
			url: saveUrl.replace( "%id", rule.id ),
			method: 'POST',
			data: rule,
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				enqueueSnackbar( 'Bírónév módosítás már meglévő névre: "' + rule.judge + '"->"' + judge + '" sikeresen végrehajtva', {variant: "success"} );
				closeRefreshDialog();
				}
			)
			.catch(handleError);
	};

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const handleEditFailsDialogClose = () => {
		setOpenEditFailsDialog(false);
		enqueueSnackbar('Hiba: Bírónév módosítás sikertelen', {variant: "error"});
	}

	const closeConfirmDialog = () => {
		setOpenConfirmDialog(false);
	}

	return (
	<div>
		<DialogTitle>
			{header}
		</DialogTitle>

		<DialogContent className={classes.dialogGrid}>
			{preText}
			<br/><br/>
			<Grid container spacing={3} justify="center" alignItems="center">
				<Grid item xs={4}>
					<Autocomplete
						id="combo-box-demo"
						size="small"
						options={judges}
						freeSolo={true}
						autoSelect={true}
						defaultValue={rule.judge}
						onChange={(event, value) => setJudge(value)}
						renderInput={(params) => <TextField {...params} label="Bírónév" variant="outlined"/> }
					/>
				</Grid>
				<Grid item xs={2}>
						{courts ? <Autocomplete
							id="combo-box-demo"
							options={courts}
							size="small"
							getOptionLabel={(option) => option.value}
							onChange={(event, value) => setNewCourtId(value)}
							defaultValue={ rule.courtId ? courts.find(element => element.key === rule.courtId) : null }
							renderInput={(params) => <TextField {...params} label="Bíróság" variant="outlined"/> }
							/>
						: null }
				</Grid>
				<Grid item xs={1}>
					<TextField
						name="created"
						variant="outlined"
						size="small"
						disabled={true}
						fullWidth={true}
						label="Létrehozás dátuma"
						value={rule.createDate}
						InputProps={{
							className: classes.input
						}}
					/>
				</Grid>
				<Grid item xs={1}>
					<TextField
						name="modify"
						variant="outlined"
						size="small"
						disabled={true}
						fullWidth={true}
						label="Módosítás dátuma"
						value={rule.modifiedDate}
						InputProps={{
							className: classes.input
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						name="username"
						variant="outlined"
						size="small"
						disabled={true}
						fullWidth={true}
						label="Utolsó módosító"
						value={rule.username}
						InputProps={{
							className: classes.input
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<Autocomplete
						id="combo-box-demo3"
						options={preparedComments}
						size="small"
						defaultValue={rule.comment}
						freeSolo={true}
						autoSelect={true}
						onChange={(event, value) => setNewComment(value)}
						renderInput={(params) => <TextField {...params} label="Megjegyzés" variant="outlined"/> }
					/>
				</Grid>
			</Grid>
			<br/>
			{ !ruleRelatedDocs &&
				<Typography variant="body1">A(z) '{rule.judge}' bírónév nem szerepel egy dokumentum meta-adataiban sem.
					Lehetséges hogy az az oka, hogy a dokumentumok meta-adataiban a csak kis-nagybetűben eltérő bírónevek
					nincsenek megkülönböztetve, és csak az másik névhez kerülnek listázásra.
				</Typography>
			}
			{ ruleRelatedDocs &&
			<div>
				<Typography variant="body1">A(z) '{rule.judge}' bírónév az alábbi dokumentum(ok) meta-adataiban szerepel:</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>FaCím1</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ruleRelatedDocs?.map((doc, index) => (
							<TableRow key={doc.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{doc.id}</TableCell>
								<TableCell>{doc.title1}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			}
		</DialogContent>
		<DialogActions>
			<Button onClick={closeDialog} color="primary" autoFocus={true}>
				Mégsem
			</Button>
			<Button
				variant="contained"
				color="primary"
				type="submit"
				onClick={onSubmit}
				className={classes.redButton}
				startIcon={<Icon>saveIcon</Icon>}
			>
				{saveButtonText}
			</Button>
		</DialogActions>

		<Dialog open={openEditFailsDialog} onClose={() => setOpenEditFailsDialog(false)} fullWidth maxWidth="lg">
			<DialogTitle id="customized-dialog-title" onClose={() => setOpenEditFailsDialog(false)}>
				Szabály ütközés
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">A bírónév módosítása '{rule.judge}' -> '{judge}' szabály az alábbi szabályokkal ütközik, 
					ezért nem menthető el!</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>Bíróság neve</TableCell>
							<TableCell>Szabály</TableCell>
							<TableCell>Létrehozás</TableCell>
							<TableCell>Módosítás</TableCell>
							<TableCell>Utolsó módosító</TableCell>
							<TableCell>Megjegyzés</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{editRuleRelatedRules?.map((rule, index) => (
							<TableRow key={rule.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{rule.id}</TableCell>
								<TableCell>{rule.courtName}</TableCell>
								<TableCell>{rule.ruleText}</TableCell>
								<TableCell>{rule.createDate}</TableCell>
								<TableCell>{rule.modifiedDate}</TableCell>
								<TableCell>{rule.username}</TableCell>
								<TableCell>{rule.comment}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleEditFailsDialogClose} color="primary" autoFocus className={classes.button} variant="contained">
					OK
				</Button>
			</DialogActions>
		</Dialog>

		<Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)} fullWidth maxWidth="lg">
			<DialogTitle id="customized-dialog-title" onClose={() => setOpenConfirmDialog(false)}>
				Megerősítés
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">A bírónév módosítása egy meglévő névre történik. Megtörténjen a módosítás?</Typography>
				<br/><br/>
				<Typography variant="body1">A bírónév '{rule.judge}' -> '{judge}'-re módosításával az aktuális név megszüntetésre kerül, 
				a kapcsolódó dokumentumaiban pedig az itt listázott dokumentumokban már használt '{judge}' bírónév kerül beállításra.</Typography>
				<br/><br/>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>Bíróság neve</TableCell>
							<TableCell>Szabály</TableCell>
							<TableCell>Létrehozás</TableCell>
							<TableCell>Módosítás</TableCell>
							<TableCell>Utolsó módosító</TableCell>
							<TableCell>Megjegyzés</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{editRuleConfirmRules?.map((rule, index) => (
							<TableRow key={rule.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{rule.id}</TableCell>
								<TableCell>{rule.courtName}</TableCell>
								<TableCell>{rule.ruleText}</TableCell>
								<TableCell>{rule.createDate}</TableCell>
								<TableCell>{rule.modifiedDate}</TableCell>
								<TableCell>{rule.username}</TableCell>
								<TableCell>{rule.comment}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<br/>
				<Typography variant="body1">A '{judge}' bírónév már az alábbi dokumentumokban szerepel:</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="right" className={classes.indexCol}>#</TableCell>
							<TableCell align="right">ID</TableCell>
							<TableCell>FaCím1</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{editRuleConfirmDocs?.map((doc, index) => (
							<TableRow key={doc.id}>
								<TableCell align="right" className={classes.indexCol}>{index + 1}.</TableCell>
								<TableCell align="right">{doc.id}</TableCell>
								<TableCell>{doc.title1}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeConfirmDialog} color="primary" autoFocus={true}>
					Mégsem
				</Button>
				<Button onClick={confirmEdit} color="primary" className={classes.redButton} variant="contained">
					Bírónév módosítás megerősítése
				</Button>
			</DialogActions>
		</Dialog>

		<Backdrop className={classes.backdrop} open={backdrop}>
			<CircularProgress color="inherit"/>
		</Backdrop>
	</div>
	);
};