import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import CustomIframe from "./CustomIframe";

const useStyles = makeStyles((theme) => ({
  dialogGrid: {
  flexGrow: 1,
  textAlign: 'center',
  paddingTop: '0 !important',
  },
  changeButton: {
  marginBottom: '10px !important',
  }
}));
  export default function EmailViewDialog({ email, closeDialog }) {
  const classes = useStyles();
  // a betoltendo email-forras url-et tartalmazza
  const [contentLink, setContentLink] = React.useState( '/api/changemonemails/htmlcontent/' + email.id );
  // a megjelenitett email-forras tipusa
  const [contentType, setContentType] = React.useState( 'html' );
  // iframe megjeleniteshez hasznalt inline letrehozott komponens
  const changeView = () => {
  const newContentType = contentType === 'html' ? 'text' : 'html';
  setContentType( newContentType );
  setContentLink( '/api/changemonemails/' + newContentType + 'content/' + email.id );
  }
  return (
  <>
    <DialogTitle id="email-view-dialog-title">
        {email.userFullname} &lt;{email.emailAddress}&gt; - létrehozva: {email.createDate}, {email.sendDate==null?'még nincs elküldve':'elküldve: '+email.sendDate}
    </DialogTitle>
    <DialogContent className={classes.dialogGrid}>
        <Button className={classes.changeButton} variant="contained" color="secondary" onClick={changeView}>{contentType==='html' ? 'Váltás szöveges változatra' : 'Váltás HTML változatra'}</Button>
        <CustomIframe src={contentLink} width='100%' height='500px'>
        </CustomIframe>
    </DialogContent>
    <DialogActions>
        <Button onClick={closeDialog} color="primary" autoFocus={true}>
          Bezárás
        </Button>
    </DialogActions>
  </>
  );
};