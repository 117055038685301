import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import { 
	Backdrop,
	CircularProgress,
	Icon,
	IconButton,
	Dialog,
	Paper,
	Typography } from "@material-ui/core";
import NotInterested from '@material-ui/icons/NotInterested';
import {useSnackbar} from 'notistack';
import Loader from "../../components/Loader";
import MultiSelectTableToolbar from "../../components/MultiSelectTableToolbar";
import axios from "axios";
import {customRowIndexColumn} from "../../utils";
import MUIDataTable from "mui-datatables";
import JudgeSynonymEditDialog from "./JudgeSynonymEditDialog";
import InvalidRuleToJudgeRuleDialog from "./InvalidRuleToJudgeRuleDialog";
import InvalidRuleToBlacklistRuleDialog from "./InvalidRuleToBlacklistRuleDialog";
import InvalidRuleDeleteDialog from "./InvalidRuleDeleteDialog";
import InvalidRuleMultiDeleteDialog from "./InvalidRuleMultiDeleteDialog";

const useStyles = makeStyles( ( theme ) => ({
	toolbar: {
		padding: "1em",
		margin: "1em 0"
	},
	panel: {
		marginBottom: '1em'
	},
	table: {
		"& td": {
		padding: "6px"
		}
	},
	buttonCol: {
		width: "80px",
		padding: "6px"
	},
	typeSelect: {
		width: "100%",
		"& em": {
		  color: "gray"
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}) );


const fixDocumentColumns = [
			customRowIndexColumn(),
	{name: 'id', label: 'ID', options: {sort: true, filter: false, searchable: false}},
	{name: 'name', label: 'Jóváhagyásra váró szöveg', options: {sort: true, filter:false, searchable: true}},
	{name: 'courtName', label: 'Bíróság neve', options: {sort: true, filter: false, searchable: false}},
	{name: 'createDate', label: 'Létrehozás dátuma', options: {sort: true, filter: true, searchable: false, filterType: 'textField'}},
	];
	
export default function  InvalidJudges() {
	const classes = useStyles();

	// a muveletek sikertelen/sikeres kimenetelet szovegesen mutato resz
	const {enqueueSnackbar} = useSnackbar();
	// varolistas szabalyok, a fooldalon tablazatban jelennek meg
	const [documents, setDocuments] = useState();
	// progress jelzes a lassu muveletekhez
	const [backdrop, setBackdrop] = useState(false);
	// birosagok listaja, az egyes dialogusoknak parameterkent atadando birosagok kiindulo adatszerkezete
	const [courts, setCourts] = useState([]);
	// helyes bironevek, az egyes dialogusoknak parameterkent atadando adatszerkezete
	const [judges, setJudges] = useState([]);
	// a muvelethez tartozo szabaly, dialogusoknak parameterkent atadando adatszerkezet
	const [rule, setRule] = useState({});
	// tobb szabaly egyuttes torlesekor szerverrol erkezo szabalyok, a tobb dokumentum torleset vegzo dialogusnak parameterkent atadando adatszerkezet
	const [rules, setRules] = useState({});
	// szabaly(ok) muveleteiben szerverrol erkezo kapcsolodo dokumentumok, dialogusok parameterkent atadando adatszerkezet
	const [ruleRelatedDocs, setRuleRelatedDocs] = useState([]);
	// szabalybol csere-szabalyt keszito dialogus lathatosaga
	const [openEditReplaceRuleDialog, setEditReplaceRuleDialog] = useState(false);
	// bironev jovahagyast kezelo dialogus lathatosaga
	const [openAckJudgeRuleDialog, setAckJudgeRuleDialog] = useState(false);
	// szabalybol feketelista-szabalyt keszito dialogus lathatosaga
	const [openBlacklistRuleDialog, setBlacklistRuleDialog] = useState(false);
	// egy szabaly torleset vegzo dialogus lathatosaga
	const [openDeleteRuleDialog, setDeleteRuleDialog] = useState(false);
	// tobb szabaly egyuttes torleset vegzo dialogus lathatosaga
	const [openDeleteListRuleDialog, setDeleteListRuleDialog] = useState(false);
	// tablazat kijelolt soraihoz tartozo szabalyokat tarolo valtozo, azert csak gomb-nyomaskor allitjuk be a selectedRules-be hogy ne lassitson
	const [tableRowsSelected, setTableRowsSelected] = useState([]);

	const showEditReplaceRuleDialog = () => setEditReplaceRuleDialog(true);
	const closeEditReplaceRuleDialog = () => {
		setEditReplaceRuleDialog(false);
	};
	const closeEditReplaceRuleDialogRefresh = () => {
		setEditReplaceRuleDialog(false);
		refresh(true);
	};

	const showAckJudgeRuleDialog = () => setAckJudgeRuleDialog(true);
	const closeAckJudgeRuleDialog = () => {
		setAckJudgeRuleDialog(false);
	};
	const closeAckJudgeRuleDialogRefresh = () => {
		setAckJudgeRuleDialog(false);
		refresh(true);
	};

	const showBlacklistRuleDialog = () => setBlacklistRuleDialog(true);
	const closeBlacklistRuleDialog = () => {
		setBlacklistRuleDialog(false);
	};
	const closeBlacklistRuleDialogRefresh = () => {
		setBlacklistRuleDialog(false);
		refresh(true);
	};

	const showDeleteRuleDialog = () => setDeleteRuleDialog(true);
	const closeDeleteRuleDialog = () => {
		setDeleteRuleDialog(false);
	};
	const closeDeleteRuleDialogRefresh = () => {
		setDeleteRuleDialog(false);
		refresh(true);
	};

	const showDeleteListRuleDialog = () => setDeleteListRuleDialog(true);
	const closeDeleteListRuleDialog = () => {
		setDeleteListRuleDialog(false);
	};
	const closeDeleteListRuleDialogRefresh = () => {
		setDeleteListRuleDialog(false);
		refresh(true);
	};

	const refresh = (needBackdrop) => {
		if ( needBackdrop ){
			setBackdrop(true);
		}
		axios( "/api/invalidjudges" ).then( resp => {
			if ( needBackdrop ){
				setBackdrop(false);
			}
			setCourts( resp.data.courts );
			setJudges( resp.data.judges );
			setTableRowsSelected([]);
			setDocuments( resp.data.list );
		} );
	}

	useEffect( () => {
		refresh(false);
	}, [ ] );

	const editName = (id) => {
		setBackdrop(true);
		const request = {
			url: '/api/invalidjudges/' + id + '/replacerule',
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				// a szerver valaszban vannak a kapcsolodo dokumentumok, megjelenitjuk a csereszabaly-szerkeszto ablakban
				setRule( resp.data.rule );
				setRuleRelatedDocs( resp.data.related );
				showEditReplaceRuleDialog();
			})
			.catch(handleError);
	};

	const acknowledgeName = (id) => {
		setBackdrop(true);
		const request = {
			url: '/api/invalidjudges/' + id + '/judgerule',
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				// a szerver valaszban vannak a kapcsolodo dokumentumok, megjelenitjuk a bironev-jovahagyas ablakban
				setRule( resp.data.rule );
				setRuleRelatedDocs( resp.data.related );
				showAckJudgeRuleDialog();
			})
			.catch(handleError);
	}

	const deleteName = (id) => {
		setBackdrop(true);
		const request = {
			url: '/api/invalidjudges/' + id + '/deleterule',
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				// a szerver valaszban vannak a kapcsolodo dokumentumok, megjelenitjuk a torles-jovahagyas ablakban
				setRule( resp.data.rule );
				setRuleRelatedDocs( resp.data.related );
				showDeleteRuleDialog();
			})
			.catch(handleError);
	}

	const deleteRules = () => {
		setBackdrop(true);
		let ruleIds = tableRowsSelected.map( i => documents[ i ].id );
		const request = {
			url: '/api/invalidjudges/' + ruleIds.join(',') + '/deleterules',
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				// a szerver valaszban vannak a kapcsolodo dokumentumok, megjelenitjuk a torles-jovahagyas ablakban
				setRules( resp.data.rules );
				setRuleRelatedDocs( resp.data.related );
				showDeleteListRuleDialog();
			})
			.catch(handleError);
	}

	const blacklistName = (id, isForced) => {
		setBackdrop(true);
		const request = {
			url: '/api/invalidjudges/' + id + '/blacklistrule',
			method: 'GET',
		};
		axios(request)
			.then(resp => {
				setBackdrop(false);
				// a szerver valaszban vannak a kapcsolodo dokumentumok, megjelenitjuk a szerkeszto-ablakban
				setRule( resp.data.rule );
				setRuleRelatedDocs( resp.data.related );
				showBlacklistRuleDialog();
			})
			.catch(handleError);
	}

	const documentColumns = [
		{
			name: "id",
			label: "Törlés",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<IconButton aria-label="delete" title="Törlés" onClick={() => deleteName(value)}>
							<Icon fontSize="small">delete</Icon>
						</IconButton>
					);
				}
			}
		},
		{
			name: "id",
			label: "Feketelistára",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<IconButton aria-label="blacklise" title="Feketelistára" onClick={() => blacklistName(value, false)}>
							<NotInterested/>
						</IconButton>
					);
				}
			}
		},
		{
			name: "id",
			label: "Jóváhagyás",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<IconButton aria-label="save" title="Jóváhagyás" onClick={() => acknowledgeName(value)} >
							<Icon fontSize="small">save</Icon>
						</IconButton>
					);
				}
			}
		},
		{
			name: "id",
			label: "Elírás javítása",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<IconButton aria-label="create" title="Elírás javítása" onClick={() => editName(value)} >
							<Icon fontSize="small">create</Icon>
						</IconButton>
					);
				}
			}
		},
		...fixDocumentColumns
	];

	const handleError = (error) => {
		setBackdrop(false);
		enqueueSnackbar('Hiba történt...', {variant: "error"});
		console.log(error);
	}

	const options = {
		filter: true,
		selectableRows: 'multiple',
		filterType: 'dropdown',
		responsive: 'vertical',
        rowsPerPageOptions: [10, 50, 100, 1000],
		rowsSelected: tableRowsSelected,
		customToolbarSelect: () => {
			return (
				<MultiSelectTableToolbar handleClick={deleteRules}/>
			);
		},
		onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
			setTableRowsSelected( rowsSelected );
		}
	};

	return (
		<div>
			<Typography variant="h4">Jóváhagyásra váró bírónevek</Typography>
			{documents ? null : <Loader/>}

			<Dialog
				open={openEditReplaceRuleDialog}
				onClose={closeEditReplaceRuleDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<JudgeSynonymEditDialog 
					rule={rule}
					courts={[courts.find(element => element.key === rule.courtId)]}
					judges={judges}
					closeDialog={closeEditReplaceRuleDialog}
					closeRefreshDialog={closeEditReplaceRuleDialogRefresh}
					ruleRelatedDocs={ruleRelatedDocs}
					saveUrl='/api/invalidjudges/%id/replacerule'/>
			</Dialog>

			<Dialog
				open={openAckJudgeRuleDialog}
				onClose={closeAckJudgeRuleDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<InvalidRuleToJudgeRuleDialog
					rule={rule}
					courts={[courts.find(element => element.key === rule.courtId)]}
					closeDialog={closeAckJudgeRuleDialog}
					closeRefreshDialog={closeAckJudgeRuleDialogRefresh}
					ruleRelatedDocs={ruleRelatedDocs}
					saveUrl='/api/invalidjudges/%id/judgerule'
					courtOnlyNullable="true"/>
			</Dialog>

			<Dialog
				open={openBlacklistRuleDialog}
				onClose={closeBlacklistRuleDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<InvalidRuleToBlacklistRuleDialog
					rule={rule}
					courts={[courts.find(element => element.key === rule.courtId)]}
					closeDialog={closeBlacklistRuleDialog}
					closeRefreshDialog={closeBlacklistRuleDialogRefresh}
					ruleRelatedDocs={ruleRelatedDocs}
					saveUrl='/api/invalidjudges/%id/blacklistrule'/>
			</Dialog>

			<Dialog
				open={openDeleteRuleDialog}
				onClose={closeDeleteRuleDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<InvalidRuleDeleteDialog
					rule={rule}
					courts={[courts.find(element => element.key === rule.courtId)]}
					closeDialog={closeDeleteRuleDialog}
					closeRefreshDialog={closeDeleteRuleDialogRefresh}
					ruleRelatedDocs={ruleRelatedDocs}
					saveUrl='/api/invalidjudges/%id'/>
			</Dialog>

			<Dialog
				open={openDeleteListRuleDialog}
				onClose={closeDeleteListRuleDialog}
				fullWidth
				maxWidth="xl"
				scroll="paper"
				disableBackdropClick={true}
				aria-labelledby="ce-rule-dlg-title"
				aria-describedby="ce-rule-dlg-content"
			>

				<InvalidRuleMultiDeleteDialog
					rules={rules}
					closeDialog={closeDeleteListRuleDialog}
					closeRefreshDialog={closeDeleteListRuleDialogRefresh}
					rulesRelatedDocs={ruleRelatedDocs}
					saveUrl="/api/invalidjudges/list"
					/>
			</Dialog>

			<Paper elevation={3} className={classes.table}>
					<MUIDataTable
						data={documents || []}
						columns={documentColumns}
						options={options}
						/>
			</Paper>

			<Backdrop className={classes.backdrop} open={backdrop}>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</div>
	);
};
