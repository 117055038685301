import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";

import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Icon,
  IconButton,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Dialog
} from "@material-ui/core";
import { useSnackbar } from 'notistack';
import DateEditDialog from "./DateEditDialog";

const useStyles = makeStyles((theme) => ({
  redButton: {
    color: '#ffffff',
    backgroundColor: '#ff0000',
    '&:hover': {
      backgroundColor: '#aa0000'
    }
  },
  root: {
    width: 900
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const SExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(ExpansionPanel);

const SExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(ExpansionPanelSummary);

const SExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(ExpansionPanelDetails);

export default function ChangeMonitoring() {
  const classes = useStyles();
  const [dirs, setDirs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  // progress jelzes a lassu muveletekhez
  const [backdrop, setBackdrop] = useState(false);
  // a szerkeszto ablak megjeleniteset szabalyzo flag
  const [editDialog, setEditDialog] = useState(false);
  // kivalasztott datum
  const [actDir, setActDir] = useState([]);
  // kivalasztott datum dokumentumai
  const [docs, setDocs] = useState([]);

  const showEditDialog = () => setEditDialog(true);
  const closeEditDialog = () => setEditDialog(false);;

  useEffect(() => {
    const loadFiles = async () => {
      const result = await axios("/api/changemon");
      const data = result.data;
      setDirs(data);
    };
    loadFiles();
  }
    , []);

  const approve = (diffDir) => {
    if (!window.confirm("Biztosan jóváhagyod?")) {
      return;
    }
    setBackdrop(true);
    axios.post('/api/changemon/approve/' + diffDir)
      .then(resp => {
        setBackdrop(false);
        setDirs(resp.data);
        enqueueSnackbar("Sikeresen jóváhagyva", { variant: 'success' });
      })
      .catch(handleError);
  };

  const genEmails = (diffDir) => {
    if (!window.confirm("Biztosan készíthetőek az email-ek?")) {
      return;
    }
    setBackdrop(true);
    axios.post('/api/changemonemails/generate/' + diffDir)
      .then(resp => {
        setBackdrop(false);
        if ( resp.data.entries && resp.data.entries.length > 0 )
        {
          enqueueSnackbar(resp.data.entries.length + "db email létrehozása sikeres", { variant: 'success', autoHideDuration: 5000 });
          // lefrissitjuk a tablazatot ugy, hogy a gomb email-listara valtozzon (nem az adatbazisbeli datum lesz megjelenitve, de lenyegtelen)
          const buttonDateTime = new Date();
          // az aktualis datumot olyan szoveges formatumra hozzuk, ahogy az adatbazisbol is erkezne
          const dateStr = buttonDateTime.getFullYear() + '-' + (( buttonDateTime.getMonth() < 9 ? '0' : '' ) + ( buttonDateTime.getMonth() + 1 ) )
              + '-' + ( ( buttonDateTime.getDate() < 10 ? '0' : '') + buttonDateTime.getDate() )
              + ' ' + ( ( buttonDateTime.getHours() < 10 ? '0' : '' ) + buttonDateTime.getHours() )
              + ':' + ( ( buttonDateTime.getMinutes() < 10 ? '0' : '') + buttonDateTime.getMinutes() )
              + ':' + ( ( buttonDateTime.getSeconds() < 10 ? '0' : '') + buttonDateTime.getSeconds() );
          // lemasoljuk a dirs objektumot, hogy modositani tudjuk
          let newDirs = JSON.parse( JSON.stringify( dirs ) );
          // bejarjuk az honapokat az objektumban, mindben keressuk a generalt datumot
          for ( let key in newDirs ) {
            // megkeressuk azt a napot, ami a legeneralt datume
            const found = newDirs[ key ].findIndex( ( element ) => element[ 'diffDate' ] === diffDir );
            // ha megtalaltuk, akkor modositjuk benne az email-datumot es kilepunk
            if ( found != -1 ){
              newDirs[ key ][ found ][ 'emailGenDate' ] = dateStr;
              break;
            }
          }
          // a modositott objektummal frissitjuk a tablazatot
          setDirs( newDirs );
          window.open( '/other/changemonitoringemails/' + diffDir , '_blank' );
        }
        else if ( resp.data.failed )
        {
          enqueueSnackbar("Email létrehozási hiba: " + resp.data.failed, { variant: 'error', autoHideDuration: 5000 } );
        }
        else
        {
          enqueueSnackbar("Email-ek nem lettek létrehozva, mert egyik dokumentumot sem figyelik", { variant: 'warning', autoHideDuration: 5000 } );
        }
      })
      .catch(handleError);
  };

  const jumpEmails = (diffDir) => {
    window.open( '/other/changemonitoringemails/' + diffDir , '_blank' );
  };

  const handleError = (error) => {
    setBackdrop(false);
    enqueueSnackbar('Hiba történt...', { variant: "error" });
    console.log(error);
  }

  const docCompatator = (doc1, doc2) => {
      let r = (doc2.valid & 1) - (doc1.valid & 1 ); // Elol legyenek az engedelyzettek
      if ( r !== 0 ) {
          return r;
      }
      r = (doc1.viewed & 1) - (doc2.viewed & 1 ); // Hatul legynek az atnezettek
      if ( r !== 0 ) {
          return r;
      }
      return doc1.id - doc2.id; // ID szerint novekvo
  };

  const edit = (dir) => {
    setBackdrop(true);
    const request = {
      url: '/api/changemon/' + dir.diffDir,
      method: 'GET',
    };
    axios(request)
      .then(resp => {
        setBackdrop(false);
        // a szerver valaszban vannak a dokumentumok, megjelenitjuk oket a dialogus ablakban
        setActDir(dir);
        resp.data.sort(docCompatator);
        setDocs(resp.data);
        showEditDialog();
      })
      .catch(handleError);
  }

  const docView = doc => {
    const request = {
        url: '/api/changemon/' + actDir.diffDir + '/viewed',
        method: 'POST',
        params: {
          id: doc.id,
          viewed: !doc.viewed
        }
    }
    axios(request)
      .then(resp => {
          const list = [...docs];
          list.forEach( doc => doc.viewed = resp.data.includes(doc.id) );
          list.sort(docCompatator);
          setDocs(list);
          enqueueSnackbar("Sikeres módosítás.", { variant: 'success' });
      })
      .catch(handleError)
  };

  if (!dirs) {
    return <Loader />
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Változásfigyelés - összegyűjtött napi adatbázis változások
      </Typography>
      {Object.keys(dirs).map((date) => (
        <SExpansionPanel key={date} defaultExpanded={true}>
          <SExpansionPanelSummary>{date}</SExpansionPanelSummary>
          <SExpansionPanelDetails>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Változás dátuma</TableCell>
                  <TableCell align="center">Közlönyök</TableCell>
                  <TableCell align="center">Vált figy.<br/>/ Összes dok.<br/> Hibás/Hiányzó lábj.</TableCell>
                  <TableCell align="center">Szerkesztés</TableCell>
                  <TableCell align="center">Jóváhagyás</TableCell>
                  <TableCell align="center">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dirs[date].map((dir) => (
                  <TableRow key={dir.diffDate}>
                    <TableCell align="center">
                      {
                        (dir.diffDir != null)
                          ? <a target="_blank" rel="noopener noreferrer" href={"https://web.jogkodex.hu/diff?date=" + dir.diffDir}>{dir.diffDate}</a>
                          : <span>{dir.diffDate}</span>
                      }
                    </TableCell>
                    <TableCell align="center">
                      {dir.gazettes && dir.gazettes.map(gazette => (
                        <div key={gazette}>{gazette}</div>
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      {dir.documents > 0 &&
                        <>
                          {dir.cmDocuments + ' / ' + dir.documents}
                          <br />
                          {dir.badFootnotes + ' / ' + dir.missingFootnotes}
                        </>
                      }
                    </TableCell>
                    <TableCell align="center">
                      {dir.diffDir != null &&
                        <IconButton aria-label="create" title="Szerkesztés" onClick={() => edit(dir)} >
                          <Icon fontSize="small">create</Icon>
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        (dir.approvedDate != null)
                          ? <span><b>Jóváhagyva: {dir.approvedDate}</b></span>
                          : (dir.diffDir == null)
                            ? <span>Nincs változás</span>
                            : <Button variant="contained" color="secondary" onClick={() => approve(dir.diffDir)}>Jóváhagyás</Button>
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        (dir.approvedDate == null)
                          ? <span>Még nincs jóváhagyva</span>
                          : (dir.emailGenDate != null)
                            ? <Button variant="contained" color="secondary" onClick={() => jumpEmails(dir.diffDir)}>Email-lista (létrehozva:{dir.emailGenDate})</Button>
                            : <Button variant="contained" color="secondary" className={classes.redButton} onClick={() => genEmails(dir.diffDir)}>Email-ek létrehozása</Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </SExpansionPanelDetails>
        </SExpansionPanel>
      ))}

      <Dialog
        open={editDialog}
        onClose={closeEditDialog}
        fullWidth
        maxWidth="xl"
        scroll="paper"
        disableBackdropClick={true}
        aria-labelledby="date-edit-dialog-title"
        aria-describedby="date-edit-dialog-content"
      >
        <DateEditDialog
          dir={actDir}
          docs={docs}
          closeDialog={closeEditDialog}
          onDocChange={docView}
        />
      </Dialog>

      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
