import React, {useContext} from "react";
import {useForm} from "react-hook-form";
import {makeStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import {Alert, AlertTitle} from '@material-ui/lab';
import Container from "@material-ui/core/Container";
import {useSnackbar} from 'notistack';

import {userContext} from "../context";
import * as api from "../api";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2em'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {
    const classes = useStyles();
    const {register, handleSubmit, errors} = useForm();
    const {enqueueSnackbar} = useSnackbar();
    const user = useContext(userContext);

    const onSubmit = data => {
        api.login(data.username, data.password, user)
            .then(r => {
                enqueueSnackbar(r.message, {variant: r.status});
            });
    }

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Bejelentkezés
                </Typography>

                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>

                    {errors.username && errors.password && (
                        <Alert severity="error">
                            <AlertTitle>Hiba!</AlertTitle>
                            {errors.username && (<Typography variant="body2">Felhasználónevet meg kell adni.</Typography>)}
                            {errors.password && (<Typography variant="body2">Jelszót meg kell adni.</Typography>)}
                        </Alert>
                    )}

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        name="username"
                        label="Felhasználónév"
                        autoComplete="username"
                        autoFocus
                        inputRef={register({required: true})}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Jelszó"
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="current-password"
                        inputRef={register({required: true})}
                    />

                    <FormControlLabel
                        control={<Checkbox name="rembemer" value="remember" color="primary" inputRef={register}/>}
                        label="Emlékezz rám"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Bejelentkezés
                    </Button>
                </form>
            </Paper>
        </Container>
    );
}
